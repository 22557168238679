import { LOSIterableEntity } from '@api';

/**
 * This is a mapping of the fields that are in the LOS to the fields that are in Premicorr. The mappings in this file
 * are temporary mocks for the actual fields which we will fetch from the backend.
 */

export interface LOSDataSource {
    losFieldId: string;
    losFieldName?: string; // we prob don't even need the los field name
    premicorrFieldName: string; // how we track the field internally
}

export const LOSDataSourceArray: LOSDataSource[] = [
    {
        losFieldId: '123',
        premicorrFieldName: 'Loan Amount'
    },
    {
        losFieldId: '456',
        premicorrFieldName: 'Interest Rate'
    },
    {
        losFieldId: '789',
        premicorrFieldName: 'Loan Term'
    },
    {
        losFieldId: '101112',
        premicorrFieldName: 'Property Address'
    },
    {
        losFieldId: '131415',
        premicorrFieldName: 'Borrower Name'
    },
    {
        losFieldId: '161718',
        premicorrFieldName: 'Loan Type'
    },
    {
        losFieldId: '192021',
        premicorrFieldName: 'Loan Purpose'
    },
    {
        losFieldId: '222324',
        premicorrFieldName: 'Occupancy'
    },
    {
        losFieldId: '252627',
        premicorrFieldName: 'Property Type'
    },
    {
        losFieldId: '282930',
        premicorrFieldName: 'Loan Program'
    },
    {
        losFieldId: '313233',
        premicorrFieldName: 'Loan Status'
    },
    {
        losFieldId: '343536',
        premicorrFieldName: 'Loan Officer'
    },
    {
        losFieldId: '373839',
        premicorrFieldName: 'Processor'
    },
    {
        losFieldId: '404142',
        premicorrFieldName: 'Underwriter'
    },
    {
        losFieldId: '434445',
        premicorrFieldName: 'Closer'
    },
    {
        losFieldId: '464748',
        premicorrFieldName: 'Funder'
    },
    {
        losFieldId: '495051',
        premicorrFieldName: 'Doc Drawer'
    },
    {
        losFieldId: '525354',
        premicorrFieldName: 'Doc Prepper'
    },
    {
        losFieldId: '555657',
        premicorrFieldName: 'Doc Signer'
    },
    {
        losFieldId: '585960',
        premicorrFieldName: 'Doc Shipper'
    },
    {
        losFieldId: '616263',
        premicorrFieldName: 'Doc Post Closer'
    },
    {
        losFieldId: '646566',
        premicorrFieldName: 'Doc Requestor'
    },
    {
        losFieldId: '676869',
        premicorrFieldName: 'Doc Processor'
    },
    {
        losFieldId: '707172',
        premicorrFieldName: 'Doc Reviewer'
    },
    {
        losFieldId: '737475',
        premicorrFieldName: 'Doc Indexer'
    },
    {
        losFieldId: '767778',
        premicorrFieldName: 'Doc QC'
    },
    {
        losFieldId: '798442',
        premicorrFieldName: 'Doc Auditor'
    },
    {
        losFieldId: '828384',
        premicorrFieldName: 'Doc Funder'
    },
    {
        losFieldId: '858687',
        premicorrFieldName: 'Doc Closer'
    }
];

export const dataSourceByIterableEntity: Record<LOSIterableEntity, LOSDataSource[]> = {
    [LOSIterableEntity.DEROGATORY]: [
        {
            premicorrFieldName: 'Borrower',
            losFieldId: 'loan.data.derogatories[].borrower'
        },
        {
            premicorrFieldName: 'Type',
            losFieldId: 'loan.data.derogatories[].type'
        },
        {
            premicorrFieldName: 'Disposition',
            losFieldId: 'loan.data.derogatories[].disposition'
        },
        {
            premicorrFieldName: 'Date',
            losFieldId: 'loan.data.derogatories[].date'
        },
        {
            premicorrFieldName: 'Extenuating',
            losFieldId: 'loan.data.derogatories[].extenuating'
        },
        {
            premicorrFieldName: 'Company',
            losFieldId: 'loan.data.derogatories[].company'
        },
        {
            premicorrFieldName: 'Account #',
            losFieldId: 'loan.data.derogatories[].account_number'
        },
        {
            premicorrFieldName: 'Balance',
            losFieldId: 'loan.data.derogatories[].balance'
        },
        {
            premicorrFieldName: 'Paying off?',
            losFieldId: 'loan.data.derogatories[].paying_off'
        },
        {
            premicorrFieldName: 'Plaintiff',
            losFieldId: 'loan.data.derogatories[].plaintiff'
        },
        {
            premicorrFieldName: 'Item type',
            losFieldId: 'loan.data.derogatories[].item_type'
        },
        {
            premicorrFieldName: 'Amount',
            losFieldId: 'loan.data.derogatories[].amount'
        }
    ],
    [LOSIterableEntity.GIFT_FUNDS]: [
        {
            premicorrFieldName: 'Donor name',
            losFieldId: 'loan.data.giftFunds[].donor'
        },
        {
            premicorrFieldName: 'Amount',
            losFieldId: 'loan.data.giftFunds[].amount'
        }
    ],
    [LOSIterableEntity.INQUIRY]: [
        {
            premicorrFieldName: 'Date',
            losFieldId: 'loan.data.inquiries[].date'
        },
        {
            premicorrFieldName: 'Creditor name',
            losFieldId: 'loan.data.inquiries[].creditor_name'
        }
    ],
    [LOSIterableEntity.PROPERTY]: [
        {
            premicorrFieldName: 'Address',
            losFieldId: 'loan.data.property[].address'
        }
    ],
    [LOSIterableEntity.SELLER]: [
        {
            premicorrFieldName: 'Name',
            losFieldId: 'loan.data.sellers[].name'
        }
    ],
    [LOSIterableEntity.BUYER]: [
        {
            premicorrFieldName: 'Name',
            losFieldId: 'loan.data.buyers[].name'
        }
    ]
};
