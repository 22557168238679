import { UnderwritingQuestionType } from '@api';
import { MenuItem } from '@mui/material';
import { TextField, useParams } from '@tsp-ui/core';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { UnderwritingQuestionContext } from '../../UnderwritingStepDetailPage';
import { UnderwritingQuestionFormValues } from '../AddEditQuestionDialog';


export function NextActionForm() {
    const { underwritingQuestionConfigId: currentQuestionId } = useParams();
    const { questionConfigs } = useContext(UnderwritingQuestionContext);
    const formMethods = useFormContext<UnderwritingQuestionFormValues>();

    const action = formMethods.watch('next.action');
    const questionType = formMethods.watch('type');

    // this will filter out the current question if we are editing. If we are adding, it won't filter anything out
    const questionsExcludingCurrentQuestion = questionConfigs.filter((question) => question.id !== currentQuestionId);

    // Include text as a field if it is an INFO question and the next action is CONTINUE
    // We only need to include the text field on next action if type is CONTINUE because if type is
    // END the submit button will be rendered.
    // We don't want them to click "end" and then "submit" if the last question in the step is an info question.
    const includeText = questionType === UnderwritingQuestionType.INFO && action === 'CONTINUE';

    return (
        <>
            {/* Need to decide if the nextaction is based on the subquestion, or if a
            subquestion nextaction = END triggering the next action of the parent question */}
            {includeText && (
                <TextField<UnderwritingQuestionFormValues>
                    name="next.text"
                    label="Choice Text"
                    helperText={'Defaults to "Next"'}
                />
            )}

            <TextField
                name="next.action"
                required
                label="Action"
                hideRequiredIndicator
                select
            >
                <MenuItem
                    value="CONTINUE"
                    key="CONTINUE"
                >
                    Continue
                </MenuItem>

                <MenuItem
                    value="END"
                    key="END"
                >
                    End
                </MenuItem>
            </TextField>

            {action === 'CONTINUE' && (
                <TextField<UnderwritingQuestionFormValues>
                    name="next.continueTo"
                    label="Continue To"
                    select
                >
                    {questionsExcludingCurrentQuestion.map((question) => (
                        <MenuItem
                            value={question.id}
                            key={question.id}
                        >
                            {question.text}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        </>
    );
}
