import { LoanHighlightType } from '@api';
import { Link as MuiLink, Paper, Typography } from '@mui/material';
import { formatPastDate } from '@utils';
import { differenceInDays, parseISO } from 'date-fns';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import styles from './LoanAlertCard.module.scss';


interface AlertCardProps {
    loanID: string;
    loanNumber: string;
    date: string;
    children?: ReactNode;
    highlightType?: LoanHighlightType;
}

export function LoanAlertCard({
    loanID, loanNumber, date, children, highlightType
}: AlertCardProps) {
    const displayDate = highlightType === LoanHighlightType.LOCK_EXPIRING
        ? `Locks expiring in ${differenceInDays(parseISO(date), new Date())} days`
        : formatPastDate(date);

    return (
        <Paper
            variant="outlined"
            className={styles.alertCard}
        >
            <Typography color="textSecondary">
                Loan #

                <MuiLink
                    component={Link}
                    to={`${loanID}/detail/all`}
                >
                    {loanNumber}
                </MuiLink>
            </Typography>

            {children ? children : (
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {displayDate}
                </Typography>
            )}
        </Paper>
    );
}
