import { LiabilityExclusionType } from '@api';
import { Menu, MenuItem, Typography } from '@mui/material';

import styles from './ExclusionTypeSelectionMenu.module.scss';


interface ExclusionTypeSelectionMenuProps {
    anchorEl: HTMLElement | undefined;
    onClose: () => void;
    onSelect: (exclusionType: LiabilityExclusionType) => void;
}

export default function ExclusionTypeSelectionMenu({
    anchorEl,
    onClose,
    onSelect
}: ExclusionTypeSelectionMenuProps) {
    return (
        <Menu
            open={!!anchorEl}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom'
            }}
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top'
            }}
            className={styles.root}
        >
            <Typography
                variant="h6"
                className={styles.menuTitle}
            >
                Select Exclusion Type
            </Typography>

            <MenuItem
                onClick={() => {
                    onSelect(LiabilityExclusionType.PAY_OFF);
                    onClose();
                }}
            >
                Pay Off
            </MenuItem>

            <MenuItem
                onClick={() => {
                    onSelect(LiabilityExclusionType.OMIT);
                    onClose();
                }}
            >
                Omit
            </MenuItem>
        </Menu>
    );
}
