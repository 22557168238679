import {
    CustomQuestionType, LOSIterableEntity, SellerBuyerInfo, SingleSelectVariant, UnderwritingCategoryConfig,
    UnderwritingFieldType, UnderwritingIconType, UnderwritingQuestionConfig,
    UnderwritingQuestionType, UnderwritingStepConfig
} from './underwriting-types';


const {
    TEXT, NUMBER, DATE, CURRENCY, PERCENTAGE
} = UnderwritingFieldType;

let underwritingCategoryId = 0;
export const getIncrementedUnderwritingCategoryId = () => `${underwritingCategoryId++}`;
let underwritingStepId = 0;
export const getIncrementedUnderwritingStepId = () => `${underwritingStepId++}`;


export const loanSellerData: SellerBuyerInfo[] = [
    {
        name: 'Seller 1'
    }
];

export const loanBuyerData: SellerBuyerInfo[] = [
    {
        name: 'Buyer 1'
    }
];

/* eslint-disable indent */
// AUS
    // AUS Review
// Sales Contract
    // Sales Contract
    // Seller Details
// Credit & Liabilities
    // Report details
    // Credit inquiries
    // Mortgage history
    // Fraud check
    // Derogatories
    // AKAs & Addresses
    // Other liabilities
    // Liabilities
// Income & Assets',
    // Gift Funds
    // Earnest Money
    // Net Equity
    // Asset Accounts
// Appraisal
    // Appraisal Waiver
    // Appraisal Property
    // Appraisal Details
    // Appraisal Review
    // Appraiser Details
    // SSR/UCDP
    // Condo Info
// Title & MI
    // Title Commitment
    // Living Trust Review
    // Tax Certification
    // Mortgage Insurance
// Flood & Hazard
    // Flood Insurance
    // Hazard Insurance
// Fraud Report
    // Fraud Report
    // Parties Review
/* eslint-enable indent */

function getUnderwritingCategoryIdByName(name: string): string {
    const category = underwritingCategories?.find(category => category.name === name);
    if (!category) {
        throw new Error(`Underwriting category with name ${name} not found`);
    }
    return category.id;
}

export let underwritingCategories: UnderwritingCategoryConfig[] = [
    {
        id: `${underwritingCategoryId++}`,
        name: 'AUS',
        displayOrder: 1,
        steps: [ createStepSummary('AUS Review') ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Sales Contract',
        displayOrder: 2,
        steps: [
            createStepSummary('Sales Contract'),
            createStepSummary('Seller Details')
        ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Credit & Liabilities',
        displayOrder: 3,
        steps: [
            createStepSummary('Report details'),
            createStepSummary('Credit inquiries'),
            createStepSummary('Mortgage history'),
            createStepSummary('Fraud check'),
            createStepSummary('Derogatories'),
            createStepSummary('AKAs & Addresses'),
            createStepSummary('Other liabilities'),
            createStepSummary('Liabilities')
        ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Income & Assets',
        displayOrder: 4,
        steps: [
            createStepSummary('Gift Funds'),
            createStepSummary('Earnest Money'),
            createStepSummary('Net Equity'),
            createStepSummary('Asset Accounts')
        ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Appraisal',
        displayOrder: 5,
        steps: [
            createStepSummary('Appraisal Waiver'),
            createStepSummary('Appraisal Property'),
            createStepSummary('Appraisal Details'),
            createStepSummary('Appraisal Review'),
            createStepSummary('Appraiser Details'),
            createStepSummary('SSR/UCDP'),
            createStepSummary('Condo Info')
        ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Title & MI',
        displayOrder: 6,
        steps: [
            createStepSummary('Title Commitment'),
            createStepSummary('Living Trust Review'),
            createStepSummary('Tax Certification'),
            createStepSummary('Mortgage Insurance')
        ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Flood & Hazard',
        displayOrder: 7,
        steps: [
            createStepSummary('Flood Insurance'),
            createStepSummary('Hazard Insurance')
        ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Fraud Report',
        displayOrder: 8,
        steps: [
            createStepSummary('Fraud Report'),
            createStepSummary('Parties Review')
        ]
    }
];

export function setUnderwritingStepConfigs(updatedUnderwritingStepConfigs: UnderwritingStepConfig[]): void {
    underwritingStepConfigs = updatedUnderwritingStepConfigs;
}

function getUnderwritingStepIdFromSummary(stepName: string): string {
    const stepSummaries = underwritingCategories.flatMap(category => category.steps);
    const step = stepSummaries.find(summary => summary.name === stepName);
    if (!step) {
        throw new Error(`Underwriting step with name ${stepName} not found`);
    }
    return step.id;
}

export let underwritingStepConfigs: UnderwritingStepConfig[] = [
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('AUS'),
        id: getUnderwritingStepIdFromSummary('AUS Review'),
        name: 'AUS Review',
        displayOrder: 0
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Sales Contract'),
        id: getUnderwritingStepIdFromSummary('Sales Contract'),
        name: 'Sales Contract',
        displayOrder: 0
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Sales Contract'),
        id: getUnderwritingStepIdFromSummary('Seller Details'),
        name: 'Seller Details',
        displayOrder: 1
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Credit & Liabilities'),
        id: getUnderwritingStepIdFromSummary('Report details'),
        name: 'Report details',
        displayOrder: 0
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Credit & Liabilities'),
        id: getUnderwritingStepIdFromSummary('Credit inquiries'),
        name: 'Credit inquiries',
        displayOrder: 1
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Credit & Liabilities'),
        id: getUnderwritingStepIdFromSummary('Mortgage history'),
        name: 'Mortgage history',
        displayOrder: 2
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Credit & Liabilities'),
        id: getUnderwritingStepIdFromSummary('Fraud check'),
        name: 'Fraud check',
        displayOrder: 3
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Credit & Liabilities'),
        id: getUnderwritingStepIdFromSummary('Derogatories'),
        name: 'Derogatories',
        displayOrder: 4
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Credit & Liabilities'),
        id: getUnderwritingStepIdFromSummary('AKAs & Addresses'),
        name: 'AKAs & Addresses',
        displayOrder: 5
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Credit & Liabilities'),
        id: getUnderwritingStepIdFromSummary('Other liabilities'),
        name: 'Other liabilities',
        displayOrder: 6
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Credit & Liabilities'),
        id: getUnderwritingStepIdFromSummary('Liabilities'),
        name: 'Liabilities',
        displayOrder: 7
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Income & Assets'),
        id: getUnderwritingStepIdFromSummary('Gift Funds'),
        name: 'Gift Funds',
        displayOrder: 0
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Income & Assets'),
        id: getUnderwritingStepIdFromSummary('Earnest Money'),
        name: 'Earnest Money',
        displayOrder: 1
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Income & Assets'),
        id: getUnderwritingStepIdFromSummary('Net Equity'),
        name: 'Net Equity',
        displayOrder: 2
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Income & Assets'),
        id: getUnderwritingStepIdFromSummary('Asset Accounts'),
        name: 'Asset Accounts',
        displayOrder: 3
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Appraisal'),
        id: getUnderwritingStepIdFromSummary('Appraisal Waiver'),
        name: 'Appraisal Waiver',
        displayOrder: 0
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Appraisal'),
        id: getUnderwritingStepIdFromSummary('Appraisal Property'),
        name: 'Appraisal Property',
        displayOrder: 1
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Appraisal'),
        id: getUnderwritingStepIdFromSummary('Appraisal Details'),
        name: 'Appraisal Details',
        displayOrder: 2
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Appraisal'),
        id: getUnderwritingStepIdFromSummary('Appraisal Review'),
        name: 'Appraisal Review',
        displayOrder: 3
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Appraisal'),
        id: getUnderwritingStepIdFromSummary('Appraiser Details'),
        name: 'Appraiser Details',
        displayOrder: 4
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Appraisal'),
        id: getUnderwritingStepIdFromSummary('SSR/UCDP'),
        name: 'SSR/UCDP',
        displayOrder: 5
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Appraisal'),
        id: getUnderwritingStepIdFromSummary('Condo Info'),
        name: 'Condo Info',
        displayOrder: 6
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Title & MI'),
        id: getUnderwritingStepIdFromSummary('Title Commitment'),
        name: 'Title Commitment',
        displayOrder: 0
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Title & MI'),
        id: getUnderwritingStepIdFromSummary('Living Trust Review'),
        name: 'Living Trust Review',
        displayOrder: 1
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Title & MI'),
        id: getUnderwritingStepIdFromSummary('Tax Certification'),
        name: 'Tax Certification',
        displayOrder: 2
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Title & MI'),
        id: getUnderwritingStepIdFromSummary('Mortgage Insurance'),
        name: 'Mortgage Insurance',
        displayOrder: 3
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Flood & Hazard'),
        id: getUnderwritingStepIdFromSummary('Flood Insurance'),
        name: 'Flood Insurance',
        displayOrder: 0
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Flood & Hazard'),
        id: getUnderwritingStepIdFromSummary('Hazard Insurance'),
        name: 'Hazard Insurance',
        displayOrder: 1
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Fraud Report'),
        id: getUnderwritingStepIdFromSummary('Fraud Report'),
        name: 'Fraud Report',
        displayOrder: 0
    },
    {
        underwritingCategoryId: getUnderwritingCategoryIdByName('Fraud Report'),
        id: getUnderwritingStepIdFromSummary('Parties Review'),
        name: 'Parties Review',
        displayOrder: 1
    }
];

let underwritingStepConfigId = 0;
let underwritingQuestionConfigId = 0;
export const getIncrementedUnderwritingStepConfigId = () => `${underwritingStepConfigId++}`;
export const getIncrementedUnderwritingQuestionConfigId = () => `${underwritingQuestionConfigId++}`;

export function setQuestionConfigs(questions: UnderwritingQuestionConfig[]): void {
    underwritingQuestionConfigs = questions;
}

export function setUnderwritingCategories(categories: UnderwritingCategoryConfig[]): void {
    underwritingCategories = categories;
}

// const categoryStepMappings: {categoryId: string, stepConfigs: UnderwritingStepConfig[]}[] = [
//     {
//         categoryId: '0',
//         stepConfigs: [
//             {
//                 id: getIncrementedUnderwritingStepConfigId(),
//                 name: 'AUS Review',
//                 displayOrder: 1
//             }
//         ]
//     }
// ];

export function createStepSummary(stepName: string) {
    return {
        id: `${underwritingStepId++}`,
        name: stepName
    };
}

export function createStepConfig(stepName: string) {
    return {
        id: getUnderwritingStepIdByName(stepName),
        name: stepName
    };
}

export function getUnderwritingStepIdByName(underwritingStepName: string): string {
    const underwritingStepId = underwritingStepConfigs.find(step => step.name === underwritingStepName)?.id;

    if (!underwritingStepId) {
        throw new Error(`Underwriting step with name "${underwritingStepName}" not found in mock data`);
    }

    return underwritingStepId;
}

let fieldOrColumnId = 0;
export let underwritingQuestionConfigs: UnderwritingQuestionConfig[] = [
    // ############################################################################################################
    // Category: AUS
    // ############################################################################################################
    // Step: AUS Review (stepId = 0)
    {
        id: '0',  // was '-1'
        stepId: getUnderwritingStepIdByName('AUS Review'),
        type: UnderwritingQuestionType.CUSTOM,
        text: 'Enter the AUS details',
        customQuestionType: CustomQuestionType.AUS_DETAILS,
        isInitialQuestion: true,
        showNextQuestion: true,
        next: {
            text: 'Continue',
            action: 'CONTINUE',
            continueTo: '1'  // was '0'
        }
    },
    {
        id: '1',  // was '0'
        stepId: getUnderwritingStepIdByName('AUS Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Does the above information match the AUS results',
        choices: [
            {
                action: 'END',
                text: 'No',
                sideEffects: [
                    {
                        id: '1',
                        type: 'GENERATE_CONDITION',
                        text: "AUS results don't match loan information"
                    }
                ]
            },
            {
                action: 'CONTINUE',
                continueTo: '2',  // was '1'
                text: 'Yes'
            }
        ]
    },
    {
        id: '2',  // was '1'
        stepId: getUnderwritingStepIdByName('AUS Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Have conditions been added to address all AUS findings?',
        choices: [
            {
                action: 'END',
                text: 'No'
            },
            {
                action: 'END',
                text: 'Yes'
            }
        ]
    },

    // ############################################################################################################
    // Category: Sales Contract
    // ############################################################################################################
    // Step: Sales Contract (stepId = 1)
    {
        id: '3',  // was '0'
        stepId: getUnderwritingStepIdByName('Sales Contract'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the sales contract on file?',
        isInitialQuestion: true,
        choices: [
            {
                action: 'END',
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '4',  // was '1'
                text: 'Yes'
            }
        ]
    },
    {
        id: '4',  // was '1'
        stepId: getUnderwritingStepIdByName('Sales Contract'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Does the property on the sales contract match the following address from the file?\n<center>{{ propertyAddress }}</center>',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '5',  // was '2'
                text: 'No'
            },
            {
                action: 'END',
                text: 'Yes'
            }
        ]
    },
    {
        id: '5',  // was '2'
        stepId: getUnderwritingStepIdByName('Sales Contract'),
        type: UnderwritingQuestionType.FORM,
        text: 'Enter the following information from the sales contract:',
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.purchase_price',
                label: 'Purchase price',
                size: 6,
                isDisabled: false,
                type: CURRENCY
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.seller_concessions',
                label: 'Seller concessions',
                size: 6,
                helperText: 'Maximum concessions: $4,000',
                isDisabled: false,
                type: CURRENCY
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.earnest_money',
                label: 'Earnest money deposit',
                size: 6,
                isDisabled: false,
                type: CURRENCY
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.contract_execution_date',
                label: 'Contract execution date',
                size: 6,
                isDisabled: false,
                type: DATE
            }
        ],
        next: {
            action: 'END',
            text: 'Done'
        }
    },

    // Seller Details Questions (stepId = 2)
    {
        id: '6',  // was '0'
        text: 'Enter the following information from the sales contract:',
        stepId: getUnderwritingStepIdByName('Seller Details'),
        type: UnderwritingQuestionType.FORM,
        isInitialQuestion: true,
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.seller_name',
                label: 'Selling agent',
                size: 6,
                isDisabled: false,
                type: TEXT
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.seller_address',
                label: 'Listing agent',
                size: 6,
                isDisabled: false,
                type: TEXT
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '7',  // was '1'
            text: 'Next'
        }
    },
    {
        id: '7',  // was '1'
        stepId: getUnderwritingStepIdByName('Seller Details'),
        type: UnderwritingQuestionType.FOREACH,
        entityName: LOSIterableEntity.SELLER,
        text: '<span style="font-size: small; color: gray;">Property seller</span><br>{{ name }}',
        addButtonText: 'Add Seller',
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'name',
                label: 'Seller name',
                size: 12,
                isDisabled: false,
                type: TEXT
            }
        ],
        next: {
            action: 'END',
            text: 'Submit'
        }
    },
    {
        id: '8',  // was '2.0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Seller Details'),
        parentQuestionId: '7',  // parent group question
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the seller a company?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '9',  // was '2.1'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '9',  // was '2.1'
                text: 'No'
            }
        ]
    },
    {
        id: '9',  // was '2.1'
        stepId: getUnderwritingStepIdByName('Seller Details'),
        parentQuestionId: '7',  // parent group question
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the seller a government agency or home builder?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '10',  // was '2.2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '10',  // was '2.2'
                text: 'No'
            }
        ]
    },
    {
        id: '10',  // was '2.2'
        stepId: getUnderwritingStepIdByName('Seller Details'),
        parentQuestionId: '7',  // parent group question
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Does the file contain proof of the individual corporate owners?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '11',  // was '2.3'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '11',  // was '2.3'
                text: 'No'
            }
        ]
    },
    {
        id: '11',  // was '2.3'
        stepId: getUnderwritingStepIdByName('Seller Details'),
        parentQuestionId: '7',  // parent group question
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is this an arms length transaction?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '12',  // was '2.4'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '12',  // was '2.4'
                text: 'No'
            }
        ]
    },
    {
        id: '12',  // was '2.4'
        stepId: getUnderwritingStepIdByName('Seller Details'),
        parentQuestionId: '7',  // parent group question
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Does contract show any gifts from seller to buyer?',
        choices: [
            {
                action: 'END',
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },

    // ############################################################################################################
    // Category: Credit & Liabilities
    // ############################################################################################################
    // Report Details (stepId = 3)
    {
        id: '13',  // was '0'
        stepId: getUnderwritingStepIdByName('Report details'),
        text: 'Enter the following information from the credit report:',
        type: UnderwritingQuestionType.FORM,
        isInitialQuestion: true,
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.borrower_name',
                label: 'Borrower name',
                size: 4,
                isDisabled: true,
                type: TEXT
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.report_date',
                label: 'Report date',
                size: 4,
                isDisabled: true,
                type: DATE
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.report_number',
                label: 'Report #',
                size: 4,
                isDisabled: true,
                type: TEXT
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.transunion',
                label: 'Transunion',
                size: 4,
                isDisabled: true,
                type: NUMBER
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.equifax',
                label: 'Equifax',
                size: 4,
                isDisabled: false,
                type: NUMBER
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.experian',
                label: 'Experian',
                size: 4,
                isDisabled: true,
                type: NUMBER
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '14',  // was '1'
            text: 'Next'
        }
    },
    {
        id: '14',  // was '1'
        stepId: getUnderwritingStepIdByName('Report details'),
        type: UnderwritingQuestionType.INFO,
        text: 'Verify credit report data and enter missing information',
        next: {
            action: 'END',
            text: 'Next'
        }
    },

    // Credit Inquiries (stepId = ?)
    {
        id: '123',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Credit inquiries'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Does the credit report show other inquiries in the last 90 days?',
        choices: [
            {
                action: 'END',
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '124',  // was '1'
                text: 'Yes'
            }
        ]
    },
    {
        id: '124',  // was '1'
        stepId: getUnderwritingStepIdByName('Credit inquiries'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is there a letter of explanation for each of those inquiries?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '125',  // was '2'
                text: 'No',
                sideEffects: [
                    {
                        id: '1',
                        type: 'GENERATE_CONDITION',
                        text: 'Missing letter of explanation',
                        description: 'Please provide a letter of explanation for each credit inquiry'
                    }
                ]
            },
            {
                action: 'END',
                text: 'Yes'
            }
        ]
    },
    {
        id: '125',  // was '2'
        stepId: getUnderwritingStepIdByName('Credit inquiries'),
        type: UnderwritingQuestionType.TABLE_ENTRY,
        text: 'Inquiries requiring explanation',
        entityName: LOSIterableEntity.INQUIRY,
        columns: [
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Creditor name',
                dataSourceId: 'creditor_name'
            },
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Date',
                dataSourceId: 'date'
            }
        ],
        next: {
            action: 'END',
            text: 'Done'
            // commented out until we fix foreach side effects
            // sideEffects: [
            //     {
            //         id: '1',
            //         type: 'GENERATE_CONDITION',
            //         subType: 'FOREACH',
            //         text: 'Provide letter of explanation for the following credit inquiry',
            //         description: '{{ creditor_name }} on {{ date }}'
            //     }
            // ]
        }
    },

    // Mortgage History (stepId = 4)
    {
        id: '15',  // was '0'
        stepId: getUnderwritingStepIdByName('Mortgage history'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is there satisfactory 12 month mortgage payment history for all properties owned?',
        isInitialQuestion: true,
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '16',  // was '1'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '17',  // was '2'
                text: 'Yes'
            }
        ]
    },
    {
        id: '16',  // was '1'
        stepId: getUnderwritingStepIdByName('Mortgage history'),
        type: UnderwritingQuestionType.TABLE_ENTRY,
        text: 'Properties requiring mortgage history',
        entityName: LOSIterableEntity.PROPERTY,
        columns: [
            {
                id: `${fieldOrColumnId++}`,
                columnType: UnderwritingFieldType.TEXT,
                columnName: 'Property address',
                dataSourceId: 'loan.data.properties[].address'
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '17',  // was '2'
            text: 'Next'
        }
    },
    {
        id: '17',  // was '2'
        stepId: getUnderwritingStepIdByName('Mortgage history'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Does any mortgage have late payments in the last 12 months?',
        choices: [
            {
                action: 'END',
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '18',  // was '3'
                text: 'Yes'
            }
        ]
    },
    {
        id: '18',  // was '3'
        stepId: getUnderwritingStepIdByName('Mortgage history'),
        type: UnderwritingQuestionType.FORM,
        text: 'How many late payments are there in each category?',
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.late_payments.30_days',
                label: '30 days late',
                size: 3,
                isDisabled: false,
                type: TEXT
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.late_payments.60_days',
                label: '60 days late',
                size: 3,
                isDisabled: false,
                type: TEXT
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.late_payments.90_days',
                label: '90 days late',
                size: 3,
                isDisabled: false,
                type: TEXT
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.late_payments.120_days',
                label: '120 days late',
                size: 3,
                isDisabled: false,
                type: TEXT
            }
        ],
        next: {
            action: 'END',
            text: 'Done',
            sideEffects: [
                {
                    id: '1',
                    type: 'GENERATE_CONDITION',
                    text: 'Late payments in the last 12 months'
                }
            ]
        }
    },

    // Fraud Check (stepId = ?)
    {
        id: '126',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Fraud check'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is there a fraud check?',
        choices: [
            {
                action: 'END',
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '127',  // was '1'
                text: 'Yes'
            }
        ]
    },
    {
        id: '127',  // was '1'
        stepId: getUnderwritingStepIdByName('Fraud check'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is there a processor certification to satisfy the alert(s)?',
        choices: [
            {
                action: 'END',
                text: 'No'
            },
            {
                action: 'END',
                text: 'Yes'
            }
        ]
    },

    // Derogatories (stepId = ?)
    {
        id: '128',  // was '0.1'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Derogatories'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Are there any bankruptcies, foreclosures, or short sales?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '129',  // was '0.2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '130',  // was '1'
                text: 'No'
            }
        ]
    },
    {
        id: '129',  // was '0.2'
        stepId: getUnderwritingStepIdByName('Derogatories'),
        text: 'Please enter the information for each derogatory:',
        type: UnderwritingQuestionType.TABLE_ENTRY,
        entityName: LOSIterableEntity.DEROGATORY,
        columns: [
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Borrower',
                dataSourceId: 'loan.data.derogatories[].borrower'
            },
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Type',
                dataSourceId: 'loan.data.derogatories[].type'
            },
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Disposition',
                dataSourceId: 'loan.data.derogatories[].disposition'
            },
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Date',
                dataSourceId: 'loan.data.derogatories[].date'
            },
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Extenuating',
                dataSourceId: 'loan.data.derogatories[].extenuating'
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '130',  // was '1'
            text: 'Next'
        }
    },
    {
        id: '130',  // was '1.1'
        stepId: getUnderwritingStepIdByName('Derogatories'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Are there any outstanding judgements or liens?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '131',  // was '1.2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '132',  // was '2'
                text: 'No'
            }
        ]
    },
    {
        id: '131',  // was '1.2'
        stepId: getUnderwritingStepIdByName('Derogatories'),
        type: UnderwritingQuestionType.TABLE_ENTRY,
        text: 'Please enter the information for each derogatory:',
        entityName: LOSIterableEntity.DEROGATORY,
        columns: [
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Borrower',
                dataSourceId: 'loan.data.derogatories[].borrower'
            },
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Plaintiff',
                dataSourceId: 'loan.data.derogatories[].plaintiff'
            },
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Item type',
                dataSourceId: 'loan.data.derogatories[].item_type'
            },
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Amount',
                dataSourceId: 'loan.data.derogatories[].amount'
            },
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Date',
                dataSourceId: 'loan.data.derogatories[].date'
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '132',  // was '2'
            text: 'Next'
        }
    },
    {
        id: '132',  // was '2.1'
        stepId: getUnderwritingStepIdByName('Derogatories'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Are there any open collections or charge offs?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '133',  // was '2.2'
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },
    {
        id: '133',  // was '2.2'
        stepId: getUnderwritingStepIdByName('Derogatories'),
        type: UnderwritingQuestionType.TABLE_ENTRY,
        text: 'Please enter the information for each derogatory:',
        entityName: LOSIterableEntity.DEROGATORY,
        columns: [
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Borrower',
                dataSourceId: 'loan.data.derogatories[].borrower'
            },
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Company',
                dataSourceId: 'loan.data.derogatories[].company'
            },
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Account #',
                dataSourceId: 'loan.data.derogatories[].account_number'
            },
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Balance',
                dataSourceId: 'loan.data.derogatories[].balance'
            },
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Paying off?',
                dataSourceId: 'loan.data.derogatories[].paying_off'
            }
        ],
        next: {
            action: 'END',
            text: 'Done'
        }
    },

    // AKA & Addresses (stepId = ?)
    {
        id: '134',  // was '0.1'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('AKAs & Addresses'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Are there any unexplained AKAs on the credit report?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '136',  // was '1'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '135',  // was '0.2'
                text: 'Yes'
            }
        ]
    },
    {
        id: '135',  // was '0.2'
        stepId: getUnderwritingStepIdByName('AKAs & Addresses'),
        type: UnderwritingQuestionType.TABLE_ENTRY,
        text: 'AKAs requiring explanation',
        columns: [
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'AKA',
                dataSourceId: 'loan.data.aka'
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '136',  // was '1'
            text: 'Next'
        }
    },
    {
        id: '136',  // was '1.1'
        stepId: getUnderwritingStepIdByName('AKAs & Addresses'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Are there any unexplained address variations on the credit report?',
        choices: [
            {
                action: 'END',
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '137',  // was '1.2'
                text: 'Yes'
            }
        ]
    },
    {
        id: '137',  // was '1.2'
        stepId: getUnderwritingStepIdByName('AKAs & Addresses'),
        type: UnderwritingQuestionType.TABLE_ENTRY,
        text: 'Addresses requiring explanation',
        columns: [
            {
                id: `${fieldOrColumnId++}`,
                columnName: 'Address',
                dataSourceId: 'loan.data.address'
            }
        ],
        next: {
            action: 'END',
            text: 'Done'
        }
    },

    // Other Liabilities (stepId = ?)
    {
        id: '138',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Other liabilities'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the borrower required to pay child support or alimony?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '139',  // was '1'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '139',  // was '1'
                text: 'No'
            }
        ]
    },
    {
        id: '139',  // was '1'
        stepId: getUnderwritingStepIdByName('Other liabilities'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is there a divorce decree on file?',
        choices: [
            {
                action: 'END',
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            },
            {
                action: 'END',
                text: 'Not required'
            }
        ]
    },

    // Liability (stepId = ?)
    {
        id: '140',  // was '0'
        isInitialQuestion: true,
        text: 'Liabilities',
        stepId: getUnderwritingStepIdByName('Liabilities'),
        type: UnderwritingQuestionType.CUSTOM,
        customQuestionType: CustomQuestionType.LIABILITIES,
        next: {
            text: 'Submit',
            action: 'END'
        }
    },

    // ############################################################################################################
    // Category: Income & Assets
    // ############################################################################################################
    // Gift Funds (stepId = 5)
    {
        id: '19',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Gift Funds'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Are there any gift funds for this transaction?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '20',  // was '1'
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },
    {
        id: '20',  // was '1'
        stepId: getUnderwritingStepIdByName('Gift Funds'),
        type: UnderwritingQuestionType.FOREACH,
        entityName: LOSIterableEntity.GIFT_FUNDS,
        text: '**{{ amount }}** from **{{ name }}**',
        addButtonText: 'Add Gift',
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'name',
                label: 'Donor name',
                size: 12,
                isDisabled: false,
                type: TEXT
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'amount',
                label: 'Amount',
                size: 12,
                isDisabled: false,
                type: CURRENCY
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '24',  // was '2'
            text: 'Continue'
        }
    },
    {
        id: '21',  // was '1.i.0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Gift Funds'),
        parentQuestionId: '20',
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the gift letter on file?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '22',  // was '1.i.1'
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },
    {
        id: '22',  // was '1.i.1'
        stepId: getUnderwritingStepIdByName('Gift Funds'),
        parentQuestionId: '20',
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Who is the gift fund recipient?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '23',  // was '1.i.2'
                text: 'Borrower'
            },
            {
                action: 'CONTINUE',
                continueTo: '23',  // was '1.i.2'
                text: 'Closing agent'
            },
            {
                action: 'END',
                text: 'Gift of equity'
            }
        ]
    },
    {
        id: '23',  // was '1.i.2'
        stepId: getUnderwritingStepIdByName('Gift Funds'),
        parentQuestionId: '20',
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Are the donor funds sourced?',
        choices: [
            {
                action: 'END',
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },
    {
        id: '24',  // was '2'
        stepId: getUnderwritingStepIdByName('Gift Funds'),
        type: UnderwritingQuestionType.INFO,
        icon: UnderwritingIconType.INFO,
        text: 'All items complete',
        next: {
            action: 'END',
            text: 'Done'
        }
    },
    // Earnest Money (stepId = 6)
    {
        id: '25',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Earnest Money'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is earnest money deposit required in the sales contract?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '26',  // was '1'
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },
    {
        id: '26',  // was '1'
        stepId: getUnderwritingStepIdByName('Earnest Money'),
        type: UnderwritingQuestionType.FORM,
        text: 'Earnest Money',
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.earnest_money',
                label: 'Earnest money deposit',
                size: 12,
                isDisabled: false,
                type: CURRENCY
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.earnest_money_1',
                label: 'Earnest money total amount',
                size: 12,
                isDisabled: false,
                type: CURRENCY
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.earnest_money_2',
                label: 'Earnest money interest',
                size: 12,
                isDisabled: false,
                type: PERCENTAGE
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '27',  // was '2'
            text: 'Next'
        }
    },
    {
        id: '27',  // was '2'
        stepId: getUnderwritingStepIdByName('Earnest Money'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Has the deposit cleared the borrower\'s bank account?',
        choices: [
            {
                action: 'END',
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },

    // Net Equity (stepId = 7)
    {
        id: '28',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Net Equity'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the borrower gaining net equity from the sale of real estate?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '29',  // was '1'
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },
    {
        id: '29',  // was '1'
        stepId: getUnderwritingStepIdByName('Net Equity'),
        type: UnderwritingQuestionType.FORM,
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.net_equity',
                label: 'Net equity gained',
                size: 12,
                isDisabled: false,
                type: CURRENCY
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '30',  // was '2'
            text: 'Next'
        }
    },
    {
        id: '30',  // was '2'
        stepId: getUnderwritingStepIdByName('Net Equity'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the settlement statement present?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '31',  // was '3'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '31',  // was '3'
                text: 'No'
            }
        ]
    },
    {
        id: '31',  // was '3'
        stepId: getUnderwritingStepIdByName('Net Equity'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Are the funds present in one of the borrower\'s accounts?',
        choices: [
            {
                action: 'END',
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },

    // Asset Accounts (stepId = 8)
    {
        id: '32',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Asset Accounts'),
        type: UnderwritingQuestionType.CUSTOM,
        customQuestionType: CustomQuestionType.ASSET_ACCOUNTS,
        next: {
            text: 'Submit',
            action: 'END'
        }
    },

    // ############################################################################################################
    // Category: Appraisal
    // ############################################################################################################

    // Appraisal Waiver (stepId = 9)
    {
        id: '33',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Appraisal Waiver'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is an appraisal waived for this file?',
        choices: [
            {
                action: 'END',
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '34',  // was '1'
                text: 'No'
            }
        ]
    },
    {
        id: '34',  // was '1'
        stepId: getUnderwritingStepIdByName('Appraisal Waiver'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is an appraisal present on file?',
        choices: [
            {
                action: 'END',
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No',
                confirmationMessage: 'Would you like to suspend the file?',
                sideEffects: [
                    {
                        id: '1',
                        type: 'SUSPEND_LOAN'
                    }
                ]
            }
        ]
    },

    // Appraisal Property (stepId = 10)
    {
        id: '35',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Appraisal Property'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'What type of appraisal has been performed?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '36',  // was '1'
                text: '1004'
            },
            {
                action: 'CONTINUE',
                continueTo: '36',  // was '1'
                text: '2055'
            },
            {
                action: 'CONTINUE',
                continueTo: '36',  // was '1'
                text: '1073'
            },
            {
                action: 'CONTINUE',
                continueTo: '36',  // was '1'
                text: '1075'
            },
            {
                action: 'CONTINUE',
                continueTo: '36',  // was '1'
                text: '1025'
            },
            {
                action: 'CONTINUE',
                continueTo: '36',  // was '1'
                text: '1025 Exterior'
            },
            {
                action: 'CONTINUE',
                continueTo: '36',  // was '1'
                text: '2090'
            }
        ]
    },
    {
        id: '36',  // was '1.0'
        stepId: getUnderwritingStepIdByName('Appraisal Property'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Does the appraisal property address match the following address from AUS?<br><center>{{ propertyAddress }}<center/>',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '38',  // was '2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '37',  // was '1.1'
                text: 'No'
            }
        ]
    },
    {
        id: '37',  // was '1.1'
        stepId: getUnderwritingStepIdByName('Appraisal Property'),
        type: UnderwritingQuestionType.FORM,
        text: 'Address',
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.appraisal_address.street',
                label: 'Street',
                size: 12,
                isDisabled: false,
                type: TEXT
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.appraisal_address.street_line_2',
                label: 'Street line 2 (optional)',
                size: 12,
                isDisabled: false,
                type: TEXT,
                isRequired: false
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.appraisal_address.city',
                label: 'City',
                size: 6,
                isDisabled: false,
                type: TEXT
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.appraisal_address.state',
                label: 'State',
                size: 3,
                isDisabled: false,
                type: TEXT
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.appraisal_address.zip',
                label: 'Zip',
                size: 3,
                isDisabled: false,
                type: NUMBER
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '38',  // was '2'
            text: 'Next'
        }
    },
    {
        id: '38',  // was '2.0'
        stepId: getUnderwritingStepIdByName('Appraisal Property'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'What type of property is the appraisal for?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '39',  // was '2.1'
                text: 'Single family'
            },
            {
                action: 'CONTINUE',
                continueTo: '39',  // was '2.1'
                text: 'Condo'
            },
            {
                action: 'CONTINUE',
                continueTo: '39',  // was '2.1'
                text: 'PUD'
            },
            {
                action: 'END',
                text: 'Co-op'
            },
            {
                action: 'END',
                text: 'Manufactured home'
            }
        ]
    },
    {
        id: '39',  // was '2.1'
        stepId: getUnderwritingStepIdByName('Appraisal Property'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the property attached or detached?',
        choices: [
            {
                action: 'END',
                text: 'Attached'
            },
            {
                action: 'END',
                text: 'Detached'
            }
        ]
    },

    // Appraisal Details (stepId = 11)
    {
        id: '40',  // was '0.0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Appraisal Details'),
        type: UnderwritingQuestionType.FORM,
        showNextQuestion: true,
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.property_taxes.amount',
                label: 'Annual taxes',
                size: 4,
                isDisabled: false,
                type: CURRENCY
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.special_assessments.amount',
                label: 'Special assessments',
                size: 4,
                isDisabled: false,
                type: UnderwritingFieldType.TEXT
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.hoa_fees.amount',
                label: 'HOA fees',
                size: 4,
                isDisabled: false,
                type: CURRENCY
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '41',  // was '0.1'
            text: 'Next'
        }
    },
    {
        id: '41',  // was '0.1'
        stepId: getUnderwritingStepIdByName('Appraisal Details'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.RADIOBUTTON,
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '42',  // was '1'
                text: 'Per month'
            },
            {
                action: 'CONTINUE',
                continueTo: '42',  // was '1'
                text: 'Per year'
            }
        ]
    },
    {
        id: '42',  // was '1'
        stepId: getUnderwritingStepIdByName('Appraisal Details'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the market declining?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '43',  // was '2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '43',  // was '2'
                text: 'No'
            }
        ]
    },
    {
        id: '43',  // was '2'
        stepId: getUnderwritingStepIdByName('Appraisal Details'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'What is the condition rating of the property?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '44',  // was '3'
                text: 'C1'
            },
            {
                action: 'CONTINUE',
                continueTo: '44',  // was '3'
                text: 'C2'
            },
            {
                action: 'CONTINUE',
                continueTo: '44',  // was '3'
                text: 'C3'
            },
            {
                action: 'CONTINUE',
                continueTo: '44',  // was '3'
                text: 'C4'
            },
            {
                action: 'CONTINUE',
                continueTo: '44',  // was '3'
                text: 'C5'
            },
            {
                action: 'CONTINUE',
                continueTo: '44',  // was '3'
                text: 'C6'
            }
        ]
    },
    {
        id: '44',  // was '3'
        stepId: getUnderwritingStepIdByName('Appraisal Details'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Appraisal was made',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '45',  // was '4'
                text: 'As is'
            },
            {
                action: 'CONTINUE',
                continueTo: '45',  // was '4'
                text: 'Subject to'
            }
        ]
    },
    {
        id: '45',  // was '4'
        stepId: getUnderwritingStepIdByName('Appraisal Details'),
        type: UnderwritingQuestionType.FORM,
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.appraisal_date',
                helperText: 'Expires 04/04/2025',
                label: 'Appraisal date',
                size: 6,
                isDisabled: false,
                type: DATE
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.appraisal_value',
                label: 'Appraisal value',
                size: 6,
                isDisabled: false,
                type: CURRENCY
            }
        ],
        next: {
            action: 'END',
            text: 'Done'
        }
    },

    // Appraisal Review (stepId = 12)
    {
        id: '46',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Appraisal Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is there a 1040D for the "subject to" appraisal?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '47',  // was '1'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '47',  // was '1'
                text: 'No'
            }
        ]
    },
    {
        id: '47',  // was '1'
        stepId: getUnderwritingStepIdByName('Appraisal Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Does the report contain interior photos?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '48',  // was '2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '48',  // was '2'
                text: 'No'
            }
        ]
    },
    {
        id: '48',  // was '2'
        stepId: getUnderwritingStepIdByName('Appraisal Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the property rural or over 10 acres?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '49',  // was '3'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '49',  // was '3'
                text: 'No'
            }
        ]
    },
    {
        id: '49',  // was '3'
        stepId: getUnderwritingStepIdByName('Appraisal Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: '<strong>Is the appraised value of $415,000 supported?<strong/>',
        choices: [
            {
                action: 'END',
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },

    // Appraiser Details (stepId = 13
    {
        id: '50',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Appraiser Details'),
        type: UnderwritingQuestionType.FORM,
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: '0',
                label: 'Appraiser name',
                size: 4,
                isDisabled: false,
                type: TEXT
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: '1',
                label: 'License number',
                size: 4,
                isDisabled: false,
                type: TEXT
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: '2',
                label: 'Appraisal company',
                size: 4,
                isDisabled: false,
                type: TEXT
            }
        ],
        next: {
            action: 'END',
            text: 'Done'
        }
    },

    // SSR/UCDP (stepId = 14)
    {
        id: '51',  // was '0.0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('SSR/UCDP'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the Fannie Mae UCDP in file?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '52',  // was '0.1'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '53',  // was '1'
                text: 'No'
            }
        ]
    },
    {
        id: '52',  // was '0.1'
        stepId: getUnderwritingStepIdByName('SSR/UCDP'),
        type: UnderwritingQuestionType.FORM,
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.fannie_ucdp_score',
                label: 'Fannie UCDP Score',
                size: 12,
                isDisabled: false,
                type: NUMBER
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '53',  // was '1'
            text: 'Next'
        }
    },
    {
        id: '53',  // was '1.0'
        stepId: getUnderwritingStepIdByName('SSR/UCDP'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the Freddie Mac UCDP in file?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '54',  // was '1.1'
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },
    {
        id: '54',  // was '1.1'
        stepId: getUnderwritingStepIdByName('SSR/UCDP'),
        type: UnderwritingQuestionType.FORM,
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.freddie_ucdp_score',
                label: 'Freddie UCDP Score',
                size: 12,
                isDisabled: false,
                type: NUMBER
            }
        ],
        next: {
            action: 'END',
            text: 'Done'
        }
    },

    // Condo Info (stepId = 15)
    {
        id: '55',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Condo Info'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the condo project new?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '58',  // was '2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '56',  // was '1'
                text: 'No'
            }
        ]
    },
    {
        id: '56',  // was '1'
        stepId: getUnderwritingStepIdByName('Condo Info'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Does the entire project contain less than 5 total units?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '59',  // was '3'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '60',  // was '4'
                text: 'No'
            }
        ]
    },
    {
        id: '57',  // was '2'
        stepId: getUnderwritingStepIdByName('Condo Info'),
        type: UnderwritingQuestionType.INFO,
        icon: UnderwritingIconType.ERROR,
        text: 'Full condo review required',
        next: {
            action: 'CONTINUE',
            continueTo: '61',  // was '5'
            text: 'Next'
        }
    },
    {
        id: '58',  // was '3'
        stepId: getUnderwritingStepIdByName('Condo Info'),
        type: UnderwritingQuestionType.INFO,
        icon: UnderwritingIconType.SUCCESS,
        text: 'No condo review required',
        next: {
            action: 'END',
            text: 'Done'
        }
    },
    {
        id: '59',  // was '4'
        stepId: getUnderwritingStepIdByName('Condo Info'),
        type: UnderwritingQuestionType.INFO,
        icon: UnderwritingIconType.WARNING,
        text: 'Limited condo review required',
        next: {
            action: 'CONTINUE',
            continueTo: '62',  // was '6'
            text: 'Next'
        }
    },
    {
        id: '60',  // was '5'
        stepId: getUnderwritingStepIdByName('Condo Info'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Are the following warranty documents included?\n- Full condo questionnaire\n- HOA Budget\n- HOA Balance sheet',
        choices: [
            {
                action: 'END',
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },
    {
        id: '61',  // was '6'
        stepId: getUnderwritingStepIdByName('Condo Info'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Are the following warranty documents included?\n- Limited condo questionnaire',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '62',  // was '7'
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },
    {
        id: '62',  // was '7'
        stepId: getUnderwritingStepIdByName('Condo Info'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the limited review sufficient?',
        choices: [
            {
                action: 'END',
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },

    // ############################################################################################################
    // Category: Title & MI
    // ############################################################################################################
    // Title Commitment (stepId = 16)
    {
        id: '63',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the title on file?',
        choices: [
            {
                action: 'END',
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '64',  // was '1'
                text: 'Yes'
            }
        ]
    },
    {
        id: '64',  // was '1'
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.FORM,
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: '1',
                label: 'Commitment Date',
                size: 6,
                helperText: 'Expires {{commitmentExpiryDate}}',
                isDisabled: false,
                type: DATE
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: '2',
                label: 'Loan Amount',
                size: 6,
                isDisabled: false,
                type: CURRENCY
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '65',  // was '2'
            text: 'Next'
        }
    },
    {
        id: '65',  // was '2.0'
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: "Is the lender's name correct?",
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '66',  // was '2.1'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '66',  // was '2.1'
                text: 'Yes'
            }
        ]
    },
    {
        id: '66',  // was '2.1'
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the vesting correct?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '67',  // was '2.2'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '67',  // was '2.2'
                text: 'Yes'
            }
        ]
    },
    {
        id: '67',  // was '2.2'
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the title clear of defects?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '68',  // was '2.3'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '68',  // was '2.3'
                text: 'Yes'
            }
        ]
    },
    {
        id: '68',  // was '2.3'
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is COVID-19 hold harmless language included?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '69',  // was '2.4'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '69',  // was '2.4'
                text: 'Yes'
            }
        ]
    },
    {
        id: '69',  // was '2.4'
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Are there any delinquent taxes?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '70',  // was '2.5'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '70',  // was '2.5'
                text: 'Yes'
            }
        ]
    },
    {
        id: '70',  // was '2.5'
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is a 24 month chain of title shown?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '71',  // was '2.6'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '71',  // was '2.6'
                text: 'Yes'
            }
        ]
    },
    {
        id: '71',  // was '2.6'
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the title fee simple or leasehold?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '72',  // was '2.7'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '72',  // was '2.7'
                text: 'Yes'
            }
        ]
    },
    {
        id: '72',  // was '2.7'
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is an insured closing letter on file?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '73',  // was '2.8'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '73',  // was '2.8'
                text: 'Yes'
            }
        ]
    },
    {
        id: '73',  // was '2.8'
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is at least one borrower currently vested on the title?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '74',  // was '2.9'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '74',  // was '2.9'
                text: 'Yes'
            }
        ]
    },
    {
        id: '74',  // was '2.9'
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Has any borrower been on the title for at least 6 months?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '75',  // was '2.10'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '75',  // was '2.10'
                text: 'Yes'
            }
        ]
    },
    {
        id: '75',  // was '2.10'
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the title currently vested in a trust or will it be at closing?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '76',  // was '3'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '76',  // was '3'
                text: 'Yes'
            }
        ]
    },
    {
        id: '76',  // was '3'
        stepId: getUnderwritingStepIdByName('Title Commitment'),
        type: UnderwritingQuestionType.INFO,
        icon: UnderwritingIconType.INFO,
        text: 'Title commitment questions are complete.',
        next: {
            action: 'END',
            text: 'Done'
        }
    },

    // Living Trust Review (stepId = 17)
    {
        id: '77',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Living Trust Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.RADIOBUTTON,
        text: 'Which of the following best describes the titling of this loan regarding a living trust?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '78',  // was '1'
                text: 'Title is currently vested in the trust and will **remain** in the trust'
            },
            {
                action: 'CONTINUE',
                continueTo: '79',  // was '2'
                text: 'Title is currently vested in the trust, but **will be deeded out** of the trust at closing'
            },
            {
                action: 'CONTINUE',
                continueTo: '78',  // was '1'
                text: 'Title is **not** currently in a trust but **will be vested** in a trust at closing'
            }
        ]
    },
    {
        id: '78',  // was '1'
        stepId: getUnderwritingStepIdByName('Living Trust Review'),
        type: UnderwritingQuestionType.INFO,
        icon: UnderwritingIconType.ERROR,
        text: 'Full living trust review required.',
        next: {
            action: 'CONTINUE',
            text: 'Next',
            continueTo: '80'  // was '3'
        }
    },
    {
        id: '79',  // was '2'
        stepId: getUnderwritingStepIdByName('Living Trust Review'),
        type: UnderwritingQuestionType.INFO,
        icon: UnderwritingIconType.SUCCESS,
        text: 'No living trust review required.',
        next: {
            action: 'END',
            text: 'Done'
        }
    },
    {
        id: '80',  // was '3'
        stepId: getUnderwritingStepIdByName('Living Trust Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the full living trust document on file?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '81',  // was '4'
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '81',  // was '4'
                text: 'Yes'
            }
        ]
    },
    {
        id: '81',  // was '4'
        stepId: getUnderwritingStepIdByName('Living Trust Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Has the trust approval been obtained from the title company?',
        choices: [
            {
                action: 'END',
                text: 'No'
            },
            {
                action: 'END',
                text: 'Yes'
            }
        ]
    },

    // Tax Certification (stepId = 18)
    {
        id: '82',  // was '0.0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Tax Certification'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the tax certification on file?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '83',  // was '0.1'
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },
    {
        id: '83',  // was '0.1'
        stepId: getUnderwritingStepIdByName('Tax Certification'),
        type: UnderwritingQuestionType.FORM,
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.annual_property_taxes',
                label: 'Annual Property Taxes',
                size: 12,
                isDisabled: false,
                type: CURRENCY
            }
        ],
        next: {
            action: 'END',
            text: 'Done'
        }
    },

    // Mortgage Insurance (stepId = 19)
    {
        id: '84',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Mortgage Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is mortgage insurance required?',
        choices: [
            {
                action: 'END',
                text: 'No'
            },
            {
                action: 'CONTINUE',
                continueTo: '85',  // was '1'
                text: 'Yes'
            }
        ]
    },
    {
        id: '85',  // was '1'
        stepId: getUnderwritingStepIdByName('Mortgage Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'What is the loan purpose listed on the mortgage insurance certificate?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '86',  // was '2'
                text: 'Purchase'
            },
            {
                action: 'CONTINUE',
                continueTo: '86',  // was '2'
                text: 'Refinance'
            },
            {
                action: 'CONTINUE',
                continueTo: '86',  // was '2'
                text: 'Cash Out Refinance'
            }
        ]
    },
    {
        id: '86',  // was '2'
        stepId: getUnderwritingStepIdByName('Mortgage Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'What is the property type listed on the mortgage insurance certificate?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '87',  // was '3'
                text: 'Single Family'
            },
            {
                action: 'CONTINUE',
                continueTo: '87',  // was '3'
                text: 'Condo'
            },
            {
                action: 'CONTINUE',
                continueTo: '87',  // was '3'
                text: 'PUD'
            },
            {
                action: 'CONTINUE',
                continueTo: '87',  // was '3'
                text: 'Co-op'
            },
            {
                action: 'CONTINUE',
                continueTo: '87',  // was '3'
                text: 'Manufactured Home'
            }
        ]
    },
    {
        id: '87',  // was '3'
        stepId: getUnderwritingStepIdByName('Mortgage Insurance'),
        type: UnderwritingQuestionType.FORM,
        text: 'Enter the following information from the mortgage insurance certificate:',
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: '1',
                label: 'Loan Amount',
                size: 6,
                isDisabled: false,
                type: CURRENCY
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: '2',
                label: 'LTV',
                size: 3,
                isDisabled: false,
                type: PERCENTAGE
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: '3',
                label: 'CLTV',
                size: 3,
                isDisabled: false,
                type: PERCENTAGE
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: '4',
                label: 'Monthly Premium',
                size: 6,
                isDisabled: false,
                type: CURRENCY
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: '5',
                label: 'Coverage Percentage',
                size: 6,
                isDisabled: false,
                type: PERCENTAGE
            }
        ],
        next: {
            action: 'END',
            text: 'Done'
        }
    },

    // ############################################################################################################
    // Category: Flood & Hazard
    // ############################################################################################################
    // Flood Insurance (stepId = 20)
    {
        id: '88',  // was '0.0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Flood Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the flood certificate on file?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '89',  // was '0.1'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '90',  // was '0.2'
                text: 'No'
            }
        ]
    },
    {
        id: '89',  // was '0.1'
        stepId: getUnderwritingStepIdByName('Flood Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the flood certificate valid for the life of the loan?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '90',  // was '0.2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '90',  // was '0.2'
                text: 'No'
            }
        ]
    },
    {
        id: '90',  // was '0.2'
        stepId: getUnderwritingStepIdByName('Flood Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.DROPDOWN,
        text: 'Which flood zone is listed on the certificate?\n',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '91',  // was '3'
                text: 'A'
            },
            {
                action: 'CONTINUE',
                continueTo: '91',  // was '3'
                text: 'AE'
            },
            {
                action: 'CONTINUE',
                continueTo: '91',  // was '3'
                text: 'A1-30'
            },
            {
                action: 'CONTINUE',
                continueTo: '91',  // was '3'
                text: 'AH'
            },
            {
                action: 'CONTINUE',
                continueTo: '91',  // was '3'
                text: 'AO'
            },
            {
                action: 'CONTINUE',
                continueTo: '91',  // was '3'
                text: 'AR'
            },
            {
                action: 'CONTINUE',
                continueTo: '91',  // was '3'
                text: 'A99'
            },
            {
                action: 'CONTINUE',
                continueTo: '91',  // was '3'
                text: 'V'
            },
            {
                action: 'CONTINUE',
                continueTo: '91',  // was '3'
                text: 'VE'
            },
            {
                action: 'CONTINUE',
                continueTo: '91',  // was '3'
                text: 'V1-30'
            },
            {
                action: 'CONTINUE',
                continueTo: '92',  // was '4'
                text: 'X'
            },
            {
                action: 'CONTINUE',
                continueTo: '92',  // was '4'
                text: 'B'
            },
            {
                action: 'CONTINUE',
                continueTo: '92',  // was '4'
                text: 'C'
            }
        ]
    },
    {
        id: '91',  // was '3'
        stepId: getUnderwritingStepIdByName('Flood Insurance'),
        type: UnderwritingQuestionType.INFO,
        icon: UnderwritingIconType.INFO,
        text: 'Flood insurance is required.',
        next: {
            action: 'CONTINUE',
            continueTo: '93',  // was '5'
            text: 'Continue'
        }
    },
    {
        id: '92',  // was '4'
        stepId: getUnderwritingStepIdByName('Flood Insurance'),
        type: UnderwritingQuestionType.INFO,
        icon: UnderwritingIconType.INFO,
        text: 'Flood insurance is not required.',
        next: {
            action: 'END',
            text: 'Done'
        }
    },
    {
        id: '93',  // was '5.0'
        stepId: getUnderwritingStepIdByName('Flood Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is flood insurance on file?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '94',  // was '5.1'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '94',  // was '5.1'
                text: 'No'
            }
        ]
    },
    {
        id: '94',  // was '5.1'
        stepId: getUnderwritingStepIdByName('Flood Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the mortgagee correct?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '95',  // was '5.2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '95',  // was '5.2'
                text: 'No'
            }
        ]
    },
    {
        id: '95',  // was '5.2'
        stepId: getUnderwritingStepIdByName('Flood Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the loan number correct?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '96',  // was '5.3'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '96',  // was '5.3'
                text: 'No'
            }
        ]
    },
    {
        id: '96',  // was '5.3'
        stepId: getUnderwritingStepIdByName('Flood Insurance'),
        type: UnderwritingQuestionType.FORM,
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.flood_insurance_annual_premium',
                label: 'What is the annual premium?',
                size: 6,
                isDisabled: false,
                type: CURRENCY
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.flood_insurance_coverage_amount',
                label: 'What is the coverage amount?',
                size: 6,
                isDisabled: false,
                type: CURRENCY
            }
        ],
        next: {
            action: 'END',
            text: 'Done'
        }
    },

    // Hazard Insurance (stepId = 21)
    {
        id: '97',  // was '0.0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Hazard Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is hazard insurance on file?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '98',  // was '0.1'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '98',  // was '0.1'
                text: 'No'
            }
        ]
    },
    {
        id: '98',  // was '0.1'
        stepId: getUnderwritingStepIdByName('Hazard Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the mortgagee correct?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '99',  // was '0.2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '99',  // was '0.2'
                text: 'No'
            }
        ]
    },
    {
        id: '99',  // was '0.2'
        stepId: getUnderwritingStepIdByName('Hazard Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the loan number correct?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '100',  // was '0.3'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '100',  // was '0.3'
                text: 'No'
            }
        ]
    },
    {
        id: '100',  // was '0.3'
        stepId: getUnderwritingStepIdByName('Hazard Insurance'),
        type: UnderwritingQuestionType.FORM,
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.hazard_insurance_annual_premium',
                label: 'What is the annual premium?',
                size: 6,
                isDisabled: false,
                type: CURRENCY
            },
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.hazard_insurance_coverage_amount',
                label: 'What is the coverage amount?',
                size: 6,
                isDisabled: false,
                type: CURRENCY
            }
        ],
        next: {
            action: 'CONTINUE',
            continueTo: '101',  // was '1'
            text: 'Next'
        }
    },
    {
        id: '101',  // was '1'
        stepId: getUnderwritingStepIdByName('Hazard Insurance'),
        type: UnderwritingQuestionType.CUSTOM,
        text: '<h3 style="font-weight: 500; font-size: 20px;">Master Insurance</h3>',
        next: {
            action: 'CONTINUE',
            continueTo: '102',  // was '2'
            text: 'Next'
        }
    },
    {
        id: '102',  // was '2.0'
        stepId: getUnderwritingStepIdByName('Hazard Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is master insurance on file?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '103',  // was '2.1'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '103',  // was '2.1'
                text: 'No'
            }
        ]
    },
    {
        id: '103',  // was '2.1'
        stepId: getUnderwritingStepIdByName('Hazard Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the mortgagee correct?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '104',  // was '2.2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '104',  // was '2.2'
                text: 'No'
            }
        ]
    },
    {
        id: '104',  // was '2.2'
        stepId: getUnderwritingStepIdByName('Hazard Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the loan number correct?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '105',  // was '3'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '105',  // was '3'
                text: 'No'
            }
        ]
    },
    {
        id: '105',  // was '3'
        stepId: getUnderwritingStepIdByName('Hazard Insurance'),
        type: UnderwritingQuestionType.CUSTOM,
        text: '<h3 style="font-weight: 500; font-size: 20px;">HO-6 (Walls in) Insurance</h3>',
        next: {
            action: 'CONTINUE',
            continueTo: '106',  // was '4'
            text: 'Next'
        }
    },
    {
        id: '106',  // was '4.0'
        stepId: getUnderwritingStepIdByName('Hazard Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is walls in insurance on file?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '107',  // was '4.1'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '107',  // was '4.1'
                text: 'No'
            }
        ]
    },
    {
        id: '107',  // was '4.1'
        stepId: getUnderwritingStepIdByName('Hazard Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the mortgagee correct?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '108',  // was '4.2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '108',  // was '4.2'
                text: 'No'
            }
        ]
    },
    {
        id: '108',  // was '4.2'
        stepId: getUnderwritingStepIdByName('Hazard Insurance'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the loan number correct?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '109',  // was '4.3'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '109',  // was '4.3'
                text: 'No'
            }
        ]
    },
    {
        id: '109',  // was '4.3'
        stepId: getUnderwritingStepIdByName('Hazard Insurance'),
        type: UnderwritingQuestionType.FORM,
        fields: [
            {
                id: `${fieldOrColumnId++}`,
                dataSourceId: 'loan.data.ho6_insurance_annual_premium',
                label: 'What is the annual premium?',
                size: 12,
                isDisabled: false,
                type: CURRENCY
            }
        ],
        next: {
            action: 'END',
            text: 'Done'
        }
    },

    // ############################################################################################################
    // Category: Fraud Report
    // ############################################################################################################
    // Fraud Report (stepId = 22)
    {
        id: '110',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Fraud Report'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Is the fraud report on file?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '111',  // was '1'
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },
    {
        id: '111',  // was '1'
        stepId: getUnderwritingStepIdByName('Fraud Report'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Have all high and medium alerts been addressed with conditions or notes?',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '112',  // was '2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '112',  // was '2'
                text: 'No'
            }
        ]
    },
    {
        id: '112',  // was '2'
        stepId: getUnderwritingStepIdByName('Fraud Report'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: 'Has the fraud report been checked for any undisclosed REO or other addresses?',
        choices: [
            {
                action: 'END',
                text: 'Yes'
            },
            {
                action: 'END',
                text: 'No'
            }
        ]
    },

    // Parties Review (stepId = 23)
    {
        id: '113',  // was '0'
        isInitialQuestion: true,
        stepId: getUnderwritingStepIdByName('Parties Review'),
        type: UnderwritingQuestionType.INFO,
        icon: UnderwritingIconType.INFO,
        text: 'Have the following parties been validated on the fraud report?',
        next: {
            action: 'CONTINUE',
            continueTo: '114',  // was '1'
            text: 'Continue'
        }
    },
    {
        id: '114',  // was '1.0'
        stepId: getUnderwritingStepIdByName('Parties Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: '<strong>Appraiser</strong><br>{{ appraiserName }}<br><small><span style="color: #888;">License #: {{ appraiserLicenseNumber }}</span></small>',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '115',  // was '2'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '115',  // was '2'
                text: 'No'
            }
        ]
    },
    {
        id: '115',  // was '2.0'
        stepId: getUnderwritingStepIdByName('Parties Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: '<strong>Appraisal company</strong><br>{{ appraisalCompany }}',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '116',  // was '3'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '116',  // was '3'
                text: 'No'
            }
        ]
    },
    {
        id: '116',  // was '3.0'
        stepId: getUnderwritingStepIdByName('Parties Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: '<strong>Loan officer</strong><br>{{ loanOfficerName }}<br><small><span style="color: #888;">NMLS ID: {{ loanOfficerNmlsId }}</span></small>',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '117',  // was '4'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '117',  // was '4'
                text: 'No'
            }
        ]
    },
    {
        id: '117',  // was '4.0'
        stepId: getUnderwritingStepIdByName('Parties Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: '<strong>Originating company</strong><br>{{ originatingCompanyName }}<br><small><span style="color: #888;">NMLS ID: {{ originatingCompanyNmlsId }}</span></small>',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '118',  // was '5'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '118',  // was '5'
                text: 'No'
            }
        ]
    },
    {
        id: '118',  // was '5.0'
        stepId: getUnderwritingStepIdByName('Parties Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: '<strong>Realtors</strong><br>{{ buyersAgentName }} (buyer\'s agent)',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '119',  // was '5.1'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '119',  // was '5.1'
                text: 'No'
            }
        ]
    },
    {
        id: '119',  // was '5.1'
        stepId: getUnderwritingStepIdByName('Parties Review'),
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        text: '{{ sellersAgentName }} (seller\'s agent)',
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '120',  // was '6'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '120',  // was '6'
                text: 'No'
            }
        ]
    },
    {
        id: '120',  // was '6.0'
        stepId: getUnderwritingStepIdByName('Parties Review'),
        text: '<strong>Sellers</strong><br>{{ sellers.0.name }}',
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '121',  // was '7'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '121',  // was '7'
                text: 'No'
            }
        ]
    },
    {
        id: '121',  // was '7.0'
        stepId: getUnderwritingStepIdByName('Parties Review'),
        text: '<strong>Buyers</strong><br>{{ buyers.0.name }}',
        type: UnderwritingQuestionType.SINGLE_SELECT,
        variant: SingleSelectVariant.SIMPLE,
        choices: [
            {
                action: 'CONTINUE',
                continueTo: '122',  // was '8'
                text: 'Yes'
            },
            {
                action: 'CONTINUE',
                continueTo: '122',  // was '8'
                text: 'No'
            }
        ]
    },
    {
        id: '122',  // was '8'
        stepId: getUnderwritingStepIdByName('Parties Review'),
        type: UnderwritingQuestionType.INFO,
        icon: UnderwritingIconType.INFO,
        text: 'End of parties review',
        next: {
            action: 'END',
            text: 'End'
        }
    }
];


// Helper function to escape single quotes in strings
function escapeSingleQuotes(input: string | undefined): string {
    if (!input) {
        return '';
    }
    return input.replace(/'/g, "''");
}

// Helper to generate a SQL value string, handling nulls and quotes
function sqlValue(value: any): string {
    if (value === null || value === undefined) {
        return 'NULL';
    }
    if (typeof value === 'boolean') {
        return value ? '1' : '0';
    }
    if (typeof value === 'number') {
        return value.toString();
    }
    return `'${escapeSingleQuotes(value.toString())}'`;
}

export function generateInsertStatements() {
    const statements: string[] = [];
    const idMaps = new Map<string, number>();

    let choiceId = 1;
    let formFieldId = 1;
    let tableColumnId = 1;
    let sideEffectId = 1;

    // Generate Category Inserts
    const categoryInserts = underwritingCategories.map(category => {
        const id = parseInt(category.id);
        idMaps.set(`category_${category.id}`, id);
        return `(${id}, ${sqlValue(category.name)}, '', ${sqlValue(category.displayOrder ?? id)})`;
    }).join(',\n');

    statements.push(
        `SET IDENTITY_INSERT uw.UnderwritingCategory ON;
INSERT INTO uw.UnderwritingCategory (UnderwritingCategoryID, Name, LOSCategory, DisplayOrder)
VALUES
${categoryInserts};
SET IDENTITY_INSERT uw.UnderwritingCategory OFF;`
    );

    // Generate Step Inserts - Group by category
    const stepsByCategory = underwritingStepConfigs.reduce((acc, step) => {
        const categoryId = step.underwritingCategoryId;
        if (!acc[categoryId]) {
            acc[categoryId] = [];
        }
        acc[categoryId].push(step);
        return acc;
    }, {} as Record<string, typeof underwritingStepConfigs>);

    const stepInserts = Object.entries(stepsByCategory).map(([ categoryId, steps ]) => {
        const category = underwritingCategories.find(c => c.id === categoryId);
        return [
            `-- ${category?.name}`,
            steps.map(step => {
                const id = parseInt(step.id);
                idMaps.set(`step_${step.id}`, id);
                return `(${id}, ${categoryId}, ${sqlValue(step.name)}, ${sqlValue(step.displayOrder ?? id)})`;
            }).join(',\n')
        ].join('\n');
    }).join(',\n\n');

    statements.push(
        `SET IDENTITY_INSERT uw.UnderwritingStepConfig ON;
INSERT INTO uw.UnderwritingStepConfig (UnderwritingStepConfigID, UnderwritingCategoryID, Name, DisplayOrder)
VALUES
${stepInserts};
SET IDENTITY_INSERT uw.UnderwritingStepConfig OFF;`
    );

    const questionInserts: string[] = [];
    const choiceInserts: string[] = [];
    const formFieldInserts: string[] = [];
    const tableColumnInserts: string[] = [];
    const sideEffectInserts: string[] = [];

    function processQuestion(question: UnderwritingQuestionConfig) {
        const currentQuestionId = parseInt(question.id);
        idMaps.set(`question_${question.id}`, currentQuestionId);
        const stepIdValue = question.stepId ? idMaps.get(`step_${question.stepId}`) : 'NULL';
        const parentId = question.parentQuestionId ? parseInt(question.parentQuestionId) : null;
        const nextQuestionId = question.next?.continueTo ? sqlValue(question.next.continueTo) : 'NULL';

        const nextText = question.next?.text ? sqlValue(question.next.text) : 'NULL';
        const questionInsert = `(${currentQuestionId}, ${stepIdValue}, ${parentId ? parentId : 'NULL'}, ${sqlValue(question.type)}, ${sqlValue(question.text)}, ${sqlValue(question.customQuestionType)}, ${sqlValue(question.showNextQuestion)}, ${sqlValue(question.variant)}, ${sqlValue(question.next?.action)}, ${nextQuestionId}, ${sqlValue(question.isInitialQuestion)}, ${sqlValue(question.entityName)}, ${sqlValue(question.addButtonText)}, ${nextText})`;

        questionInserts.push(questionInsert);

        if (question.choices) {
            question.choices.forEach(choice => {
                const currentChoiceId = choiceId++;
                const nextQuestionId = choice.action === 'CONTINUE' && choice.continueTo ? sqlValue(choice.continueTo) : 'NULL';
                choiceInserts.push(`(${currentChoiceId}, ${currentQuestionId}, ${sqlValue(choice.text)}, ${sqlValue(choice.action)}, ${nextQuestionId}, ${sqlValue(choice.confirmationMessage)})`);

                if (choice.sideEffects) {
                    choice.sideEffects.forEach(effect => {
                        sideEffectInserts.push(`(${sideEffectId++}, NULL, ${currentChoiceId}, ${sqlValue(effect.type)}, ${sqlValue(effect.subType)}, ${sqlValue(effect.text)}, ${sqlValue(effect.description)})`);
                    });
                }
            });
        }

        if (question.fields) {
            question.fields.forEach(field => {
                formFieldInserts.push(`(${formFieldId++}, ${currentQuestionId}, ${sqlValue(field.dataSourceId)}, ${sqlValue(field.type)}, ${sqlValue(field.label)}, ${sqlValue(field.helperText)}, ${sqlValue(field.size)}, ${sqlValue(field.isRequired ?? true)}, ${sqlValue(field.isDisabled)})`);
            });
        }

        if (question.columns) {
            question.columns.forEach(column => {
                tableColumnInserts.push(`(${tableColumnId++}, ${currentQuestionId}, ${sqlValue(column.dataSourceId)}, ${sqlValue(column.columnName)}, ${sqlValue(column.columnType)})`);
            });
        }
    }

    // Process and group questions by step
    const questionsByStep = underwritingQuestionConfigs.reduce((acc, question) => {
        const { stepId } = question;
        if (!stepId) {
            return acc;
        } // Skip questions without a step
        if (!acc[stepId]) {
            acc[stepId] = [];
        }
        acc[stepId].push(question);
        return acc;
    }, {} as Record<string, typeof underwritingQuestionConfigs>);

    // Process all questions
    Object.entries(questionsByStep).forEach(([ stepId, questions ]) => {
        const step = underwritingStepConfigs.find(s => s.id === stepId);
        questionInserts.push(`-- ${step?.name}`);
        questions.forEach(question => processQuestion(question));
    });

    if (questionInserts.length > 0) {
        statements.push(
            `SET IDENTITY_INSERT uw.Question ON;
INSERT INTO uw.Question (QuestionID, UnderwritingStepID, ParentQuestionID, QuestionType, Text, CustomQuestionType, ShowNextQuestion, Variant, NextAction, NextQuestionID, IsInitialQuestion, EntityName, AddButtonText, NextText)
VALUES
${questionInserts.join(',\n')};
SET IDENTITY_INSERT uw.Question OFF;`
        );
    }

    if (choiceInserts.length > 0) {
        statements.push(
            `SET IDENTITY_INSERT uw.QuestionChoice ON;
INSERT INTO uw.QuestionChoice (QuestionChoiceID, QuestionID, Text, Action, NextQuestionID, ConfirmationMessage)
VALUES
${choiceInserts.join(',\n')};
SET IDENTITY_INSERT uw.QuestionChoice OFF;`
        );
    }

    if (formFieldInserts.length > 0) {
        statements.push(
            `SET IDENTITY_INSERT uw.FormField ON;
INSERT INTO uw.FormField (FormFieldID, QuestionID, LOSDataSourceID, FieldType, Label, HelperText, Size, IsRequired, IsDisabled)
VALUES
${formFieldInserts.join(',\n')};
SET IDENTITY_INSERT uw.FormField OFF;`
        );
    }

    if (tableColumnInserts.length > 0) {
        statements.push(
            `SET IDENTITY_INSERT uw.TableColumn ON;
INSERT INTO uw.TableColumn (TableColumnID, QuestionID, LOSDataSourceID, ColumnName, Type)
VALUES
${tableColumnInserts.join(',\n')};
SET IDENTITY_INSERT uw.TableColumn OFF;`
        );
    }

    if (sideEffectInserts.length > 0) {
        statements.push(
            `SET IDENTITY_INSERT uw.SideEffect ON;
INSERT INTO uw.SideEffect (SideEffectID, QuestionID, QuestionChoiceID, Type, SubType, Text, Description)
VALUES
${sideEffectInserts.join(',\n')};
SET IDENTITY_INSERT uw.SideEffect OFF;`
        );
    }

    return statements.join('\n\n');
}

(window as any).printUnderwritingInserts = generateInsertStatements;
(window as any).clearLocalStorageMocks = () => {
    localStorage.removeItem('underwritingCategories');
    localStorage.removeItem('underwritingStepConfigs');
    localStorage.removeItem('questionConfigs');
    localStorage.removeItem('underwritingQuestionResponses');
    localStorage.removeItem('underwritingStepData');
};
