export enum SpecialLiabilityType {
    ALIMONY = 'ALIMONY',
    CHILD_SUPPORT = 'CHILD_SUPPORT',
    JOB_RELATED_EXPENSES = 'JOB_RELATED_EXPENSES',
    SEPARATE_MAINTENANCE_EXPENSE = 'SEPARATE_MAINTENANCE_EXPENSE',
    OTHER = 'OTHER'
}

export const specialLiabilityTypeDisplay = {
    [SpecialLiabilityType.ALIMONY]: 'Alimony',
    [SpecialLiabilityType.CHILD_SUPPORT]: 'Child Support',
    [SpecialLiabilityType.JOB_RELATED_EXPENSES]: 'Job Related Expenses',
    [SpecialLiabilityType.SEPARATE_MAINTENANCE_EXPENSE]: 'Separate Maintenance Expense',
    [SpecialLiabilityType.OTHER]: 'Other'
};
