import api, { LiabilityExclusionType, UnderwritingLiability, liabilityTypeDisplay } from '@api';
import {
    AttachMoney, Edit, MoneyOff, RemoveCircleOutline
} from '@mui/icons-material';
import {
    Paper, Typography
} from '@mui/material';
import {
    IconButton,
    LabelGroup, LabeledValue, useConfirm, usePageMessage
} from '@tsp-ui/core/components';
import { formatCurrency } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import ExclusionTypeSelectionMenu from './ExclusionTypeSelectionMenu';
import styles from './LiabilitiesCard.module.scss';


interface LiabilityCardProps {
    liability: UnderwritingLiability;
    onUpdate: () => Promise<void>;
    loanId: string | undefined;
}

export default function LiabilityCard({
    liability, onUpdate, loanId
}: LiabilityCardProps) {
    const confirm = useConfirm();
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();
    const [ isDeleting, setIsDeleting ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    async function updateLiabilityExclusionStatus(exclusionType?: LiabilityExclusionType) {
        if (!loanId) {
            return;
        }

        try {
            await api.underwriting.liability.updateUnderwritingLiability(clientId, loanId, {
                ...liability,
                excluded: !liability.excluded,
                exclusionType: !liability.excluded ? exclusionType : undefined
            });
            await onUpdate();
            pageMessage.success('Underwriting liability updated');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating the status of the liability', error);
        }
    }

    async function handleExclude(event: React.MouseEvent<HTMLButtonElement>) {
        if (liability.excluded) {
            // If already excluded, just include it
            if (await confirm('Are you sure you want to include this underwriting liability?')) {
                updateLiabilityExclusionStatus();
            }
        } else {
            // If including to excluding, show menu for exclusion type
            setAnchorEl(event.currentTarget);
        }
    }

    async function deleteLiability() {
        if (!loanId || !await confirm('Are you sure you want to delete this underwriting liability?')) {
            return;
        }

        setIsDeleting(true);

        try {
            await api.underwriting.liability.deleteUnderwritingLiability(clientId, loanId, liability.id);
            await onUpdate();
            pageMessage.success('Underwriting liability deleted');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while deleting the underwriting liability', error);
        }

        setIsDeleting(false);
    }

    return (
        <>
            <Paper
                className={styles.root}
                elevation={0}
            >
                <div className={styles.accountInfo}>
                    <Typography className={styles.creditorName}>
                        {liability.creditorName}
                    </Typography>

                    <Typography variant="body2">
                        {liability.accountNumber}
                    </Typography>

                    <Typography
                        variant="body2"
                        className={styles.accountName}
                    >
                        {liability.accountName}
                    </Typography>

                    <Typography
                        variant="body2"
                    >
                        {liabilityTypeDisplay[liability.liabilityType]}
                    </Typography>
                </div>

                <div>
                    <div className={styles.iconButtons}>
                        <IconButton
                            tooltip="Delete"
                            size="small"
                            onClick={deleteLiability}
                            loading={isDeleting}
                        >
                            <RemoveCircleOutline color="error" />
                        </IconButton>

                        <IconButton
                            tooltip={liability.excluded ? 'Include' : 'Exclude'}
                            size="small"
                            onClick={handleExclude}
                        >
                            {liability.excluded ? (
                                <AttachMoney color="action" />
                            ) : (
                                <MoneyOff color="action" />
                            )}
                        </IconButton>

                        <IconButton
                            tooltip="Edit"
                            size="small"
                            component={Link}
                            to={`edit/${liability.id}`}
                        >
                            <Edit color="secondary" />
                        </IconButton>
                    </div>

                    <LabelGroup>
                        <LabeledValue
                            label="Account balance:"
                            value={formatCurrency(liability.accountBalance) ?? '--'}
                        />

                        {liability.liabilityType === 'HELOC' && (
                            <LabeledValue
                                label="Credit limit:"
                                value={formatCurrency(liability.creditLimit) ?? '--'}
                            />
                        )}

                        <LabeledValue
                            label="Monthly payment:"
                            value={formatCurrency(liability.monthlyPayment) ?? '--'}
                        />

                        <LabeledValue
                            label="Months left:"
                            value={formatCurrency(liability.monthsLeft) ?? '--'}
                        />
                    </LabelGroup>
                </div>
            </Paper>

            <ExclusionTypeSelectionMenu
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(undefined)}
                onSelect={(exclusionType) => {
                    setAnchorEl(undefined);
                    updateLiabilityExclusionStatus(exclusionType);
                }}
            />
        </>
    );
}
