import { UnderwritingTableRowValueSet } from '@api';
import { RemoveCircleOutline } from '@mui/icons-material';
import { TextField, Typography } from '@mui/material';
import {
    Button, CardTable, IconButton, getItemByKey, replaceItemByKey
} from '@tsp-ui/core';
import { replaceItemByIndex } from '@utils';
import { Markdown } from '@views/components/Markdown';
import {
    useContext, useEffect, useMemo
} from 'react';

import { UnderwritingCategoriesContext } from '../UnderwritingCategories';
import { UnderWritingStepContext } from '../UnderwritingStepSection';
import { UnderwritingCardWrapper, UnderwritingQuestionCardProps } from '../underwriting-common';

import styles from './TableQuestionCard.module.scss';


export function TableQuestionCard({ questionConfig, questionResponse }: UnderwritingQuestionCardProps) {
    const {
        setReadyToSubmit,
        updateQuestionIdsToDisplayAndSideEffects,
        setQuestionResponse,
        questionResponses
    } = useContext(UnderWritingStepContext);
    const { underwritingData } = useContext(UnderwritingCategoriesContext);

    // an object where every key is the column name and the value is an empty string
    // const emptyRowValueSet: UnderwritingTableRowValueSet = useMemo<UnderwritingTableRowValueSet>(
    //     () => questionConfig.columns!.reduce((acc, column) => ({
    //         ...acc,
    //         values: {
    //             ...acc.values,
    //             [column.id]: ''
    //         }
    //     }), {
    //         entityId: `new-entity-${Math.random()}`,
    //         values: {}
    //     } as UnderwritingTableRowValueSet), [ questionConfig.columns ]
    // );
    const emptyRowValueSet: UnderwritingTableRowValueSet = useMemo<UnderwritingTableRowValueSet>(
        () => ({
            entityId: `new-entity-${Math.random()}`,
            values: questionConfig.columns!.map(column => ({
                id: `value-${column.id}-${Math.random()}`,
                columnId: column.id,
                value: ''
            }))
        }),
        [ questionConfig.columns ]
    );

    const tableRowValues = questionResponse?.tableRowValues || [ emptyRowValueSet ];
    const setTableRowValues = (tableRowValues: UnderwritingTableRowValueSet[]) => {
        setQuestionResponse({
            ...questionResponse,
            tableRowValues
        });
    };

    const tableIsCompleted = tableRowValues?.every(row => row.values.every(value => ![
        null, undefined, ''
    ].includes(value.value)));

    useEffect(() => {
        if (questionConfig.next?.action === 'END') {
            if (tableIsCompleted) {
                setReadyToSubmit(true);
            } else {
                setReadyToSubmit(false);
            }
        }
    }, [
        tableIsCompleted, questionConfig, setReadyToSubmit
    ]);

    const handleDeleteRow = (rowIndex: number) => {
        const updatedValues = tableRowValues.filter((_, index) => index !== rowIndex);
        // updateUnderwritingQuestion({
        //     ...questionConfig,
        //     values: updatedValues
        // });
        setTableRowValues(updatedValues);
    };

    const allowDeleteRows = (questionResponse?.tableRowValues?.length || 0) > 1;

    return (
        <UnderwritingCardWrapper
            questionConfig={questionConfig}
            questionResponse={questionResponse}
            className={styles.tableCard}
        >
            <Typography color="textSecondary">
                <Markdown valuesToInject={underwritingData}>
                    {questionConfig.text}
                </Markdown>
            </Typography>

            <CardTable
                mediumBackground
                className={styles.editableCardTable}
                headers={[ ...questionConfig.columns?.map(column => column.columnName) || [], 'Actions' ]}
            >
                {tableRowValues.map((row, rowIndex) => (
                    <tr key={row.entityId}>
                        {questionConfig.columns?.map((column, columnIndex) => {
                            const tableFieldValue = getItemByKey(row.values, 'columnId', column.id);

                            return (
                                <td key={column.dataSourceId}>
                                    <TextField
                                        id={tableFieldValue.id}
                                        variant="standard"
                                        size="small"
                                        value={tableFieldValue.value}
                                        autoFocus={rowIndex === 0 && columnIndex === 0}
                                        onChange={(event) => {
                                        // TODO we can update this to replace by entityId instead but its fine as is
                                            setTableRowValues(
                                                replaceItemByIndex<UnderwritingTableRowValueSet>(tableRowValues, {
                                                    ...row,
                                                    values: replaceItemByKey(row.values, {
                                                        ...tableFieldValue,
                                                        value: event.target.value
                                                    }, 'columnId')
                                                }, rowIndex)
                                            );
                                        }}
                                    />
                                </td>
                            );
                        })}

                        <td>
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteRow(rowIndex)}
                                disabled={!allowDeleteRows}
                                tooltip={allowDeleteRows ? 'Delete row' : 'Cannot delete last row'}
                            >
                                <RemoveCircleOutline />
                            </IconButton>
                        </td>
                    </tr>
                ))}
            </CardTable>

            <div className={styles.tableCardButtons}>
                <Button onClick={() => {
                    // updateUnderwritingQuestion({
                    //     ...questionConfig,
                    //     values: [
                    //         ...questionConfig.values || [],
                    //         emptyRow
                    //     ]
                    // });
                    setTableRowValues([
                        ...tableRowValues,
                        emptyRowValueSet
                    ]);
                }}
                >
                    Add row
                </Button>

                {/* if question is last in the step, use ready to submit.
                    if question has a next action, use this next button instead */}
                {questionConfig.next?.action === 'CONTINUE' && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            // const { questionIdsToDisplay, sideEffects } = getUnderwritingStepData(underwritingStep!);
                            // setQuestionIdsToDisplay(questionIdsToDisplay);
                            // setSideEffects(sideEffects);
                            updateQuestionIdsToDisplayAndSideEffects(questionResponses);
                        }}
                        disabled={!tableIsCompleted}
                    >
                        {questionConfig.next.text}
                    </Button>
                )}
            </div>
        </UnderwritingCardWrapper>
    );
}
