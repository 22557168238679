import api, {
    LiabilityBorrower,
    LiabilityLienPosition, LiabilityType, LiabilityUCDPayoffType,
    UnderwritingLiability, liabilityBorrowerDisplay, liabilityTypeDisplay,
    liabilityUCDPayoffTypeDisplay,
    liabilitylienPositionDisplay
} from '@api';
import {
    DialogActions, DialogContent, FormLabel, MenuItem
} from '@mui/material';
import {
    Button,
    RoutedDialog,
    RoutedDialogImplProps, Switch, TextField, replaceItemByKey, usePageMessage, useParams
} from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils';
import { LoanDetailContext } from '@views/loans/LoanDetailPage';
import { UnderWritingStepContext } from '@views/loans/underwriting/UnderwritingStepSection';
import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './AddEditUnderwritingLiabilityDialog.module.scss';


export default function AddEditUnderwritingLiabilityDialog(props: RoutedDialogImplProps) {
    const { id: clientId } = useGetCurrentAccount();
    const { liabilityId } = useParams();
    const { liabilities, setLiabilities } = useContext(UnderWritingStepContext);
    const loanId = useContext(LoanDetailContext).loanDetail?.id;
    const liabilityToEdit = liabilities.find(({ id }) => id === liabilityId!);
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const formMethods = useForm<UnderwritingLiability>({
        defaultValues: liabilityToEdit
    });
    const { handleSubmit } = formMethods;
    const [ saveLoading, setSaveLoading ] = useState(false);

    async function onSubmit(data: UnderwritingLiability) {
        if (!loanId) {
            return;
        }

        setSaveLoading(true);
        try {
            if (liabilityToEdit) {
                const updatedLiability = await api.underwriting.liability.updateUnderwritingLiability(
                    clientId, loanId, data
                );
                setLiabilities(prevLiabilities => replaceItemByKey(prevLiabilities, updatedLiability, 'id'));
                pageMessage.success('Liability edited successfully');
            } else {
                const newLiability = await api.underwriting.liability.createUnderwritingLiability(
                    clientId, loanId, data
                );
                setLiabilities([ ...liabilities, newLiability ]);
                pageMessage.success('Liability created successfully');
            }

            navigate(props.closeTo);
        } catch (e) {
            pageMessage.handleApiError(`An error occurred while ${liabilityToEdit ? 'editing' : 'creating'} underwriting liability`, e);
        }
        setSaveLoading(false);
    }

    return (
        <RoutedDialog
            {...props}
            title={liabilityToEdit ? 'Edit liability' : 'Add liability'}
            saveLoading={saveLoading}
        >
            <DialogContent>
                <form
                    noValidate
                    id={formId}
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormProvider {...formMethods}>
                        <div>
                            <FormLabel className={styles.formLabel}>
                                Creditor Info
                            </FormLabel>

                            <div className={styles.detailsContainer}>
                                <TextField<UnderwritingLiability>
                                    name="creditorName"
                                    label="Creditor Name"
                                    required
                                />

                                <TextField<UnderwritingLiability>
                                    name="liabilityType"
                                    label="Liability Type"
                                    select
                                    required
                                >
                                    {Object.values(LiabilityType).map((type) => (
                                        <MenuItem
                                            key={type}
                                            value={type}
                                        >
                                            {liabilityTypeDisplay[type]}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>

                        <div>
                            <FormLabel className={styles.formLabel}>
                                Account Info
                            </FormLabel>

                            <div className={styles.detailsContainer}>
                                <TextField<UnderwritingLiability>
                                    name="accountName"
                                    label="Account Name"
                                />

                                <TextField<UnderwritingLiability>
                                    name="accountNumber"
                                    label="Account Number"
                                    required
                                />

                                <TextField<UnderwritingLiability>
                                    name="borrowerStatus"
                                    label="Borrower Status"
                                    select
                                >
                                    {Object.values(LiabilityBorrower).map((bor) => (
                                        <MenuItem
                                            key={bor}
                                            value={bor}
                                        >
                                            {liabilityBorrowerDisplay[bor]}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>

                        <div>
                            <FormLabel className={styles.formLabel}>
                                Debt Info
                            </FormLabel>

                            <div className={styles.detailsContainer}>
                                <TextField<UnderwritingLiability>
                                    name="monthsToExclude"
                                    label="Months to Exclude"
                                    type="number"
                                />

                                <TextField<UnderwritingLiability>
                                    name="purpose"
                                    label="Purpose"
                                />

                                <TextField<UnderwritingLiability>
                                    name="ucdPayoffType"
                                    label="UCD Payoff Type"
                                    select
                                    required
                                >
                                    {Object.values(LiabilityUCDPayoffType).map((type) => (
                                        <MenuItem
                                            key={type}
                                            value={type}
                                        >
                                            {liabilityUCDPayoffTypeDisplay[type]}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField<UnderwritingLiability>
                                    name="currentLienPosition"
                                    label="Current Lien Position"
                                    select
                                >
                                    {Object.values(LiabilityLienPosition).map((pos) => (
                                        <MenuItem
                                            key={pos}
                                            value={pos}
                                        >
                                            {liabilitylienPositionDisplay[pos]}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField<UnderwritingLiability>
                                    name="proposedLienPosition"
                                    label="Proposed Lien Position"
                                    select
                                >
                                    {Object.values(LiabilityLienPosition).map((pos) => (
                                        <MenuItem
                                            key={pos}
                                            value={pos}
                                        >
                                            {liabilitylienPositionDisplay[pos]}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField<UnderwritingLiability>
                                    name="accountBalance"
                                    label="Account Balance"
                                    type="number"
                                    required
                                />

                                <TextField<UnderwritingLiability>
                                    name="monthsLeft"
                                    label="Months Left"
                                    type="number"
                                />

                                <TextField<UnderwritingLiability>
                                    name="monthlyPayment"
                                    label="Monthly Payment"
                                    type="number"
                                    required
                                />

                                <TextField<UnderwritingLiability>
                                    name="creditLimit"
                                    label="Credit Limit"
                                    type="number"
                                />

                                <Switch<UnderwritingLiability>
                                    name="willBePaidOff"
                                    label="Will Be Paid Off"
                                />

                                <Switch<UnderwritingLiability>
                                    name="excluded"
                                    label="Excluded"
                                />

                                <Switch<UnderwritingLiability>
                                    name="resubordinatedIndicator"
                                    label="Resubordinated Indicator"
                                />

                                <Switch<UnderwritingLiability>
                                    name="subjectProperty"
                                    label="Subject Property"
                                />

                                <Switch<UnderwritingLiability>
                                    name="paymentIncludesTaxes"
                                    label="Payment Includes Taxes"
                                />

                                <Switch<UnderwritingLiability>
                                    name="paceLoan"
                                    label="PACE Loan"
                                />

                                <Switch<UnderwritingLiability>
                                    name="energyImprovement"
                                    label="Energy Improvement"
                                />
                            </div>
                        </div>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    form={formId}
                    type="submit"
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

const formId = 'add-edit-underwriting-liability-form';
