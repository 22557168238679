import { TemplateFile } from '@api';

/**
 * Returns the first key in the object that has a truthy value
 */
export function tooltipTitle(titles: { [title: string]: any }) {
    const [ title ] = Object.entries(titles).find(([ _, shouldShow ]) => shouldShow) || [];
    return title || '';
}

/**
 * Converts from camel or pascal case to lower case with spaces, i.e. "camelCase" -> "camel case"
 */
export function toLowerCaseWithSpaces(str: string) {
    return str.replace(/([A-Z])/g, ' $1').toLowerCase();
}

/**
 * Converts from camel or pascal case to sentence case
 */
export function toSentenceCase(str: string) {
    return str.replace(/([A-Z][a-z])/g, ' $1')
        .replace(/\//g, ' /') // add spaces around slashes
        .replace(/^./, (s) => s.toUpperCase());
}

/**
 * Returns a new array with the item at the specified index replaced by the new item
 */
export function replaceItemByIndex<T>(array: T[], newItem: T, index: number) {
    return [
        ...array.slice(0, index),
        newItem,
        ...array.slice(index + 1)
    ];
}

/**
 * Constructs the account base route using the clientId and optional customerId.
 * Returns the route in the format:
 * `/accounts/{clientId}` or
 * `/accounts/{clientId}/{customerId}` if the customer ID is provided.
 */
export function getAccountBaseRoute(clientId: string, customerId?: string) {
    return `/accounts/${clientId}${customerId ? `/${customerId}` : ''}`;
}

export function handleDownload(file: Blob | TemplateFile, fileName: string) {
    if (file instanceof Blob) {
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } else {
        const link = document.createElement('a');
        link.href = file.fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

type ItemWithDisplayOrder = { displayOrder: number, [key: string]: any };
export function getMinDisplayOrder(itemsWithDisplayOrder: ItemWithDisplayOrder[]) {
    return itemsWithDisplayOrder.reduce(
        (min, { displayOrder }) => Math.min(min, displayOrder), itemsWithDisplayOrder[0].displayOrder

    );
}

export function getMaxDisplayOrder(itemsWithDisplayOrder: ItemWithDisplayOrder[]) {
    return itemsWithDisplayOrder.reduce(
        (max, { displayOrder }) => Math.max(max, displayOrder), itemsWithDisplayOrder[0].displayOrder
    );
}
