/* eslint-disable max-len */
import { UnderwritingIconType } from '@api';
import {
    CheckCircleOutline, ErrorOutline, InfoOutlined, WarningOutlined
} from '@mui/icons-material';
import { Button, IconTypography } from '@tsp-ui/core';
import { Markdown } from '@views/components/Markdown';
import { ReactNode, useContext, useEffect } from 'react';

import { UnderwritingCategoriesContext } from '../UnderwritingCategories';
import { UnderWritingStepContext } from '../UnderwritingStepSection';
import { UnderwritingCardWrapper, UnderwritingQuestionCardProps } from '../underwriting-common';

import styles from './InfoQuestionCard.module.scss';


export function InfoQuestionCard({ questionConfig, questionResponse }: UnderwritingQuestionCardProps) {
    const { setReadyToSubmit, setQuestionResponse, updateQuestionIdsToDisplayAndSideEffects } = useContext(UnderWritingStepContext);
    const { underwritingData } = useContext(UnderwritingCategoriesContext);

    useEffect(() => { // this is for the default rendering of the question, not when a user clicks next
        // might be able to remove this effect
        if (questionConfig.next?.action === 'END') {
            setReadyToSubmit(true);
        }
    }, [ questionConfig, setReadyToSubmit ]);

    return (
        <UnderwritingCardWrapper
            questionConfig={questionConfig}
            questionResponse={questionResponse}
            className={styles.infoQuestionRoot}
        >
            <IconTypography icon={infoQuestionIconMap[questionConfig.icon || UnderwritingIconType.INFO]}>
                <Markdown valuesToInject={underwritingData}>
                    {questionConfig.text}
                </Markdown>
            </IconTypography>

            {questionConfig.next?.action === 'CONTINUE' && (
                <Button
                    variant="contained"
                    onClick={() => {
                        const updatedResponse = {
                            ...questionResponse,
                            answer: 'true'
                        };

                        updateQuestionIdsToDisplayAndSideEffects(setQuestionResponse(updatedResponse));
                    }}
                >
                    {questionConfig.next.text || 'Next'}
                </Button>
            )}
        </UnderwritingCardWrapper>
    );
}

const infoQuestionIconMap: Record<UnderwritingIconType, ReactNode> = {
    INFO: <InfoOutlined color="primary" />,
    WARNING: <WarningOutlined color="warning" />,
    ERROR: <ErrorOutline color="error" />,
    SUCCESS: <CheckCircleOutline color="success" />
};
