export enum LoanHighlightType {
    LOCK_EXPIRING = 'LOCK_EXPIRING',
    NEEDS_REPRICING = 'NEEDS_REPRICING',
    ONE_CONDITION_LEFT = 'ONE_CONDITION_LEFT'
}

export const highlightTypeDisplay = {
    [LoanHighlightType.LOCK_EXPIRING]: 'Locks expiring',
    [LoanHighlightType.NEEDS_REPRICING]: 'Loan needs reprice',
    [LoanHighlightType.ONE_CONDITION_LEFT]: 'One condition left'
};
