import { UnderwritingQuestionConfig, UnderwritingQuestionType } from '@api';
import { Edit, RemoveCircleOutline, Save } from '@mui/icons-material';
import { MenuItem, Paper, Typography } from '@mui/material';
import {
    IconButton, LabelGroup, LabeledValue, TextField
} from '@tsp-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';

import { UnderwritingQuestionFormValues } from '../AddEditQuestionDialog';

import styles from './SideEffectCard.module.scss';


interface SideEffectCardProps {
    sideEffects: UseFieldArrayReturn<UnderwritingQuestionConfig, any, 'id'>; // tlTODO any
    index: number;
}

const { TABLE_ENTRY, FOREACH } = UnderwritingQuestionType;

export function SideEffectCard({ sideEffects, index }: SideEffectCardProps) {
    const formMethods = useFormContext<UnderwritingQuestionFormValues>();

    const currentQuestion = formMethods.getValues();
    const currentSideEffect = currentQuestion.next?.sideEffects?.[index];

    const {
        text, description, type, subType
    } = formMethods.watch(`next.sideEffects.${index}`);

    const [ isEditing, setIsEditing ] = useState(!currentSideEffect?.text);

    const saveDisabled = !text || !type;
    const buttons = (
        <div className={styles.buttons}>
            {isEditing ? (
                <IconButton
                    key="save"
                    tooltip="Save"
                    size="small"
                    onClick={() => setIsEditing(false)}
                    disabled={saveDisabled}
                >
                    <Save
                        color={saveDisabled ? 'disabled' : 'secondary'}
                        fontSize="small"
                    />
                </IconButton>
            ) : (
                <IconButton
                    tooltip="Edit"
                    size="small"
                    onClick={() => setIsEditing(true)}
                >
                    <Edit
                        color="secondary"
                        fontSize="small"
                    />
                </IconButton>
            )}

            <IconButton
                tooltip="Remove"
                size="small"
                onClick={() => sideEffects.remove?.(index)}
            >
                <RemoveCircleOutline
                    color="error"
                    fontSize="small"
                />
            </IconButton>
        </div>
    );

    return isEditing ? (
        <Paper
            className={clsx(styles.sideEffectCard, styles.isEditing)}
            variant="outlined"
        >
            <Typography
                variant="body2"
                component="div"
                className={styles.headerEditing}
                fontWeight={500}
            >
                <TextField<UnderwritingQuestionFormValues>
                    className={styles.fullWidth}
                    name={`next.sideEffects.${index}.text`}
                    variant="standard"
                    label="Side effect text"
                    size="small"
                    required
                />

                {buttons}
            </Typography>

            <TextField
                name={`next.sideEffects.${index}.type`}
                variant="standard"
                label="Type"
                required
                hideRequiredIndicator
                select
                size="small"
            >
                <MenuItem
                    value="GENERATE_CONDITION"
                    key="GENERATE_CONDITION"
                >
                    Generate condition
                </MenuItem>

                <MenuItem
                    value="SUSPEND_LOAN"
                    key="SUSPEND_LOAN"
                >
                    Suspend loan
                </MenuItem>
            </TextField>

            {(currentQuestion.type === TABLE_ENTRY || currentQuestion.type === FOREACH) && (
                <TextField<UnderwritingQuestionFormValues>
                    name={`next.sideEffects.${index}.subType`}
                    label="Sub-type"
                    variant="standard"
                    size="small"
                    helperText="If set to for each, this will generate a sideEffect for each row of a table or each item in a foreach question"
                    select
                >
                    <MenuItem
                        value="FOREACH"
                        key="FOREACH"
                    >
                        For each
                    </MenuItem>

                    <MenuItem
                        value="STANDARD"
                        key="STANDARD"
                    >
                        Standard
                    </MenuItem>
                </TextField>
            )}

            <TextField<UnderwritingQuestionFormValues>
                className={styles.fullWidth}
                name={`next.sideEffects.${index}.description`}
                variant="standard"
                label="Description"
                size="small"
            />
        </Paper>
    ) : (
        <Paper
            className={styles.sideEffectCard}
            variant="outlined"
        >
            <Typography
                variant="body2"
                component="div"
                className={styles.headerContainer}
                fontWeight={500}
            >
                <Typography
                    variant="body2"
                    color="textSecondary"
                    fontWeight={600}
                >
                    {text}
                </Typography>

                {buttons}
            </Typography>

            <LabelGroup>
                <LabeledValue
                    value={type === 'GENERATE_CONDITION' ? 'Generate condition' : 'Suspend loan'}
                    label="Type:"
                />

                {subType && (
                    <LabeledValue
                        value={subType === 'FOREACH' ? 'For each' : 'Standard'}
                        label="Sub type:"
                    />
                )}

                {description && (
                    <LabeledValue
                        value={description}
                        label="Description:"
                    />
                )}
            </LabelGroup>
        </Paper>
    );
}
