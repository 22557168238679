import { PermissionType, UserType } from '@api';
import { SentryRoutes } from '@tsp-ui/core/components';
import { useGetCurrentAccount } from '@utils/hooks';
import { withAuth } from '@utils/withAuth';
import LLPARoutesBase from '@views/admin/llpas/LLPARoutes';
import { Navigate, Route, useLocation } from 'react-router-dom';

import NotFoundPage from '../NotFoundPage';

import BulkCommitmentManagementPageBase from './bulk-commitment/BulkCommitmentManagementPage';
import ClientManagementPageBase from './clients/ClientManagementPage';
import ConditionConfigPageBase from './condition-config/ConditionConfigPage';
import ConfigurableValuesPageBase from './configurable-values/ConfigurableValuesPage';
import CustomerAlertConfigPageBase from './customer-alert-config/CustomerAlertConfigPage';
import CustomerManagementPageBase from './customers/CustomerManagementPage';
import DataRequestsPageBase from './data-requests/DataRequestsPage';
import FieldConfigPageBase from './field-config/FieldConfigPage';
import InvestorEligibilityRoutesBase from './investor-eligibility/InvestorEligibilityRoutes';
import InvestorManagementPageBase from './investors/InternalInvestorManagementPage';
import LoanStatusConfigPageBase from './loan-status/LoanStatusPage';
import LockAvailabilityPageBase from './lockAvailability/LockAvailabilityPage';
import ProductManagementPageBase from './products/ProductManagementPage';
import PurchaseAdviceManagementPageBase from './purchase-advice/PurchaseAdviceManagementPage';
import ClientReferenceGuideManagementPageBase from './reference-guides/ClientReferenceGuideManagementPage';
import CustomerReferenceGuideManagementPageBase from './reference-guides/CustomerReferenceGuideManagementPage';
import RoleManagementPageBase from './roles/RoleManagementPage';
import UnderwritingCategoriesPageBase from './underwriting/UnderwritingCategoriesPage';
import UnderwritingCategoryDetailPage from './underwriting/UnderwritingCategoryDetailPage';
import UnderwritingStepDetailPage from './underwriting/UnderwritingStepDetailPage';
import UserManagementPageBase from './users/UserManagementPage';


const { INTERNAL, CLIENT, CUSTOMER } = UserType;

const BulkCommitmentManagementPage = withAuth(
    BulkCommitmentManagementPageBase, [ PermissionType.VIEW_BULK_COMMITMENT ]
);
const ClientManagementPage = withAuth(ClientManagementPageBase, [ PermissionType.VIEW_CLIENTS ]);
const CustomerManagementPage = withAuth(CustomerManagementPageBase, [ PermissionType.VIEW_CUSTOMERS ]);
const CustomerAlertConfigPage = withAuth(CustomerAlertConfigPageBase, [ PermissionType.VIEW_CUSTOMER_ALERT_CONFIGS ]);
const InvestorManagementPage = withAuth(InvestorManagementPageBase, [ PermissionType.VIEW_INVESTORS ]);
const InvestorEligibilityRoutes = withAuth(
    InvestorEligibilityRoutesBase, [ PermissionType.VIEW_CLIENT_INVESTORS ]
);
const ProductManagementPage = withAuth(ProductManagementPageBase, [ PermissionType.VIEW_PRODUCTS ]);
const PurchaseAdviceManagementPage = withAuth(
    PurchaseAdviceManagementPageBase,
    [ PermissionType.VIEW_PURCHASE_ADVICE_CONFIG, PermissionType.VIEW_PURCHASE_ADVICE_FEE ]
);
const ClientReferenceGuideManagementPage = withAuth(
    ClientReferenceGuideManagementPageBase, [ PermissionType.VIEW_REFERENCE_GUIDES ]
);
const CustomerReferenceGuideManagementPage = withAuth(
    CustomerReferenceGuideManagementPageBase, [ PermissionType.VIEW_REFERENCE_GUIDES ]
);
const LockAvailabilityPage = withAuth(LockAvailabilityPageBase, [ PermissionType.VIEW_LOCK_AVAILABILITY ]);
const LoanStatusConfigPage = withAuth(LoanStatusConfigPageBase, [ PermissionType.VIEW_LOAN_STATUS_CONFIGS ]);
const RoleManagementPage = withAuth(RoleManagementPageBase, [ PermissionType.VIEW_ROLES ]);
const UserManagementPage = withAuth(UserManagementPageBase, [ PermissionType.VIEW_USERS ]);
const LLPARoutes = withAuth(LLPARoutesBase, [ PermissionType.VIEW_LLPAS ]);
const DataRequestsPage = withAuth(DataRequestsPageBase, [ PermissionType.VIEW_DUE_DILIGENCE_STEP ]);
const ConditionConfigPage = withAuth(ConditionConfigPageBase, [ PermissionType.VIEW_CONDITION_CONFIGS ]);
const FieldConfigPage = withAuth(FieldConfigPageBase, [ PermissionType.VIEW_FIELD_CONFIGS ]);
const ConfigurableValuesPage = withAuth(ConfigurableValuesPageBase, [ PermissionType.VIEW_CONFIGURABLE_VALUES ]);
const UnderwritingCategoriesPage = withAuth(
    UnderwritingCategoriesPageBase, [ PermissionType.VIEW_UNDERWRITING_CATEGORIES ]
);

export default function AdminRouteSwitch() {
    const { pathname } = useLocation();
    const { accountUserType } = useGetCurrentAccount();

    return (
        <SentryRoutes>
            <Route
                path="/"
                element={(
                    <Navigate
                        to={{
                            [INTERNAL]: `${pathname}/clients`,
                            [CLIENT]: `${pathname}/customers`,
                            [CUSTOMER]: `${pathname}/users`
                        }[accountUserType]}
                    />
                )}
            />

            <Route
                path="/clients/*"
                element={<ClientManagementPage />}
            />

            <Route
                path="/customers/*"
                element={<CustomerManagementPage />}
            />

            <Route
                path="/customer-alert-config/*"
                element={<CustomerAlertConfigPage />}
            />

            <Route
                path="/bulk-commitments/*"
                element={<BulkCommitmentManagementPage />}
            />

            <Route
                path="/lock-availability/*"
                element={<LockAvailabilityPage />}
            />

            <Route
                path="/investors/*"
                element={<InvestorManagementPage />}
            />

            <Route
                path="/investor-eligibility/*"
                element={<InvestorEligibilityRoutes />}
            />

            <Route
                path="/products/*"
                element={<ProductManagementPage />}
            />

            <Route
                path="/reference-guides/*"
                element={accountUserType === CUSTOMER
                    ? <CustomerReferenceGuideManagementPage />
                    : <ClientReferenceGuideManagementPage />}
            />

            <Route
                path="/purchase-advice/*"
                element={<PurchaseAdviceManagementPage />}
            />

            <Route
                path="/roles/*"
                element={<RoleManagementPage />}
            />

            <Route
                path="/users/*"
                element={<UserManagementPage />}
            />

            <Route
                path="/llpas/*"
                element={<LLPARoutes />}
            />

            <Route
                path="/data-requests"
                element={<DataRequestsPage />}
            />

            <Route
                path="/data-requests/:dataRequestID/*"
                element={<DataRequestsPage />}
            />

            <Route
                path="/loan-status-configs/*"
                element={<LoanStatusConfigPage />}
            />

            <Route
                path="/condition-configs/*"
                element={<ConditionConfigPage />}
            />

            <Route
                path="/field-configs/*"
                element={<FieldConfigPage />}
            />

            <Route
                path="/configurable-values/*"
                element={<ConfigurableValuesPage />}
            />

            <Route
                path="/underwriting-categories/*"
                element={<UnderwritingRoutes />}
            />

            <Route
                path="*"
                element={<NotFoundPage />}
            />
        </SentryRoutes>
    );
}

function UnderwritingRoutes() {
    return (
        <SentryRoutes>
            <Route
                path="*"
                element={<UnderwritingCategoriesPage />}
            />

            <Route
                path="detail/:underwritingCategoryConfigId/*"
                element={<UnderwritingCategoryDetailPage />}
            />

            <Route
                path="detail/:underwritingCategoryConfigId/step/:underwritingStepConfigId/*"
                element={<UnderwritingStepDetailPage />}
            />
        </SentryRoutes>
    );
}
