import { LiabilityBorrower } from 'api/enums/liabilityBorrowerEnum';
import { LiabilityExclusionType } from 'api/enums/liabilityExclusionType';
import { LiabilityLienPosition } from 'api/enums/liabilityLienPosition';
import { LiabilityType } from 'api/enums/liabilityTypeEnum';
import { LiabilityUCDPayoffType } from 'api/enums/liabilityUCDPayoffTypeEnum';
import { SpecialLiabilityType } from 'api/enums/specialLiabilityTypeEnum';

import { apiUtils, getAccountBaseUrl } from '../../api-utils';


export function getUnderwritingLiabilities(
    clientId: string, loanId: string, customerId?: string
): Promise<UnderwritingLiability[]> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}/liability`);
}

export function createUnderwritingLiability(clientId: string, loanId: string, underwritingLiabilityToCreate: Omit<UnderwritingLiability, 'id'>, customerId?: string): Promise<UnderwritingLiability> {
    return apiUtils.post(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}/liability`, underwritingLiabilityToCreate);
}

export function updateUnderwritingLiability(
    clientId: string, loanId: string, underwritingLiabilityToCreate: UnderwritingLiability, customerId?: string
): Promise<UnderwritingLiability> {
    return apiUtils.put(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}/liability/${underwritingLiabilityToCreate.id}`, underwritingLiabilityToCreate);
}

export function deleteUnderwritingLiability(
    clientId: string, loanId: string, underwritingLiabilityId: string, customerId?: string
): Promise<void> {
    return apiUtils.delete(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}/liability/${underwritingLiabilityId}`);
}

export function getSpecialUnderwritingLiabilities(
    clientId: string, loanId: string, customerId?: string
): Promise<SpecialUnderwritingLiability[]> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}/special-liability`);
}

export function createSpecialUnderwritingLiability(clientId: string, loanId: string, specialUnderwritingLiabilityToCreate: Omit<SpecialUnderwritingLiability, 'id'>, customerId?: string): Promise<SpecialUnderwritingLiability> {
    return apiUtils.post(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}/special-liability`, specialUnderwritingLiabilityToCreate);
}

export function updateSpecialUnderwritingLiability(
    clientId: string, loanId: string,
    specialUnderwritingLiabilityToCreate: SpecialUnderwritingLiability, customerId?: string
): Promise<SpecialUnderwritingLiability> {
    return apiUtils.put(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}/special-liability/${specialUnderwritingLiabilityToCreate.id}`, specialUnderwritingLiabilityToCreate);
}

export function deleteSpecialUnderwritingLiability(
    clientId: string, loanId: string, specialUnderwritingLiabilityId: string, customerId?: string
): Promise<void> {
    return apiUtils.delete(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}/special-liability/${specialUnderwritingLiabilityId}`);
}

export interface UnderwritingLiability {
    id: string;
    creditorName: string;
    liabilityType: LiabilityType;
    exclusionType?: LiabilityExclusionType;

    accountName: string;
    accountNumber: string;
    borrowerStatus: LiabilityBorrower;

    monthsToExclude?: number;
    willBePaidOff: boolean;
    purpose: string;
    ucdPayoffType: LiabilityUCDPayoffType;
    excluded: boolean;
    resubordinatedIndicator: boolean;
    subjectProperty: boolean;
    currentLienPosition: LiabilityLienPosition;
    proposedLienPosition: LiabilityLienPosition;

    accountBalance: number;
    monthsLeft: number;
    monthlyPayment: number;
    creditLimit: number;
    paymentIncludesTaxes: boolean;

    paceLoan: boolean;
    energyImprovement: boolean;
}

export interface SpecialUnderwritingLiability {
    id: string;
    specialLiablityType: SpecialLiabilityType;
    otherDescription?: string;

    borrowerStatus: LiabilityBorrower;
    monthlyPayment: number;
    accountBalance?: number;
    monthsLeft?: number;
    creditLimit?: number;
}
