import {
    Breadcrumbs, Link as MuiLink, Skeleton, Toolbar, Typography
} from '@mui/material';
import { Loader, PageMessage } from '@tsp-ui/core/components';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './Page.module.scss';


export interface PageProps {
    breadcrumbs?: ReactNode[];
    breadcrumbPathLengths?: number[];
    explicitBreadcrumbs?: {
        path: string;
        to?: string;
    }[]; // for when regular breadcrumbs don't work
    children: ReactNode;
    className?: string;
    header: ReactNode;
    headerActions?: ReactNode;
    loading?: boolean;
    variant?: 'centered' | 'full';
}

export default function Page({
    breadcrumbs, breadcrumbPathLengths, explicitBreadcrumbs, children, className,
    header, headerActions, loading, variant
}: PageProps) {
    const { search } = useLocation();

    return (
        <main
            className={clsx(styles.main, className, {
                [styles.centered]: variant === 'centered'
            })}
        >
            <Toolbar
                className={styles.toolbar}
                disableGutters
            >
                <Typography
                    variant="h4"
                    component="h1"
                    align={variant === 'centered' ? 'center' : 'left'}
                    className={styles.header}
                >
                    {header}
                </Typography>

                <PageMessage className={styles.pageMessageContainer} />

                <div className={styles.headerActionsContainer}>
                    {headerActions}
                </div>
            </Toolbar>

            {breadcrumbs && (
                <Breadcrumbs
                    className={styles.breadcrumbs}
                    separator="›"
                >
                    {breadcrumbs.map((breadcrumb, index) => {
                        const to = getBreadcrumbTo(index, breadcrumbs.length, breadcrumbPathLengths, search);

                        return index < breadcrumbs.length - 1 ? (
                            <MuiLink
                                key={to}
                                component={Link}
                                to={to}
                                variant="body2"
                            >
                                {breadcrumb || <Skeleton width={70} />}
                            </MuiLink>
                        ) : (
                            <Typography
                                key="end"
                                variant="body2"
                            >
                                {breadcrumb || <Skeleton width={70} />}
                            </Typography>
                        );
                    })}
                </Breadcrumbs>
            )}

            {explicitBreadcrumbs && (
                <Breadcrumbs
                    className={styles.breadcrumbs}
                    separator="›"
                >
                    {explicitBreadcrumbs.map(({ path, to }) => (
                        to ? (
                            <MuiLink
                                key={to}
                                component={Link}
                                to={to}
                                variant="body2"
                            >
                                {path}
                            </MuiLink>
                        ) : (
                            <Typography
                                key={path}
                                variant="body2"
                            >
                                {path}
                            </Typography>
                        )
                    ))}
                </Breadcrumbs>
            )}

            {loading ? (
                <Loader
                    className={styles.loader}
                    loading
                />
            ) : children}
        </main>
    );
}

function getBreadcrumbTo(
    index: number,
    breadcrumbLength: number,
    breadcrumbPathLengths?: number[],
    searchQueryParam?: string
) {
    const path = Array(breadcrumbLength - 1 - index)
        .fill(0)
        .flatMap((_, index) => (
            Array(!breadcrumbPathLengths ? 1 : breadcrumbPathLengths[index]).fill('..')
        ))
        .join('/');

    return `${path}${searchQueryParam || ''}`;
}

Page.defaultProps = {
    variant: 'full'
};
