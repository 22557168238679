
import api, { UnderwritingCategoryConfig } from '@api';
import {
    Button, DialogActions, DialogContent
} from '@mui/material';
import {
    RoutedDialog, RoutedDialogImplProps, TextField, usePageMessage,
    useParams
} from '@tsp-ui/core';
import { getMaxDisplayOrder, useGetCurrentAccount } from '@utils';
import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { UnderwritingCategoryContext } from './UnderwritingCategoriesPage';


interface UnderwritingCategoryFormValues {
    name: string;
}

export default function AddEditUnderwritingCategoryDialog(props: RoutedDialogImplProps) {
    const { id: clientId } = useGetCurrentAccount();
    const { underwritingCategoryConfigId  } = useParams();
    const { setUnderwritingCategoryConfigs, underwritingCategoryConfigs } = useContext(UnderwritingCategoryContext);
    const configToEdit = underwritingCategoryConfigs.find(({ id }) => id === underwritingCategoryConfigId!);
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const formMethods = useForm<UnderwritingCategoryFormValues>({
        defaultValues: configToEdit
    });
    const { handleSubmit } = formMethods;

    const [ saveLoading, setSaveLoading ] = useState(false);

    async function onSubmit(data: UnderwritingCategoryFormValues) {
        setSaveLoading(true);
        try {
            if (configToEdit) {
                // Update existing category
                await api.underwriting.updateUnderwritingCategoryConfig(clientId, {
                    ...configToEdit,
                    name: data.name
                });
                pageMessage.success('Underwriting category configuration updated');
            } else {
                // Create new category
                await api.underwriting.createUnderwritingCategoryConfig(clientId, {
                    name: data.name,
                    displayOrder: getMaxDisplayOrder(underwritingCategoryConfigs) + 1,
                    steps: []
                });
                pageMessage.success('New underwriting category configuration created');
            }

            setUnderwritingCategoryConfigs(await api.underwriting.getUnderwritingCategoryConfigs(clientId));
            navigate(props.closeTo);
        } catch (error) {
            const action = configToEdit ? 'updating' : 'creating';
            pageMessage.handleApiError(`An error occurred while ${action} the configuration`, error);
        }
        setSaveLoading(false);
    }

    return (
        <RoutedDialog
            {...props}
            title={`${configToEdit ? 'Edit' : 'Add'} category`}
            saveLoading={saveLoading}
        >
            <form
                noValidate
                id={formId}
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormProvider {...formMethods}>
                    <DialogContent>
                        <TextField<UnderwritingCategoryConfig>
                            name="name"
                            label="Name"
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            form={formId}
                            type="submit"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </FormProvider>
            </form>
        </RoutedDialog>
    );
}

const formId = 'add-edit-underwriting-category-form';
