export enum LiabilityUCDPayoffType {
    BORROWER_ESTIMATED = 'BORROWER_ESTIMATED',
    COLLECTIONS_JUDGMENTS = 'COLLECTIONS_JUDGMENTS',
    DEFERRED_STUDENT_LOAN = 'DEFERRED_STUDENT_LOAN',
    DELINQUENT_TAXES = 'DELINQUENT_TAXES',
    FIRST_POSITION_MORTGAGE = 'FIRST_POSITION_MORTGAGE',
    GARNISHMENTS = 'GARNISHMENTS',
    HELOC = 'HELOC',
    HOA_LIEN = 'HOA_LIEN',
    INSTALLMENT = 'INSTALLMENT',
    LEASE_PAYMENT = 'LEASE_PAYMENT',
    MORTGAGE_LOAN = 'MORTGAGE_LOAN',
    OPEN_30_DAY_CHARGE = 'OPEN_30_DAY_CHARGE',
    OTHER = 'OTHER',
    PERSONAL_LOAN = 'PERSONAL_LOAN',
    REVOLVING = 'REVOLVING',
    SECOND_POSITION_MORTGAGE = 'SECOND_POSITION_MORTGAGE',
    TAXES = 'TAXES',
    TAX_LIEN = 'TAX_LIEN',
    THIRD_POSITION_MORTGAGE = 'THIRD_POSITION_MORTGAGE',
    UNSECURED_HOME_IMPROVEMENT_INSTALLMENT = 'UNSECURED_HOME_IMPROVEMENT_INSTALLMENT',
    UNSECURED_HOME_IMPROVEMENT_REVOLVING = 'UNSECURED_HOME_IMPROVEMENT_REVOLVING'
}

export const liabilityUCDPayoffTypeDisplay = {
    [LiabilityUCDPayoffType.BORROWER_ESTIMATED]: 'Borrower Estimated Total Monthly Liability Payment',
    [LiabilityUCDPayoffType.COLLECTIONS_JUDGMENTS]: 'Collections Judgments and Liens',
    [LiabilityUCDPayoffType.DEFERRED_STUDENT_LOAN]: 'Deferred Student Loan',
    [LiabilityUCDPayoffType.DELINQUENT_TAXES]: 'Delinquent Taxes',
    [LiabilityUCDPayoffType.FIRST_POSITION_MORTGAGE]: 'First Position Mortgage Lien',
    [LiabilityUCDPayoffType.GARNISHMENTS]: 'Garnishments',
    [LiabilityUCDPayoffType.HELOC]: 'HELOC',
    [LiabilityUCDPayoffType.HOA_LIEN]: 'Homeowners  Association  Lien',
    [LiabilityUCDPayoffType.INSTALLMENT]: 'Installment',
    [LiabilityUCDPayoffType.LEASE_PAYMENT]: 'Lease  Payment',
    [LiabilityUCDPayoffType.MORTGAGE_LOAN]: 'Mortgage  Loan',
    [LiabilityUCDPayoffType.OPEN_30_DAY_CHARGE]: 'Open 30 Day Charge Account',
    [LiabilityUCDPayoffType.OTHER]: 'Other',
    [LiabilityUCDPayoffType.PERSONAL_LOAN]: 'Personal Loan',
    [LiabilityUCDPayoffType.REVOLVING]: 'Revolving',
    [LiabilityUCDPayoffType.SECOND_POSITION_MORTGAGE]: 'Second  Position  Mortgage  Lien',
    [LiabilityUCDPayoffType.TAXES]: 'Taxes',
    [LiabilityUCDPayoffType.TAX_LIEN]: 'Tax Lien',
    [LiabilityUCDPayoffType.THIRD_POSITION_MORTGAGE]: 'Third  Position  Mortgage  Lien',
    [LiabilityUCDPayoffType.UNSECURED_HOME_IMPROVEMENT_INSTALLMENT]: 'Unsecured  Home  Improvement  Loan  Installment',
    [LiabilityUCDPayoffType.UNSECURED_HOME_IMPROVEMENT_REVOLVING]: 'Unsecured Home Improvement Loan Revolving'
};
