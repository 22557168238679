import api, { UnderwritingQuestionType } from '@api';
import {
    Button, DialogActions, DialogContent, Tooltip
} from '@mui/material';
import {
    RoutedDialog, RoutedDialogImplProps, Switch, TextField, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { UnderwritingQuestionContext } from '../UnderwritingStepDetailPage';

import { UnderwritingQuestionFormValues } from './AddEditQuestionDialog';
import styles from './AddEditQuestionDialog.module.scss';


export function AddEditGroupDialog(props: RoutedDialogImplProps) {
    const { id: clientId } = useGetCurrentAccount();
    const { underwritingCategoryConfigId, underwritingStepConfigId, underwritingQuestionConfigId } = useParams();
    const { questionConfigs, setQuestionConfigs } = useContext(UnderwritingQuestionContext);
    const configToEdit = questionConfigs.find(({ id }) => id === underwritingQuestionConfigId!);
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const formMethods = useForm<UnderwritingQuestionFormValues>({
        defaultValues: configToEdit
    });
    const { handleSubmit } = formMethods;
    const [ saveLoading, setSaveLoading ] = useState(false);

    async function onSubmit(formValues: UnderwritingQuestionFormValues) {
        setSaveLoading(true);

        try {
            if (configToEdit) {
                await api.underwriting.updateUnderwritingQuestionConfig(
                    clientId, underwritingCategoryConfigId!, underwritingStepConfigId!, formValues
                );
                pageMessage.success('Underwriting question group updated');
            } else {
                formValues.type = UnderwritingQuestionType.GROUP;
                await api.underwriting.createUnderwritingQuestionConfig(
                    clientId,
                    underwritingCategoryConfigId!,
                    underwritingStepConfigId!,
                    formValues
                );
                pageMessage.success('New underwriting question group created');
            }

            setQuestionConfigs(await api.underwriting.getUnderwritingQuestionConfigs(
                clientId, underwritingCategoryConfigId!, underwritingStepConfigId!
            ));
            navigate(props.closeTo);
        } catch (error) {
            pageMessage.handleApiError('Error adding question', error);
        }

        setSaveLoading(false);
    }

    const initialQuestionInStep = questionConfigs.find(q => q.isInitialQuestion);
    const isInitialQuestionDisabled = !!initialQuestionInStep
        && initialQuestionInStep.id !== formMethods.getValues().id;

    return (
        <RoutedDialog
            {...props}
            title={configToEdit ? 'Edit group' : 'Add group'}
            saveLoading={saveLoading}
        >
            <DialogContent>
                <form
                    noValidate
                    id={formId}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormProvider {...formMethods}>
                        <div className={styles.dialogContent}>
                            <TextField<UnderwritingQuestionFormValues>
                                name="text"
                                label="Group name"
                                required
                            />

                            <Tooltip title={isInitialQuestionDisabled ? `"${initialQuestionInStep.text}" is already the initial question in this group. To select a new one, you must first disable it.` : ''}>
                                {/* span needed here for tooltip to work while disabled */}
                                <span>
                                    <Switch<UnderwritingQuestionFormValues>
                                        name="isInitialQuestion"
                                        label="Is first question in step"
                                        disabled={isInitialQuestionDisabled}
                                    />
                                </span>
                            </Tooltip>
                        </div>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    form={formId}
                    type="submit"
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

const formId = 'add-edit-underwriting-group-form';
