export enum LiabilityType {
    CHILD_CARE = 'CHILD_CARE',
    CHILD_SUPPORT = 'CHILD_SUPPORT',
    COLLECTIONS_JUDGEMENTS_LIENS = 'COLLECTIONS_JUDGEMENTS_LIENS',
    HELOC = 'HELOC',
    INSTALLMENT = 'INSTALLMENT',
    LEASE_PAYMENTS = 'LEASE_PAYMENTS',
    MORTGAGE_LOAN = 'MORTGAGE_LOAN',
    OPEN_30_DAY_CHARGE = 'OPEN_30_DAY_CHARGE',
    OTHER_LIABILITY = 'OTHER_LIABILITY',
    REVOLVING = 'REVOLVING',
    SEPARATE_MAINTENANCE = 'SEPARATE_MAINTENANCE',
    OTHER_EXPENSE = 'OTHER_EXPENSE',
    TAXES = 'TAXES',
    TAX_LIEN = 'TAX_LIEN'
}

export const liabilityTypeDisplay = {
    [LiabilityType.CHILD_CARE]: 'Child Care',
    [LiabilityType.CHILD_SUPPORT]: 'Child Support',
    [LiabilityType.COLLECTIONS_JUDGEMENTS_LIENS]: 'Collections, Judgements, and Liens',
    [LiabilityType.HELOC]: 'HELOC',
    [LiabilityType.INSTALLMENT]: 'Installment',
    [LiabilityType.LEASE_PAYMENTS]: 'Lease Payments',
    [LiabilityType.MORTGAGE_LOAN]: 'Mortgage Loan',
    [LiabilityType.OPEN_30_DAY_CHARGE]: 'Open 30 Day Charge Account',
    [LiabilityType.OTHER_LIABILITY]: 'Other Liability',
    [LiabilityType.REVOLVING]: 'Revolving',
    [LiabilityType.SEPARATE_MAINTENANCE]: 'Separate Maintenance Expense',
    [LiabilityType.OTHER_EXPENSE]: 'Other Expense',
    [LiabilityType.TAXES]: 'Taxes',
    [LiabilityType.TAX_LIEN]: 'Tax Lien'
};
