import {
    UnderwritingFieldConfig, UnderwritingFieldResponse, UnderwritingFieldType, UnderwritingQuestionConfig,
    UnderwritingQuestionResponse
} from '@api';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button, replaceItemByKey, tryGetItemByKey } from '@tsp-ui/core';
import { Markdown } from '@views/components/Markdown';
import { ChangeEvent, useContext, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

import { UnderwritingCategoriesContext } from '../UnderwritingCategories';
import { UnderWritingStepContext } from '../UnderwritingStepSection';
import {
    UnderwritingCardWrapper, UnderwritingQuestionCardProps,
    injectValues, isUnderwritingFormComplete
} from '../underwriting-common';

import styles from './FormQuestionCard.module.scss';


export function FormQuestionCard({ questionConfig, questionResponse }: UnderwritingQuestionCardProps) {
    const {
        setReadyToSubmit, updateQuestionIdsToDisplayAndSideEffects,
        setQuestionResponse, questionResponses
    } = useContext(UnderWritingStepContext);
    const { underwritingData } = useContext(UnderwritingCategoriesContext);

    const formIsCompleted = isUnderwritingFormComplete(questionConfig, questionResponse);

    useEffect(() => {
        if (questionConfig.next?.action === 'END') {
            if (formIsCompleted) {
                setReadyToSubmit(true);
            } else {
                setReadyToSubmit(false);
            }
        }
    }, [
        formIsCompleted, questionConfig, setReadyToSubmit
    ]);

    return (
        <UnderwritingCardWrapper
            questionConfig={questionConfig}
            questionResponse={questionResponse}
            className={styles.formQuestion}
        >
            <Markdown valuesToInject={underwritingData}>
                {questionConfig.text}
            </Markdown>

            <UnderwritingQuestionForm
                questionConfig={questionConfig}
                questionResponse={questionResponse}
                getOnChange={fieldResponse => (newValue) => {
                    const existingFieldResponse = tryGetItemByKey(questionResponse?.fieldValues || [], 'fieldId', fieldResponse.fieldId);
                    setQuestionResponse({
                        ...questionResponse,
                        // check if field exists in response
                        // if so, replace it
                        // othersiee, add a new field response to field values in the question response
                        fieldValues: existingFieldResponse
                            ? replaceItemByKey(questionResponse?.fieldValues || [], {
                                ...existingFieldResponse,
                                value: newValue
                            }, 'fieldId')
                            : (questionResponse?.fieldValues || []).concat({
                                ...fieldResponse,
                                value: newValue
                            })
                    });
                }}
            />

            {/* if question is last in the step, use ready to submit.
            if question has a next action, use this next button instead */}
            {questionConfig.next?.action === 'CONTINUE' && (
                <Button
                    onClick={async () => {
                        // updates visibleQuestionIds and sideEffects
                        await updateQuestionIdsToDisplayAndSideEffects(questionResponses);
                    }}
                    disabled={!formIsCompleted}
                >
                    {questionConfig.next.text}
                </Button>
            )}
        </UnderwritingCardWrapper>
    );
}

function getFieldResponse(fieldConfig: UnderwritingFieldConfig, questionResponse?: UnderwritingQuestionResponse) {
    return tryGetItemByKey(questionResponse?.fieldValues || [], 'fieldId', fieldConfig.id);
}

export function UnderwritingQuestionForm({ questionConfig, questionResponse, getOnChange }: {
    questionConfig: UnderwritingQuestionConfig;
    questionResponse?: UnderwritingQuestionResponse;
    getOnChange: (field: UnderwritingFieldResponse) => (newValue: string | Date | undefined | null) => void;
}) {
    const { underwritingData } = useContext(UnderwritingCategoriesContext);

    // this is the set of COMPLETE field responses, i.e. it includes undefined values for the fields that do not
    // yet have a response
    const fieldResponses = questionConfig.fields?.map(fieldConfig => {
        const fieldResponse = getFieldResponse(fieldConfig, questionResponse);

        return ({
            fieldId: fieldConfig.id,
            value: fieldResponse?.value,
            id: fieldResponse?.id || ''
        });
    }) || [];

    if (!questionConfig.fields) {
        return null;
    }

    return (
        <div className={styles.formQuestionFields}>
            {questionConfig.fields?.map((fieldConfig) => {
                const fieldResponse = fieldResponses.find(f => f.fieldId === fieldConfig.id)!;
                const fieldValue = fieldResponse.value;
                const onChange = getOnChange(fieldResponse);
                const fieldOnChange = (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value);

                console.log(`Found value "${fieldValue}" for field id "${fieldConfig.id}"`);

                const commonProps = {
                    key: fieldConfig.dataSourceId,
                    style: {
                        gridColumn: `span ${fieldConfig.size || 6}`,
                        minWidth: `${fieldConfig.label.length * 9}px` // Ensure the field grows to fit the label
                    },
                    helperText: underwritingData ? injectValues(
                        fieldConfig.helperText, underwritingData
                    ) : fieldConfig.helperText,
                    label: fieldConfig.label,
                    value: fieldValue as string,
                    disabled: fieldConfig.isDisabled,
                    onChange: fieldOnChange
                };

                return (
                    <>
                        {fieldConfig.type === TEXT && (
                            <TextField {...commonProps} />
                        )}

                        {fieldConfig.type === NUMBER && (
                            <TextField
                                {...commonProps}
                                type="number"
                                className={styles.hideSpinners}
                            />
                        )}

                        {fieldConfig.type === DATE && (
                            <DatePicker
                                value={fieldValue || null}
                                onChange={onChange}
                                disabled={fieldConfig.isDisabled}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        {...commonProps}
                                        onChange={params.onChange}
                                    />
                                )}
                            />
                        )}

                        {fieldConfig.type === CURRENCY && (
                            <NumericFormat
                                thousandsGroupStyle="thousand"
                                prefix="$"
                                decimalScale={2}
                                decimalSeparator="."
                                displayType="input"
                                thousandSeparator
                                allowNegative
                                customInput={TextField}
                                {...commonProps}
                            />
                        )}

                        {fieldConfig.type === PERCENTAGE && (
                            <NumericFormat
                                suffix="%"
                                customInput={TextField}
                                {...commonProps}
                            />
                        )}
                    </>
                );
            })}
        </div>
    );
}

const {
    TEXT, NUMBER, DATE, CURRENCY, PERCENTAGE
} = UnderwritingFieldType;
