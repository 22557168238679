/* eslint-disable max-len */
import {
    UnderwritingQuestionConfig, UnderwritingQuestionResponse, UnderwritingQuestionType, UnderwritingSideEffect
} from '@api';
import { Paper } from '@mui/material';
import { tryConcat } from '@utils/array-utils';
import { HTMLAttributes } from 'react';

import { CustomQuestionCard } from './question-cards/CustomQuestionCard/CustomQuestionCard';
import { ForeachQuestionCard } from './question-cards/ForeachQuestionCard';
import { FormQuestionCard } from './question-cards/FormQuestionCard';
import { GroupQuestionCard } from './question-cards/GroupQuestionCard';
import { InfoQuestionCard } from './question-cards/InfoQuestionCard';
import { SingleSelectQuestionCard } from './question-cards/SingleSelectQuestionCard';
import { TableQuestionCard } from './question-cards/TableQuestionCard';


export interface UnderwritingQuestionCardProps {
    questionConfig: UnderwritingQuestionConfig;
    questionResponse: UnderwritingQuestionResponse;
}

/**
 * **Either a Paper or a div**
 *
 * This component determins whether the underwriting question is a top-level question or a sub-question.
 * If it's a top level question, it renders children in a Paper component.
 * If it's a sub-question, it uses a div.
 */
export function UnderwritingCardWrapper(
    { questionConfig, ...props }: UnderwritingQuestionCardProps & HTMLAttributes<HTMLDivElement>
) {
    const isSubQuestion = questionConfig.parentQuestionId; // represents if this question belongs to a group
    const WrapperComponent = isSubQuestion ? 'div' : Paper;

    return (
        <WrapperComponent
            variant="outlined"
            {...props}
        />
    );
}

export function getQuestionIdsToDisplayAndSideEffects(
    questionConfigs: UnderwritingQuestionConfig[],
    questionResponses: UnderwritingQuestionResponse[],
    // defaults to the initial question
    questionConfig: UnderwritingQuestionConfig | undefined = questionConfigs.find(config => config.isInitialQuestion),
    // defaults to just the initial question id
    questionIdsToDisplay: string[] = questionConfigs.find(
        config => config.isInitialQuestion
    ) ? [ questionConfigs.find(config => config.isInitialQuestion)!.id ] : [],
    sideEffects: UnderwritingSideEffect[] = []
): { questionIdsToDisplay: string[], sideEffects: UnderwritingSideEffect[] } {
    function getNextQuestion(questionId: string): UnderwritingQuestionConfig | undefined {
        return questionConfigs.find(config => config.id === questionId);
    }
    if (questionConfig?.showNextQuestion && questionConfig.next?.continueTo) {
        return getQuestionIdsToDisplayAndSideEffects(
            questionConfigs,
            questionResponses,
            getNextQuestion(questionConfig.next.continueTo),
            [ ...questionIdsToDisplay, questionConfig.next.continueTo ],
            tryConcat(sideEffects, questionConfig.next.sideEffects)
        );
    }

    const questionResponse = questionResponses.find(
        response => questionConfig?.id && (response.questionId === questionConfig.id)
    );

    if (!questionConfig) {
        return {
            questionIdsToDisplay,
            sideEffects
        };
    }

    // group questions don't need a response, other question types do
    if (!questionResponse && questionConfig.type !== UnderwritingQuestionType.GROUP) {
        return {
            questionIdsToDisplay,
            sideEffects
        };
    }

    if (questionConfig.type === UnderwritingQuestionType.SINGLE_SELECT) {
        // const isInitialQuestion = questionConfig.isInitialQuestion;
        // if (isInitialQuestion) {
        //     console.log('initial question: ', questionConfig.text);
        //     console.log('initial question response: ', questionResponse);
        //     debugger;
        // }
        if (!questionResponse?.answer) {
            console.log('no answer yet for single select, returning questionIdsToDisplay: ', questionIdsToDisplay);
            return {
                questionIdsToDisplay,
                sideEffects
            };
        } else {
            const answeredChoice = questionConfig.choices?.find(choice => choice.text === questionResponse.answer);
            console.log('User has selected: ', `["${answeredChoice?.text}"->${answeredChoice?.action}]`, 'for question: ', questionConfig.text);

            if (answeredChoice?.action === 'CONTINUE') {
                console.log('continuing to question: ', getNextQuestion(answeredChoice.continueTo || '')?.text);
                return getQuestionIdsToDisplayAndSideEffects(
                    questionConfigs,
                    questionResponses,
                    getNextQuestion(answeredChoice.continueTo || ''),
                    [ ...questionIdsToDisplay, answeredChoice.continueTo || '' ],
                    tryConcat(sideEffects, answeredChoice.sideEffects)
                );
            } else if (answeredChoice?.action === 'END') {
                console.log('ending underwriting step with questionIdsToDisplay: ', questionIdsToDisplay);
                return {
                    questionIdsToDisplay,
                    sideEffects: tryConcat(sideEffects, answeredChoice.sideEffects)
                };
            }
        }
    } else if (questionConfig.type === UnderwritingQuestionType.FORM) {
        const formIsCompleted = isUnderwritingFormComplete(questionConfig, questionResponse);

        if (!formIsCompleted) {
            return {
                questionIdsToDisplay,
                sideEffects
            };
        } else if (questionConfig.next?.action === 'END') {
            return {
                questionIdsToDisplay,
                sideEffects: tryConcat(sideEffects, questionConfig.next.sideEffects)
            };
        } else {
            return getQuestionIdsToDisplayAndSideEffects(
                questionConfigs,
                questionResponses,
                getNextQuestion(questionConfig.next!.continueTo || ''),
                [ ...questionIdsToDisplay, questionConfig.next?.continueTo || '' ],
                tryConcat(sideEffects, questionConfig.next?.sideEffects)
            );
        }
    } else if (questionConfig.type === UnderwritingQuestionType.INFO) {
        if (questionResponse?.answer) { // just check if it's truthy
            if (questionConfig.next?.action === 'END') {
                return {
                    questionIdsToDisplay,
                    sideEffects: tryConcat(sideEffects, questionConfig.next?.sideEffects)
                };
            } else {
                return getQuestionIdsToDisplayAndSideEffects(
                    questionConfigs,
                    questionResponses,
                    getNextQuestion(questionConfig.next!.continueTo || ''),
                    [ ...questionIdsToDisplay, questionConfig.next?.continueTo || '' ],
                    tryConcat(sideEffects, questionConfig.next?.sideEffects)
                );
            }
        } else {
            return {
                questionIdsToDisplay,
                sideEffects
            };
        }
    } else if (questionConfig.type === UnderwritingQuestionType.GROUP) {
        const groupQuestions = questionConfigs.filter(
            config => config.parentQuestionId === questionConfig.id
        );

        const firstQuestionInGroup = groupQuestions.find(
            config => config.isInitialQuestion
        );

        if (firstQuestionInGroup) {
            return getQuestionIdsToDisplayAndSideEffects(
                questionConfigs,
                questionResponses,
                firstQuestionInGroup,
                [ ...questionIdsToDisplay, firstQuestionInGroup.id ],
                sideEffects
            );
        } else {
            return {
                questionIdsToDisplay,
                sideEffects: tryConcat(sideEffects, questionConfig.next?.sideEffects)
            };
        }
    } else if (questionConfig.type === UnderwritingQuestionType.FOREACH) {
        // debugger;
        const subQuestionConfigs = questionConfigs.filter(
            config => config.parentQuestionId === questionConfig.id
        );

        // const subQuestionSideEffects = question.arrayData?.flatMap((item, index) => {
        //     const subQuestionsForIndex = question.questions?.map(
        //         forEachSubQuestionConfig => createForEachQuestionWithIndex(forEachSubQuestionConfig, question, index)
        //     ) || [];

        //     return subQuestionsForIndex.flatMap(subQuestion => getUnderwritingStepData(
        //         {
        //             ...underwritingStep,
        //             questions: subQuestionsForIndex
        //         },
        //         subQuestion
        //     ).sideEffects);
        // }) || [];

        // const subQuestionIds = question.arrayData?.flatMap((item, index) => {
        //     const subQuestionsForIndex = question.questions?.map(
        //         forEachSubQuestionConfig => createForEachQuestionWithIndex(forEachSubQuestionConfig, question, index)
        //     ) || [];

        //     return getUnderwritingStepData(
        //         {
        //             ...underwritingStep,
        //             questions: subQuestionsForIndex
        //         },
        //         subQuestionsForIndex[0],
        //         [ subQuestionsForIndex[0]?.id ]
        //     ).questionIdsToDisplay;
        // }) || [];

        // subquestion ids should be the questionConfig.id of the subquestion where parentQuestionId === questionConfig.id, and the entity id, concatenated with a "-"
        // There is one response for the parent foreach question PER entity id. So all the entity ids will be in the responses
        const entityIds = questionResponses.filter(
            response => response.questionId === questionConfig.id
        ).map(response => response.entityId);

        // if there are no entity ids, we can't proceed
        if (!entityIds.length) {
            return {
                questionIdsToDisplay,
                sideEffects
            };
        }

        const subQuestionIds = entityIds.flatMap(entityId => {
            const mappedConfigsForEntity = subQuestionConfigs.map(subQuestion => ({
                ...subQuestion,
                id: `${subQuestion.id}-${entityId}`,
                next: subQuestion.next ? {
                    ...subQuestion.next,
                    continueTo: `${subQuestion.next.continueTo}-${entityId}`
                } : undefined,
                choices: subQuestion.choices?.map(choice => ({
                    ...choice,
                    continueTo: `${choice.continueTo}-${entityId}`
                }))
            }));
            const firstSubQuestionForEntity = mappedConfigsForEntity.find(subQuestion => subQuestion.isInitialQuestion);

            if (firstSubQuestionForEntity) {
                const subQuestionIdsToDisplayForEntity = getQuestionIdsToDisplayAndSideEffects(
                    // replace the continueTo Ids on the congifs with id-entityId
                    mappedConfigsForEntity,
                    questionResponses.filter(response => response.entityId === entityId).map(response => ({
                        ...response,
                        questionId: `${response.questionId}-${entityId}`
                    })), // important must filer responses for entity
                    firstSubQuestionForEntity,
                    [ firstSubQuestionForEntity.id ],
                    sideEffects
                ).questionIdsToDisplay;

                return subQuestionIdsToDisplayForEntity;
            } else {
                return [];
            }
        });

        // if all subquestions for all entities have been answered, we can proceed
        if (areAllForeachItemsComplete(questionConfigs, questionConfig.id, questionResponses)) {
            if (questionConfig.next?.action === 'CONTINUE') {
                return getQuestionIdsToDisplayAndSideEffects(
                    questionConfigs,
                    questionResponses,
                    getNextQuestion(questionConfig.next!.continueTo || ''),
                    [
                        ...questionIdsToDisplay,
                        ...subQuestionIds,
                        questionConfig.next?.continueTo || ''
                    ],
                    tryConcat(sideEffects, questionConfig.next?.sideEffects)
                );
            } else { // if action is end
                return {
                    questionIdsToDisplay: [ ...questionIdsToDisplay, ...subQuestionIds ],
                    sideEffects: tryConcat(sideEffects)
                };
            }
        } else {
            return {
                questionIdsToDisplay: [ ...questionIdsToDisplay, ...subQuestionIds ],
                sideEffects: tryConcat(
                    sideEffects,
                    // TODO post-demo make sure that side effects are recursively added for foreach subquestions based on which questions were answered
                    subQuestionConfigs.map(subQuestion => subQuestion.next?.sideEffects || []).flat()
                )
            };
        }
    } else if (questionConfig.type === UnderwritingQuestionType.TABLE_ENTRY) {
        const tableIsCompleted = questionResponse?.tableRowValues?.every(row => row.values.every(value => ![
            null, undefined, ''
        ].includes(value.value)));

        if (tableIsCompleted) {
            // if any sideEffects have subType 'FOREACH', we need to add a side effect for each row in the table
            const tableSideEffects = questionConfig.next?.sideEffects?.flatMap((sideEffect) => {
                if (sideEffect.subType === 'FOREACH') {
                    return questionResponse?.tableRowValues?.map((tableRowValues) => ({
                        ...sideEffect,
                        text: injectValues(sideEffect.text, tableRowValues),
                        description: injectValues(sideEffect.description, tableRowValues)
                    }));
                } else {
                    return sideEffect;
                }
            })?.filter(Boolean) as UnderwritingSideEffect[] | undefined;

            if (questionConfig.next?.action === 'END') {
                return {
                    questionIdsToDisplay,
                    sideEffects: tryConcat(sideEffects, tableSideEffects)
                };
            } else {
                return getQuestionIdsToDisplayAndSideEffects(
                    questionConfigs,
                    questionResponses,
                    getNextQuestion(questionConfig.next!.continueTo || ''),
                    [ ...questionIdsToDisplay, questionConfig.next?.continueTo || '' ],
                    tryConcat(sideEffects, tableSideEffects)
                );
            }
        } else {
            return {
                questionIdsToDisplay,
                sideEffects
            };
        }
    } else if (questionConfig.type === UnderwritingQuestionType.CUSTOM) {
        if (questionConfig.next?.action === 'END') {
            return {
                questionIdsToDisplay,
                sideEffects: tryConcat(sideEffects, questionConfig.next?.sideEffects)
            };
        } else {
            return getQuestionIdsToDisplayAndSideEffects(
                questionConfigs,
                questionResponses,
                getNextQuestion(questionConfig.next!.continueTo || ''),
                [ ...questionIdsToDisplay, questionConfig.next?.continueTo || '' ],
                tryConcat(sideEffects, questionConfig.next?.sideEffects)
            );
        }
    } else {
        return {
            questionIdsToDisplay,
            sideEffects
        };
    }

    return {
        questionIdsToDisplay,
        sideEffects
    };
}

/**
 * This function is used to determine if all of the foreach items have been completed.
 * When all subquestions on all items in the foreach have been answered, this function will return true,
 * and the question's next action will be triggered.
 */
export function areAllForeachItemsComplete(questionConfigs: UnderwritingQuestionConfig[], forEachQuestionId: string, questionResponses: UnderwritingQuestionResponse[]): boolean {
    // question.questions is the question configs
    // question.arrayData is the data for the foreach
    // for each item in arrayData, check if each question from question.questions has an answer
    // if there are no items in arrayData, return false

    // return !!question.arrayData?.length && question.arrayData.every((_, index) => {
    //     const subQuestions = question.questions?.map(
    //         subQuestion => createForEachQuestionWithIndex(subQuestion, question, index)
    //     );
    //     return !subQuestions || subQuestions.every(subQuestion => !!subQuestion.answer);
    // });

    const subQuestionConfigs = questionConfigs.filter(
        config => config.parentQuestionId === forEachQuestionId
    );

    const forEachEntityIds = questionResponses.filter(
        response => response.questionId === forEachQuestionId
    ).map(response => response.entityId);

    // check that for each entity, each qiuestion config has an associated response
    return !!forEachEntityIds.length && forEachEntityIds.every(
        entityId => subQuestionConfigs.every(
            subQuestion => !!questionResponses.find(
                response => response.questionId === subQuestion.id && response.entityId === entityId
            )
        )
    );
}

/**
 * Maps the underwriting question type to the correct card component.
 * - SINGLE_SELECT questions are mapped to SingleSelectQuestionCard
 * - FORM questions are mapped to FormQuestionCard
 * - INFO questions are mapped to InfoQuestionCard
 * - TABLE_ENTRY questions are mapped to TableQuestionCard
 * - GROUP questions are mapped to GroupQuestionCard
 */
export const questionTypeToComponentMap = {
    [UnderwritingQuestionType.SINGLE_SELECT]: SingleSelectQuestionCard,
    [UnderwritingQuestionType.FORM]: FormQuestionCard,
    [UnderwritingQuestionType.INFO]: InfoQuestionCard,
    [UnderwritingQuestionType.TABLE_ENTRY]: TableQuestionCard,
    [UnderwritingQuestionType.GROUP]: GroupQuestionCard,
    [UnderwritingQuestionType.FOREACH]: ForeachQuestionCard,
    [UnderwritingQuestionType.CUSTOM]: CustomQuestionCard
};

export function isUnderwritingFormComplete(
    questionConfig: UnderwritingQuestionConfig, questionResponse?: UnderwritingQuestionResponse
): boolean {
    // debugger;
    if (!questionResponse) {
        return false;
    }

    const emptyFormValues: (string | Date | null | undefined)[] = [
        '', null, undefined
    ];

    return !!questionConfig.fields?.every(
        fieldConfig => {
            // If the field is disabled or not required, return true
            if (fieldConfig.isDisabled || fieldConfig.isRequired === false) {
                return true;
            }

            // otherwise, only return true if it's populated and valid
            const fieldValue = questionResponse.fieldValues?.find(
                fieldValue => fieldValue.fieldId === fieldConfig.id
            )?.value;

            const isFieldPopulated = !emptyFormValues.includes(fieldValue);
            const isFieldValid = !(fieldValue instanceof Date && isNaN(fieldValue.getTime())); // only checks if it's a date, is the date valid. If not a date, assumed valid

            return isFieldPopulated && isFieldValid;
        }
    );
}

/**
 * Replaces placeholders in the template string with corresponding values from the provided object.
 */
export function injectValues(template: string | undefined, values: Record<string, any>): string | undefined {
    // Matches placeholders like {{ loan.data.commitment_expiry_date }} or {{ myKey }}
    const placeholderRegex = /\{\{\s*([\w.]+)\s*\}\}/g;

    return template?.replace(
        placeholderRegex,
        (_, path) => {
            // Split the path by dots to traverse the nested structure
            const keys = path.split('.');
            let value = values;

            // Traverse the object using each key in the path
            for (const key of keys) {
                if (value && key in value) {
                    value = value[key];
                } else {
                    // If a key is missing, return an empty string
                    return '';
                }
            }

            // Convert the resolved value to a string, or use an empty string if undefined
            return value !== undefined ? String(value) : '';
        }
    );
}

export function isQuestionAnswered(questionConfig: UnderwritingQuestionConfig, questionResponse: UnderwritingQuestionResponse | undefined, questionConfigs: UnderwritingQuestionConfig[], questionResponses: UnderwritingQuestionResponse[]): boolean {
    if (questionConfig.type === 'CUSTOM') {
        return true;
    } else if (questionConfig.type === 'SINGLE_SELECT') {
        return !!questionResponse?.answer;
    } else if (questionConfig.type === UnderwritingQuestionType.FOREACH) {
        return areAllForeachItemsComplete(questionConfigs, questionConfig.id, questionResponses);
    } else if (questionConfig.type === UnderwritingQuestionType.FORM) {
        return isUnderwritingFormComplete(questionConfig, questionResponse);
    } else if (questionConfig.type === UnderwritingQuestionType.GROUP) {
        const groupQuestions = questionConfigs.filter(q => q.parentQuestionId === questionConfig.id);
        return !!groupQuestions?.every(q => isQuestionAnswered(q, questionResponses.find(r => r.questionId === q.id), questionConfigs, questionResponses));
    } else if (questionConfig.type === UnderwritingQuestionType.INFO) {
        return !!questionResponse?.answer;
    } else if (questionConfig.type === UnderwritingQuestionType.TABLE_ENTRY) {
        return !!questionResponse?.tableRowValues?.every(row => row?.values.every(cell => !!cell.value));
    }

    return true;
}
