import api, { PermissionType, UnderwritingCategoryConfig } from '@api';
import {
    Button,
    FilledSection, RoutedDialogManager, useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount, useHasPermission, withAuth } from '@utils';
import Page from '@views/components/Page';
import React, {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Link } from 'react-router-dom';

import AddEditUnderwritingCategoryDialog from './AddEditUnderwritingCategoryDialog';
import styles from './UnderwritingCategoriesPage.module.scss';
import { UnderwritingCategoryConfigCard } from './UnderwritingCategoryConfigCard';


export interface UnderwritingCategoryContextValue {
    underwritingCategoryConfigs: UnderwritingCategoryConfig[];
    setUnderwritingCategoryConfigs: Dispatch<SetStateAction<UnderwritingCategoryConfig[]>>;
}

export const UnderwritingCategoryContext = createContext<UnderwritingCategoryContextValue>({
    underwritingCategoryConfigs: [],
    setUnderwritingCategoryConfigs: () => {}
});

export default function UnderwritingCategoriesPage() {
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();
    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ]);

    const [ loading, setLoading ] = useState(false);
    const [ underwritingCategoryConfigs, setUnderwritingCategoryConfigs ] = useState<UnderwritingCategoryConfig[]>([]);

    const orderedConfigs = underwritingCategoryConfigs.sort((a, b) => a.displayOrder - b.displayOrder);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setUnderwritingCategoryConfigs(await api.underwriting.getUnderwritingCategoryConfigs(clientId));
        } catch (e) {
            pageMessage.handleApiError('An error occurred while fetching underwriting category configs', e);
        }

        setLoading(false);
    }, [ clientId, pageMessage ]));

    const underwritingCategoryContextValue = useMemo(() => ({
        underwritingCategoryConfigs,
        setUnderwritingCategoryConfigs
    }), [ underwritingCategoryConfigs ]);

    return (
        <UnderwritingCategoryContext.Provider value={underwritingCategoryContextValue}>
            <Page
                header="Underwriting Category Management"
                loading={loading}
            >
                <div className={styles.root}>
                    <FilledSection
                        className={styles.section}
                        header={(
                            <>
                                <span>
                                    Underwriting categories
                                </span>

                                <Button
                                    component={Link}
                                    to="add"
                                    disabled={!canManage}
                                    className={styles.headerButton}
                                >
                                    Add category
                                </Button>
                            </>
                        )}
                        noResultsMessage="No underwriting category configurations are currently configured."
                    >
                        {orderedConfigs.map((config) => (
                            <UnderwritingCategoryConfigCard
                                key={config.id}
                                underwritingCategoryConfig={config}
                            />
                        ))}
                    </FilledSection>
                </div>

                <RoutedDialogManager
                    routes={dialogRoutes}
                />
            </Page>
        </UnderwritingCategoryContext.Provider>
    );
}

const dialogRoutes = {
    add: withAuth(AddEditUnderwritingCategoryDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true),
    ':underwritingCategoryConfigId': withAuth(AddEditUnderwritingCategoryDialog, [ PermissionType.MANAGE_UNDERWRITING_CATEGORIES ], true)
};
