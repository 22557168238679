import { LOSIterableEntity, UnderwritingQuestionType, losIterableEntityDisplay } from '@api';
import { TextField, renderEnumOptions } from '@tsp-ui/core';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { UnderwritingQuestionContext } from '../../UnderwritingStepDetailPage';
import { UnderwritingQuestionFormValues } from '../AddEditQuestionDialog';


export function ForEachFormContent() {
    const questionId = useFormContext<UnderwritingQuestionFormValues>().watch('id');
    const hasChildren = useContext(UnderwritingQuestionContext).questionConfigs.some(
        ({ parentQuestionId }) => parentQuestionId === questionId
    ) && !!questionId;

    return (
        <TextField<UnderwritingQuestionFormValues>
            name="entityName"
            label="Linked LOS Entity"
            helperText={hasChildren ? 'Cannot update entity because subquestions have already been configured' : 'Please select which LOS entity this question will correspond to'}
            required
            select
            disabled={hasChildren}
        >
            {renderEnumOptions(losIterableEntityDisplay)}
        </TextField>
    );
}

/**
 * If we are outside of the form context, we can pass in the parentQuestionId instead of using the form context
 * @param parentQuestionId
 */
export function useIsForEachChild(
    parentQuestionId?: string
): { isForEachChild: boolean, parentEntity: LOSIterableEntity } {
    const formMethods = useFormContext<UnderwritingQuestionFormValues>();
    const { questionConfigs } = useContext(UnderwritingQuestionContext);

    if (!parentQuestionId) {
        parentQuestionId = formMethods?.getValues?.()?.parentQuestionId;
    }
    const parentQuestion = questionConfigs.find(
        ({ id }) => id === parentQuestionId
    );
    const { entityName } = parentQuestion || {};
    const isForEachChild = parentQuestion?.type === UnderwritingQuestionType.FOREACH;

    return {
        isForEachChild,
        parentEntity: entityName!
    };
}
