export enum LiabilityLienPosition {
    FIRST = '1',
    SECOND = '2',
    THIRD = '3',
    FOURTH = '4'
}

export const liabilitylienPositionDisplay = {
    [LiabilityLienPosition.FIRST]: '1',
    [LiabilityLienPosition.SECOND]: '2',
    [LiabilityLienPosition.THIRD]: '3',
    [LiabilityLienPosition.FOURTH]: '4'
};
