import { underwritingIconTypeDisplay } from '@api';
import { Divider, Typography } from '@mui/material';
import { TextField, renderEnumOptions } from '@tsp-ui/core';
import { InfoQuestionCard } from '@views/loans/underwriting/question-cards/InfoQuestionCard';
import { useFormContext } from 'react-hook-form';

import { UnderwritingQuestionFormValues } from '../AddEditQuestionDialog';


export function InfoFormContent() {
    const question = useFormContext<UnderwritingQuestionFormValues>().watch();

    return (
        <>
            <TextField<UnderwritingQuestionFormValues>
                name="icon"
                label="Icon"
                required
                select
            >
                {renderEnumOptions(underwritingIconTypeDisplay)}
            </TextField>

            <Divider />

            {true && (
                <>
                    <Typography variant="h6">
                        Content Preview
                    </Typography>

                    <InfoQuestionCard
                        questionConfig={{
                            ...question,
                            id: question.id || 'new-info-question' // UnderwritingCardWrapper is dependent on the id being present even if this is just a preview
                        }}
                        questionResponse={{ // response not needed here to render the preview
                            id: '',
                            questionId: question.id
                        }}
                    />
                </>
            )}


        </>
    );
}
