import { UnderwritingFieldConfig, singleSelectVariantDisplay } from '@api';
import { AddCircleOutlined } from '@mui/icons-material';
import { Divider, Typography } from '@mui/material';
import { IconButton, TextField, renderEnumOptions } from '@tsp-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { UnderwritingQuestionFormValues } from '../AddEditQuestionDialog';

import { NextActionChoiceCard } from './NextActionChoiceCard';
import styles from './SingleSelectFormContent.module.scss';


export function SingleSelectFormContent() {
    const formMethods = useFormContext<UnderwritingQuestionFormValues>();
    const choices = useFieldArray<UnderwritingQuestionFormValues>({
        name: 'choices',
        control: formMethods.control
    });

    return (
        <>
            <TextField<UnderwritingQuestionFormValues>
                name="variant"
                label="Variant"
                select
            >
                {renderEnumOptions(singleSelectVariantDisplay)}
            </TextField>

            <Divider />

            <div className={styles.root}>
                <div className={styles.choicesHeader}>
                    <Typography variant="h6">
                        Answers
                    </Typography>

                    <IconButton
                        tooltip="Add choice"
                        onClick={() => choices.append({
                            action: '',
                            text: '',
                            confirmationMessage: '',
                            type: '',
                            size: '',
                            label: '',
                            dataSourceId: '',
                            isDisabled: false,
                            id: ''
                        } as unknown as UnderwritingFieldConfig)}
                        size="small"
                    >
                        <AddCircleOutlined color="primary" />
                    </IconButton>
                </div>

                {choices.fields.map((choice, index) => (
                    <NextActionChoiceCard
                        choices={choices}
                        index={index}
                        key={choice.id}
                    />
                ))}
            </div>
        </>
    );
}
