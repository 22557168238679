import api, {
    AmortizationType,
    AutomatedUwRecommendation,
    AutomatedUwSystem,
    Borrower,
    CommitmentType,
    DocumentationType,
    LoanDetail,
    LoanDocument,
    LoanPurpose,
    LoanType,
    LockPeriod,
    NumUnits,
    OccupancyType,
    PropertyType,
    SpecialtyProgram,
    State,
    amortizationTypeDisplay,
    automatedUwRecommendationDisplay,
    automatedUwSystemDisplay,
    commitmentTypeDisplay,
    documentationTypeDisplay,
    loanPurposeDisplay,
    loanTypeDisplay,
    numUnitsDisplay,
    occupancyTypeDisplay,
    propertyTypeDisplay,
    specialtyProgramDisplay
} from '@api';
import {
    AttachMoney,
    ExpandMore,
    HourglassBottom,
    HowToReg,
    LocalAtm,
    Place,
    TrendingUp
} from '@mui/icons-material';
import {
    Button, DialogContent, Divider, List, Paper, PaperProps, Skeleton, Tooltip, Typography
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import {
    AddressTypography, DialogActions, DiffIcon, DiffLabeledValue, DiffLabeledValueProps,
    DiffValue, EmailLink, ExpandableHeader, FilterTextField, IconButton, LabelGroup,
    RoutedDialog, RoutedDialogImplProps
} from '@tsp-ui/core/components';
import {
    formatCurrency, getFullName, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils';
import { IconMenuItem } from '@views/admin/data-requests/components/DataRequestsMenuListItem';
import { renderBoolean } from '@views/admin/products/components/ProductDetailsCard';
import { DocumentLink } from '@views/components/DocumentLink';
import clsx from 'clsx';
import deepEqual from 'fast-deep-equal';
import React, {
    Fragment, useCallback, useContext, useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { LoanDetailContext, LoanStatusButton } from '../LoanDetailPage';
import { PendingLoanDetailContext } from '../PendingLoanDetailPage';

import styles from './LoanDataDialog.module.scss';


type DiffLabeledValuePropsBase = Omit<DiffLabeledValueProps<LoanDetail, keyof LoanDetail>, 'property' | 'fieldSet' | 'renderValue'>;

interface LocationState {
    isPendingLoan: boolean;
}

export default function LoanDataDialog(props: RoutedDialogImplProps) {
    const { loanDetail: loanDetailFromContext } = useContext(LoanDetailContext || PendingLoanDetailContext);
    const { id: clientID, customerId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();
    const location = useLocation();
    const { isPendingLoan } = (location.state as LocationState) || { isPendingLoan: false };
    const { loanID, documentId } = useParams<{ loanID: string, documentId: string }>();

    const [ loading, setLoading ] = useState(!loanDetailFromContext);
    const [ documents, setDocuments ] = useState<LoanDocument[]>();
    const [ loanDetail, setLoanDetail ] = useState<LoanDetail | undefined>(loanDetailFromContext);
    const [ mockOcrData, setMockOcrData ] = useState<Partial<LoanDetail>>({});
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);
    const isDocumentReview = !!documentId;

    useAsyncEffect(useCallback(async () => {
        if (!loanDetail) {
            try {
                setLoanDetail(!isPendingLoan
                    ? await api.loans.getLoanDetail(clientID, loanID, customerId)
                    : await api.loans.getProspectiveLoanDetail(clientID, loanID, customerId));

                if (isDocumentReview) {
                    const availableDocs = await api.documentType.getAvailableDocTypes(clientID);
                    const requiredDocCodes = await api.documentType.getRequiredDocCodes(clientID);
                    const loanDocuments = await api.loans.document.getLoanDocuments(clientID, loanID, customerId);

                    setDocuments(availableDocs
                        .filter(doc => requiredDocCodes.includes(doc.code))
                        .map(doc => loanDocuments.find(uploadedDoc => uploadedDoc.name === doc.name)!)
                        .filter(Boolean));

                    // If viewing a specific document, get its mock OCR data
                    if (documentId) {
                        setMockOcrData(getMockOcrData(documentId, loanDocuments));
                    }
                }
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching loan details', error);
            } finally {
                setLoading(false);
            }
        }
    }, [
        loanDetail, isPendingLoan, clientID, loanID, customerId, isDocumentReview, documentId, pageMessage
    ]));

    const diffProps: DiffLabeledValuePropsBase = {
        original: loanDetail,
        updated: !documentId ? loanDetail : {
            ...loanDetail!,
            ...mockOcrData
        },
        ignoreDelete: true,
        variants: {
            value: 'body1' as Variant,
            label: 'body2' as Variant
        },
        hideIfUnchanged: false,
        label: '',
        classNames: { value: styles.diffRoot }
    };

    /**
     * TODO post-demo idea for improvement: use context and some components to
     * handle some of this for us. Create SearchableFieldGroup and SearchableField
     * components where the group knows the fields that are below it in the tree
     * so we don't need to keep logic like this:
     * `(include([ 'borrowers' ])) && render fields` in sync.
     */
    const filterLoanFields = useCallback((loan: LoanDetail) => {
        if (!loan) {
            return [];
        }

        return (Object.keys(loan) as (keyof LoanDetail)[])
            .filter(
                key => String(loan[key]).toLowerCase().includes(debouncedTerm.toLowerCase())
                    || (loanFieldLabels[key] || '').toLowerCase().includes(debouncedTerm.toLowerCase())
            );
    }, [ debouncedTerm ]);

    const { original, updated } = diffProps;

    const filterLoanFieldsForDocuments = useCallback((loan: LoanDetail) => {
        if (!loan) {
            return [];
        }

        return (Object.keys(loan) as (keyof LoanDetail)[]).filter(key => (
            !deepEqual(original?.[key], updated?.[key])
        ));
    }, [ original, updated ]);

    const filteredFields = loanDetail
        ? documentId
            ? filterLoanFieldsForDocuments(loanDetail)
            : filterLoanFields(loanDetail)
        : [];

    function includeField(field: keyof LoanDetail) {
        return documentId
            ? filteredFields.includes(field)
            : filteredFields.includes(field);
    }

    const document = documents?.find(({ id }) => id === documentId);
    const { name: documentName, loanId } = document || {};

    const [ documentUrl, setDocumentUrl ] = useState('');

    useAsyncEffect(useCallback(async () => {
        if (!loanId) {
            return;
        }

        setDocumentUrl(
            (await api.loans.document.getLoanDocumentUrl(clientID, loanId, documentId, customerId)).preSignedUrl
        );
    }, [
        clientID, loanId, documentId, customerId
    ]));

    return (
        <RoutedDialog
            {...props}
            title={!isDocumentReview ? 'Loan Data' : (
                <div className={styles.title}>
                    Review Document Data -&nbsp;

                    {(loading || !document) ? (
                        <Skeleton>
                            <Typography variant="h6">
                                {documentName}
                            </Typography>
                        </Skeleton>
                    ) : document && (
                        <DocumentLink
                            variant="h6"
                            document={document}
                            name={documentName || ''}
                        />
                    )}
                </div>
            )}
            maxWidth={false}
            loading={loading}
            classes={{
                paper: clsx(styles.dialogPaper, {
                    [styles.isDocument]: isDocumentReview
                })
            }}
        >
            {(isDocumentReview ? (
                <>
                    <DialogContent className={styles.documentDialogContent}>
                        <Paper
                            className={styles.documentContentContainer}
                            elevation={0}
                        >
                            <List disablePadding>
                                {documents?.map((document) => (
                                    <IconMenuItem
                                        key={document.id}
                                        title={document.name}
                                        to={`/accounts/${clientID}/loans/${loanID}/loan-data/document/${document.id}`}
                                        selected={document.id === documentId}
                                        classes={{
                                            root: styles.menuItem,
                                            selected: styles.selected
                                        }}
                                        icon={(
                                            <Tooltip title="Needs Review">
                                                <HourglassBottom
                                                    fontSize="small"
                                                    color="primary"
                                                />
                                            </Tooltip>
                                        )}
                                    />
                                ))}
                            </List>

                            <Paper
                                elevation={0}
                                className={styles.documentDataContainer}
                            >
                                <Paper
                                    elevation={0}
                                    className={styles.documentDataPaper}
                                >
                                    <ExpandableHeader
                                        disableExpand={false}
                                        title="Data Differences"
                                        secondaryText=""
                                        expandedContent={(
                                            <div
                                                className={clsx(
                                                    styles.expandedContent, styles.dataDiffExpandedContent
                                                )}
                                            >
                                                <LoanInfo
                                                    includeField={includeField}
                                                    diffProps={diffProps}
                                                    usePaperWrapper
                                                />

                                                <BorrowerInfo
                                                    includeField={includeField}
                                                    diffProps={diffProps}
                                                    usePaperWrapper
                                                />

                                                <PropertyInfo
                                                    includeField={includeField}
                                                    diffProps={diffProps}
                                                    usePaperWrapper
                                                />

                                                <AdditionalData
                                                    includeField={includeField}
                                                    diffProps={diffProps}
                                                    usePaperWrapper
                                                />
                                            </div>
                                        )}
                                    />

                                    <ExpandableHeader
                                        disableExpand={false}
                                        title="Document Preview"
                                        secondaryText=""
                                        expandedContent={(
                                            <div className={styles.expandedContent}>
                                                <iframe
                                                    title={document?.name}
                                                    className={styles.iframe}
                                                    src={documentUrl}
                                                />
                                            </div>
                                        )}
                                    />
                                </Paper>
                            </Paper>
                        </Paper>
                    </DialogContent>

                    <DialogActions>
                        <Button>
                            Reject
                        </Button>

                        <Button variant="contained">
                            Approve
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogContent className={styles.headerContent}>
                        <Typography>
                            Loan #

                            <Typography
                                component="span"
                                fontWeight={500}
                            >
                                {loanDetail?.loanNumber || loanDetail?.customerLoanNumber}
                            </Typography>
                        </Typography>

                        <FilterTextField
                            placeholder="Find a loan field"
                            helperText="Search by field label or value"
                            onChange={(event) => setSearchTerm(event.target.value.toLocaleLowerCase())}
                        />

                        {!isPendingLoan && loanDetail && (
                            <LoanStatusButton
                                status={loanDetail.loanStatus}
                                loanID={loanDetail.id}
                            />
                        )}
                    </DialogContent>

                    <DialogContent className={styles.content}>
                        <div className={styles.column}>
                            <LoanInfo
                                includeField={includeField}
                                diffProps={diffProps}
                            />

                            <BorrowerInfo
                                includeField={includeField}
                                diffProps={diffProps}
                            />
                        </div>

                        <div className={styles.column}>
                            <PropertyInfo
                                includeField={includeField}
                                diffProps={diffProps}
                            />

                            <AdditionalData
                                includeField={includeField}
                                diffProps={diffProps}
                            />
                        </div>
                    </DialogContent>
                </>
            ))}
        </RoutedDialog>
    );
}

interface SectionProps {
    includeField: (field: keyof LoanDetail) => boolean;
    diffProps: DiffLabeledValuePropsBase;
    usePaperWrapper?: boolean;
}

function LoanInfo({
    includeField, diffProps, usePaperWrapper
}: SectionProps) {
    const [ showArmDetails, setShowArmDetails ] = useState(false);

    function shouldShowExpandButton() {
        const currentAmortType = diffProps.updated?.amortizationType ?? diffProps.original?.amortizationType;
        return currentAmortType === 'ADJUSTABLE';
    }

    const Wrapper = usePaperWrapper ? Paper : 'div';
    const wrapperProps = !usePaperWrapper ? {} : {
        variant: 'outlined',
        className: styles.paper
    } as PaperProps;

    const shouldRenderSection = includeField('escrowsFlag') || includeField('interestOnlyFlag') || includeField('loanAmount')
        || includeField('loanTerm') || includeField('interestRate') || includeField('lockPeriod')
        || includeField('amortizationType') || includeField('armMargin') || includeField('armInitialCap')
        || includeField('armSubsequentCap') || includeField('armLifeCap');

    return !shouldRenderSection ? null : (
        <Wrapper {...wrapperProps}>
            <Typography className={styles.header}>
                Loan Information
            </Typography>

            <div className={styles.section}>
                <div className={styles.infoHeader}>
                    {includeField('loanType') && diffProps.original && diffProps.updated && (
                        <div className={styles.diffValue}>
                            <DiffValue
                                originalValue={diffProps.original.loanType}
                                updatedValue={diffProps.updated.loanType}
                                renderValue={(type: LoanType) => (
                                    <Tooltip title={loanFieldLabels['loanType']}>
                                        <Typography>{loanTypeDisplay[type]}</Typography>
                                    </Tooltip>
                                )}
                            />
                        </div>
                    )}

                    <div className={styles.icons}>
                        {includeField('escrowsFlag') && diffProps.original && diffProps.updated && (
                            <DiffIcon
                                originalValue={diffProps.original.escrowsFlag}
                                updatedValue={diffProps.updated.escrowsFlag}
                                renderIcon={(value) => (
                                    <Tooltip title={`Taxes & insurance ${value ? '' : 'not'} escrowed`}>
                                        <LocalAtm
                                            color={value ? 'primary' : 'disabled'}
                                            fontSize="small"
                                        />
                                    </Tooltip>
                                )}
                            />
                        )}

                        {includeField('interestOnlyFlag') && diffProps.original && diffProps.updated && (
                            <DiffIcon
                                originalValue={diffProps.original.interestOnlyFlag}
                                updatedValue={diffProps.updated.interestOnlyFlag}
                                renderIcon={value => (
                                    <Tooltip title={`${value ? '' : 'Not'} Interest Only`}>
                                        <TrendingUp
                                            color={value ? 'primary' : 'disabled'}
                                            fontSize="small"
                                        />
                                    </Tooltip>
                                )}
                            />
                        )}
                    </div>
                </div>

                {includeField('loanAmount') && diffProps.original && diffProps.updated && (
                    <div className={styles.diffValue}>
                        {diffProps.original.loanAmount !== diffProps.updated.loanAmount && (
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Loan Amount:
                            </Typography>
                        )}

                        <DiffValue
                            originalValue={diffProps.original.loanAmount}
                            updatedValue={diffProps.updated.loanAmount}
                            renderValue={amount => (
                                <Tooltip title={loanFieldLabels['loanAmount']}>
                                    <Typography>{formatCurrency(amount)}</Typography>
                                </Tooltip>
                            )}
                        />
                    </div>
                )}

                {includeField('loanTerm') && diffProps.original && diffProps.updated && (
                    <div className={styles.diffValue}>
                        <DiffValue
                            originalValue={diffProps.original.loanTerm}
                            updatedValue={diffProps.updated.loanTerm}
                            renderValue={term => (
                                <Tooltip title={loanFieldLabels['loanTerm']}>
                                    <Typography align="right">
                                        {term ? `${term} months` : 'Term not specified'}
                                    </Typography>
                                </Tooltip>
                            )}
                        />
                    </div>
                )}

                {includeField('interestRate') && diffProps.original && diffProps.updated && (
                    <div className={styles.diffValue}>
                        {diffProps.original.interestRate !== diffProps.updated.interestRate && (
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Interest Rate:
                            </Typography>
                        )}

                        <DiffValue
                            originalValue={diffProps.original.interestRate}
                            updatedValue={diffProps.updated.interestRate}
                            renderValue={rate => (
                                <Tooltip title={loanFieldLabels['interestRate']}>
                                    <Typography>{rate ? `${rate.toFixed(3)}%` : '--'}</Typography>
                                </Tooltip>
                            )}
                        />
                    </div>
                )}

                {includeField('lockPeriod') && diffProps.original && diffProps.updated && (
                    <div className={styles.diffValue}>
                        <DiffValue
                            originalValue={diffProps.original.lockPeriod}
                            updatedValue={diffProps.updated.lockPeriod}
                            renderValue={period => (
                                <Tooltip title={loanFieldLabels['lockPeriod']}>
                                    <Typography align="right">
                                        {period ? `${period} days` : 'Lock period not specified'}
                                    </Typography>
                                </Tooltip>
                            )}
                        />
                    </div>
                )}

                {includeField('amortizationType') && diffProps.original && diffProps.updated && (
                    <div className={styles.diffValue}>
                        <DiffValue
                            originalValue={diffProps.original.amortizationType}
                            updatedValue={diffProps.updated.amortizationType}
                            renderValue={(type) => (
                                <Tooltip title={loanFieldLabels['amortizationType']}>
                                    <Typography>{type ? amortizationTypeDisplay[type] : '--'}</Typography>
                                </Tooltip>
                            )}
                        />
                    </div>
                )}

                {shouldShowExpandButton() && (includeField('armMargin') || includeField('armInitialCap') || includeField('armSubsequentCap') || includeField('armLifeCap')) && (
                    <Tooltip title="Show amortization details">
                        <IconButton
                            size="small"
                            className={styles.showArmButton}
                            onClick={() => setShowArmDetails(!showArmDetails)}
                        >
                            <ExpandMore
                                color="secondary"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                )}

                {showArmDetails && (
                    <LabelGroup className={styles.armDetails}>
                        {includeField('armMargin') && (
                            <DiffLabeledValue
                                {...diffProps}
                                property="armMargin"
                                label={`${loanFieldLabels['armMargin']}:`}
                                renderValue={margin => (margin ? `${margin}%` : '--')}
                            />
                        )}

                        {includeField('armInitialCap') && (
                            <DiffLabeledValue
                                {...diffProps}
                                property="armInitialCap"
                                label={`${loanFieldLabels['armInitialCap']}:`}
                                renderValue={cap => (cap ? `${cap}%` : '--')}
                            />
                        )}

                        {includeField('armSubsequentCap') && (
                            <DiffLabeledValue
                                {...diffProps}
                                property="armSubsequentCap"
                                label={`${loanFieldLabels['armSubsequentCap']}:`}
                                renderValue={cap => (cap ? `${cap}%` : '--')}
                            />
                        )}

                        {includeField('armLifeCap') && (
                            <DiffLabeledValue
                                {...diffProps}
                                property="armLifeCap"
                                label={`${loanFieldLabels['armLifeCap']}:`}
                                renderValue={cap => (cap ? `${cap}%` : '--')}
                            />
                        )}
                    </LabelGroup>
                )}
            </div>
        </Wrapper>
    );
}

function BorrowerInfo({ includeField, diffProps, usePaperWrapper }: SectionProps) {
    const Wrapper = usePaperWrapper ? Paper : 'div';
    const wrapperProps = !usePaperWrapper ? {} : {
        variant: 'outlined',
        className: styles.paper
    } as PaperProps;

    const borrowers = diffProps.updated?.borrowers
        || diffProps.original?.borrowers;

    return !includeField('borrowers') ? null : (
        <Wrapper {...wrapperProps}>
            <Typography className={styles.header}>
                Borrowers
            </Typography>

            <div className={styles.borrowers}>
                {borrowers?.map((borrower: Borrower, index: number) => (
                    <Fragment key={borrower.id}>
                        <BorrowerCard
                            borrower={borrower}
                            diffProps={diffProps}
                        />

                        {index < (
                            diffProps.updated?.borrowers || diffProps.original?.borrowers || []
                        ).length - 1 && (
                            <Divider className={styles.divider} />
                        )}
                    </Fragment>
                ))}
            </div>
        </Wrapper>
    );
}

interface BorrowerProps {
    borrower: Borrower;
    diffProps: DiffLabeledValuePropsBase;
}

function BorrowerCard({ borrower, diffProps }: BorrowerProps) {
    const [ hideSSN, setHideSSN ] = useState(true);
    const [ hideContactInfo, setHideContactInfo ] = useState(true);

    const borrowerDiffProps = {
        ...diffProps,
        original: diffProps.original?.borrowers.find((b) => b.id === borrower.id),
        updated: diffProps.updated?.borrowers.find((b) => b.id === borrower.id)
    };

    function formatSSN(borrower: Borrower) {
        if (!borrower.ssn) {
            return 'N/A';
        }
        return hideSSN ? `*-${borrower.ssn.slice(-4)}` : borrower.ssn;
    }

    function toggleSSNVisibility() {
        setHideSSN(!hideSSN);
    }

    function toggleContactVisibility() {
        setHideContactInfo(!hideContactInfo);
    }

    return (
        <div>
            <div className={styles.borrowerCardHeader}>
                {borrowerDiffProps.original && borrowerDiffProps.updated && (
                    <DiffLabeledValue<Borrower, 'firstName' | 'lastName'>
                        {...borrowerDiffProps}
                        property={[ 'firstName', 'lastName' ]}
                        label=""
                        fieldSet
                        renderValue={({ firstName, lastName }) => (
                            <Tooltip title={hideContactInfo ? 'Show Contact Info' : 'Hide Contact Info'}>
                                <Typography
                                    className={styles.borrowerName}
                                    onClick={toggleContactVisibility}
                                >
                                    {getFullName({
                                        firstName,
                                        lastName
                                    })}
                                </Typography>
                            </Tooltip>
                        )}
                    />
                )}

                <div className={styles.icons}>
                    {borrowerDiffProps.original && borrowerDiffProps.updated && (
                        <DiffIcon
                            originalValue={borrowerDiffProps.original.primaryWageEarner}
                            updatedValue={borrowerDiffProps.updated.primaryWageEarner}
                            renderIcon={value => (
                                <Tooltip
                                    title={`${value ? 'P' : 'Not the p'}rimary wage earner`}
                                    enterDelay={0}
                                >
                                    <AttachMoney
                                        color={value ? 'primary' : 'disabled'}
                                        fontSize="small"
                                    />
                                </Tooltip>
                            )}
                        />
                    )}

                    {diffProps.original && diffProps.updated && (
                        <DiffIcon
                            originalValue={diffProps.original.firstTimeHomeBuyer}
                            updatedValue={diffProps.updated.firstTimeHomeBuyer}
                            renderIcon={value => (
                                <Tooltip
                                    title={`${value ? 'F' : 'Not a f'}irst time home buyer`}
                                    enterDelay={0}
                                >
                                    <HowToReg
                                        color={value ? 'primary' : 'disabled'}
                                        fontSize="small"
                                    />
                                </Tooltip>
                            )}
                        />
                    )}
                </div>
            </div>

            {!hideContactInfo && (
                <DiffLabeledValue
                    {...borrowerDiffProps}
                    label=""
                    property="email"
                    renderValue={(email) => <EmailLink email={email || ''} />}
                    className={styles.email}
                />
            )}

            <div className={styles.borrowerDetails}>
                <LabelGroup>
                    <DiffLabeledValue
                        {...borrowerDiffProps}
                        property="fico"
                        label="FICO:"
                    />
                </LabelGroup>

                <LabelGroup>
                    <DiffLabeledValue
                        {...borrowerDiffProps}
                        property="ssn"
                        label="SSN:"
                        renderValue={() => (
                            <Tooltip title={hideSSN ? 'Reveal' : 'Hide'}>
                                <Typography
                                    onClick={toggleSSNVisibility}
                                    variant="body2"
                                    className={styles.ssn}
                                >
                                    {formatSSN(borrower)}
                                </Typography>
                            </Tooltip>
                        )}
                    />
                </LabelGroup>
            </div>
        </div>
    );
}

function PropertyInfo({ includeField, diffProps, usePaperWrapper }: SectionProps) {
    const Wrapper = usePaperWrapper ? Paper : 'div';
    const wrapperProps = !usePaperWrapper ? {} : {
        variant: 'outlined',
        className: styles.paper
    } as PaperProps;

    const renderSection = includeField('salePrice') || includeField('appraisedValue') || includeField('address')
        || includeField('occupancy') || includeField('purpose') || includeField('units')
        || includeField('propertyType');

    return !renderSection ? null : (
        <Wrapper {...wrapperProps}>
            <Typography className={styles.header}>Property Details</Typography>

            <div className={styles.section}>
                {includeField('propertyType') && diffProps.original && diffProps.updated && (
                    <div className={styles.diffValue}>
                        <DiffValue
                            originalValue={diffProps.original.propertyType}
                            updatedValue={diffProps.updated.propertyType}
                            renderValue={(type) => (
                                <Tooltip title={loanFieldLabels['propertyType']}>
                                    <Typography>
                                        {type ? propertyTypeDisplay[type] : 'Property type not specified'}
                                    </Typography>
                                </Tooltip>
                            )}
                        />
                    </div>
                )}

                {includeField('units') && diffProps.original && diffProps.updated && (
                    <div className={styles.diffValue}>
                        <DiffValue
                            originalValue={diffProps.original.units}
                            updatedValue={diffProps.updated.units}
                            renderValue={(units) => (
                                <Tooltip title={loanFieldLabels['units']}>
                                    <Typography align="right">
                                        {units ? `${numUnitsDisplay[units]} unit${units !== NumUnits.ONE ? 's' : ''}` : 'Number of units not specified'}
                                    </Typography>
                                </Tooltip>
                            )}
                        />
                    </div>
                )}

                {includeField('purpose') && diffProps.original && diffProps.updated && (
                    <div className={styles.diffValue}>
                        <DiffValue
                            originalValue={diffProps.original.purpose}
                            updatedValue={diffProps.updated.purpose}
                            renderValue={(purpose) => (
                                <Tooltip title={loanFieldLabels['purpose']}>
                                    <Typography>
                                        {purpose ? loanPurposeDisplay[purpose] : 'Loan purpose not specified'}
                                    </Typography>
                                </Tooltip>
                            )}
                        />
                    </div>
                )}

                {includeField('occupancy') && diffProps.original && diffProps.updated && (
                    <div className={styles.diffValue}>
                        <DiffValue
                            originalValue={diffProps.original.occupancy}
                            updatedValue={diffProps.updated.occupancy}
                            renderValue={(type) => (
                                <Tooltip title={loanFieldLabels['occupancy']}>
                                    <Typography align="right">
                                        {type ? occupancyTypeDisplay[type] : 'Occupancy type not specified'}
                                    </Typography>
                                </Tooltip>
                            )}
                        />
                    </div>
                )}

                {includeField('address') && diffProps.original && diffProps.updated && (
                    <div className={styles.addressWrapper}>
                        <Divider className={styles.divider} />

                        <Tooltip title={loanFieldLabels['address']}>
                            <DiffValue
                                originalValue={diffProps.original.address}
                                updatedValue={diffProps.updated.address}
                                renderValue={address => (
                                    <div className={styles.propertyAddress}>
                                        <Place color="primary" />

                                        <AddressTypography address={address} />
                                    </div>
                                )}
                            />
                        </Tooltip>

                        <Divider className={styles.divider} />
                    </div>
                )}

                <LabelGroup className={styles.justifyStart}>
                    {includeField('appraisedValue') && (
                        <DiffLabeledValue
                            {...diffProps}
                            property="appraisedValue"
                            label="Appraised value:"
                            renderValue={(value) => formatCurrency(value) || '--'}
                        />
                    )}
                </LabelGroup>

                <LabelGroup className={styles.justifyEnd}>
                    {includeField('salePrice') && (
                        <DiffLabeledValue
                            {...diffProps}
                            property="salePrice"
                            label="Sale price:"
                            renderValue={(price) => formatCurrency(price) || '--'}
                        />
                    )}
                </LabelGroup>
            </div>
        </Wrapper>
    );
}

function AdditionalData({
    includeField, diffProps, usePaperWrapper
}: SectionProps) {
    function getEnumDisplay<T extends string | number>(
        value: unknown,
        enumObject: { [key: string]: T },
        displayObject: { [key in T]: string }
    ): string {
        return (typeof value === 'string' && Object.values(enumObject).includes(value as T))
            ? displayObject[value as T]
            : '--';
    }

    function getRenderValueForField(key: keyof LoanDetail) {
        return (value: unknown) => {
            if (typeof value === 'boolean') {
                return renderBoolean(value);
            }
            if (typeof value === 'number' && [
                'cashOutAmount', 'subordinatedBalance', 'mortgageInsCoverage', 'subordinatedLoanAmount', 'totalLoanAmount'
            ].includes(key)) {
                return formatCurrency(value);
            }
            switch (key) {
            case 'specialtyProgram':
                return getEnumDisplay(value, SpecialtyProgram, specialtyProgramDisplay);
            case 'documentationType':
                return getEnumDisplay(value, DocumentationType, documentationTypeDisplay);
            case 'commitmentType':
                return getEnumDisplay(value, CommitmentType, commitmentTypeDisplay);
            case 'automatedUwSystem':
                return getEnumDisplay(value, AutomatedUwSystem, automatedUwSystemDisplay);
            case 'automatedUwRecommendation':
                return getEnumDisplay(value, AutomatedUwRecommendation, automatedUwRecommendationDisplay);
            default:
                return value?.toString() || '--';
            }
        };
    }

    const additionalFields: (keyof LoanDetail)[] = [
        'customerName', 'customerId', 'customerLoanNumber', 'productCode', 'specialtyProgram',
        'documentationType', 'subordinatedBalance', 'cashOutAmount', 'limitedLiabilityCorp',
        'commitmentType', 'commitmentIdentifier', 'mortgageInsFlag', 'mortgageInsCompany',
        'mortgageInsCoverage', 'underwriteFlag', 'automatedUwSystem', 'automatedUwRecommendation'
    ];

    const includedAdditionalFields = additionalFields.filter((field) => includeField(field));

    const Wrapper = usePaperWrapper ? Paper : 'div';
    const wrapperProps = !usePaperWrapper ? {} : {
        variant: 'outlined',
        className: styles.paper
    } as PaperProps;

    return !includedAdditionalFields?.length ? null : (
        <Wrapper {...wrapperProps}>
            <Typography className={styles.header}>Additional Data</Typography>

            <div className={styles.section}>
                <LabelGroup className={styles.justifyStart}>
                    {includedAdditionalFields.map((field) => (
                        <DiffLabeledValue
                            key={field}
                            {...diffProps}
                            property={field}
                            label={`${loanFieldLabels[field]}:`}
                            renderValue={getRenderValueForField(field)}
                            classNames={{ value: styles.diffRoot }}
                            variants={{
                                label: 'body2',
                                value: 'body1'
                            }}
                        />
                    ))}
                </LabelGroup>
            </div>
        </Wrapper>
    );
}

const loanFieldLabels: Partial<Record<keyof LoanDetail, string>> = {
    loanNumber: 'Loan number',
    assignee: 'Assignee',
    loanStatus: 'Loan status',
    loanType: 'Loan type',
    loanAmount: 'Loan amount',
    interestRate: 'Interest rate',
    amortizationType: 'Amortization type',
    armMargin: 'Arm margin',
    armInitialCap: 'Arm initial cap',
    armSubsequentCap: 'Arm subsequent cap',
    armLifeCap: 'Arm life cap',
    escrowsFlag: 'Escrows flag',
    interestOnlyFlag: 'Interest only flag',
    loanTerm: 'Loan term',
    lockPeriod: 'Lock period',
    borrowers: 'Borrowers',
    propertyType: 'Property type',
    units: 'Number of units',
    occupancy: 'Occupancy type',
    purpose: 'Purpose',
    address: 'Property address',
    appraisedValue: 'Appraised value',
    salePrice: 'Sale price',
    customerName: 'Customer name',
    customerId: 'Customer ID',
    customerLoanNumber: 'Customer loan number',
    productCode: 'Product code',
    specialtyProgram: 'Specialty program',
    documentationType: 'Documentation type',
    subordinatedBalance: 'Subordinated balance',
    cashOutAmount: 'Cash out amount',
    limitedLiabilityCorp: 'Limited liability corp',
    commitmentType: 'Commitment type',
    commitmentIdentifier: 'Commitment identifier',
    mortgageInsFlag: 'Mortgage ins flag',
    mortgageInsCompany: 'Mortgage ins company',
    mortgageInsCoverage: 'Mortgage ins coverage',
    underwriteFlag: 'Underwrite flag',
    automatedUwSystem: 'AUS',
    automatedUwRecommendation: 'AUR'
};

function getRandomChange(current: number): number {
    const changePercent = Math.random() * 0.15;
    const direction = Math.random() > 0.5 ? 1 : -1;
    return current * (1 + (direction * changePercent));
}

export function getMockedOCRData(
    loanDetail: LoanDetail
): { original: LoanDetail, updated: LoanDetail } {
    const getRandomDiff = (original: LoanDetail) => {
        const diffs = [
            // Loan info changes
            {
                ...original,
                loanAmount: Math.round(getRandomChange(original.loanAmount || 0)),
                interestRate: Number((getRandomChange(original.interestRate || 0)).toFixed(3)),
                loanTerm: Math.round(getRandomChange(original.loanTerm || 0)),
                lockPeriod: Math.round(getRandomChange(original.lockPeriod || 0)),
                amortizationType: original.amortizationType === AmortizationType.FIXED
                    ? AmortizationType.ADJUSTABLE : AmortizationType.FIXED,
                loanType: original.loanType === LoanType.CONVENTIONAL
                    ? LoanType.FHA : LoanType.CONVENTIONAL,
                escrowsFlag: !original.escrowsFlag,
                interestOnlyFlag: !original.interestOnlyFlag
            },
            // Borrower changes
            {
                ...original,
                borrowers: original.borrowers.map(borrower => ({
                    ...borrower,
                    fico: Math.round(getRandomChange(borrower.fico || 0)),
                    primaryWageEarner: !borrower.primaryWageEarner
                })),
                firstTimeHomeBuyer: !original.firstTimeHomeBuyer
            },
            // Property/Additional changes
            {
                ...original,
                propertyType: original.propertyType === PropertyType.SINGLE_FAMILY_RESIDENCE
                    ? PropertyType.CONDO : PropertyType.SINGLE_FAMILY_RESIDENCE,
                units: original.units === NumUnits.ONE ? NumUnits.TWO : NumUnits.ONE,
                occupancy: original.occupancy === OccupancyType.OWNER_OCCUPIED
                    ? OccupancyType.SECOND_HOME : OccupancyType.OWNER_OCCUPIED,
                documentationType: original.documentationType === DocumentationType.FULL
                    ? DocumentationType.STREAMLINE : DocumentationType.FULL,
                appraisedValue: Math.round(getRandomChange(original.appraisedValue || 0)),
                salePrice: Math.round(getRandomChange(original.salePrice || 0)),
                specialtyProgram: original.specialtyProgram === SpecialtyProgram.HOME_STYLE
                    ? SpecialtyProgram.HOME_ONE : SpecialtyProgram.HOME_STYLE,
                commitmentType: original.commitmentType === CommitmentType.BEST_EFFORT
                    ? CommitmentType.MANDATORY : CommitmentType.BEST_EFFORT,
                automatedUwSystem: original.automatedUwSystem === AutomatedUwSystem.DU
                    ? AutomatedUwSystem.LPA : AutomatedUwSystem.DU,
                automatedUwRecommendation:
                    original.automatedUwRecommendation === AutomatedUwRecommendation.APPROVE_ELIGIBLE
                        ? AutomatedUwRecommendation.CAUTION : AutomatedUwRecommendation.APPROVE_ELIGIBLE,
                mortgageInsFlag: !original.mortgageInsFlag,
                underwriteFlag: !original.underwriteFlag,
                mortgageInsCoverage: Math.round(getRandomChange(original.mortgageInsCoverage || 0)),
                subordinatedBalance: Math.round(getRandomChange(original.subordinatedBalance || 0)),
                cashOutAmount: Math.round(getRandomChange(original.cashOutAmount || 0))
            }
        ];

        return {
            original,
            updated: diffs[Math.floor(Math.random() * diffs.length)]
        };
    };

    return getRandomDiff(loanDetail);
}

function getMockOcrData(docId: string, docs: LoanDocument[]): Partial<LoanDetail> {
    const doc = docs.find(d => d.id === docId);
    if (!doc) {
        return {};
    }

    // Return different mock data based on document name
    switch (doc.name) {
    case 'Lock In Confirmation':
        return {
            commitmentType: CommitmentType.BEST_EFFORT,
            lockPeriod: LockPeriod.THIRTY,
            interestRate: 6.5,
            loanAmount: 166400,
            purpose: LoanPurpose.REFINANCE_CASH_OUT,
            loanTerm: 360,
            escrowsFlag: true,
            documentationType: DocumentationType.STREAMLINE,
            salePrice: 208000,
            appraisedValue: 208000,
            address: {
                street: '456 Any Street',
                state: State.NJ,
                city: 'Cherry Hill',
                county: 'Burlington',
                zip: '08930'
            },
            occupancy: OccupancyType.OWNER_OCCUPIED,
            propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
            borrowers: [
                {
                    firstName: 'William',
                    lastName: 'Clinton',
                    ssn: '333-33-3333',
                    fico: 712
                }
            ]
        };
    case 'Mortgage':
        return {
            loanNumber: '241000879',
            loanAmount: 70000,
            address: {
                street: '888 Willis Ave',
                state: State.IA,
                city: 'Corydon',
                county: 'Wayne',
                zip: '50060'
            },
            propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE
        };
    case 'Note':
        return {
            loanNumber: '241000879',
            interestRate: 7.5,
            loanAmount: 70000,
            loanTerm: 179
        };
    case 'URLA - Borrower Information':
        return {
            appraisedValue: 550000,
            propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE
        };
    case 'Credit Explanation Letter':
        return {
            appraisedValue: 550000,
            propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE
        };
    case 'Credit Score Disclosure Notice to the Home Loan Applicant':
        return {
            appraisedValue: 550000,
            propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE
        };
    default:
        return {};
    }
}
