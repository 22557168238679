import {
    Button, MenuItem, RadioGroup, TextField, Tooltip
} from '@mui/material';
import { Radio, useConfirm } from '@tsp-ui/core';
import { Markdown } from '@views/components/Markdown';
import clsx from 'clsx';
import { useContext, useEffect } from 'react';

import { UnderwritingCategoriesContext } from '../UnderwritingCategories';
import { UnderWritingStepContext } from '../UnderwritingStepSection';
import { UnderwritingCardWrapper, UnderwritingQuestionCardProps } from '../underwriting-common';

import styles from './SingleSelectQuestionCard.module.scss';


export function SingleSelectQuestionCard({ questionConfig, questionResponse }: UnderwritingQuestionCardProps) {
    const confirm = useConfirm();
    const {
        setReadyToSubmit, updateQuestionIdsToDisplayAndSideEffects,
        setQuestionResponse
    } = useContext(UnderWritingStepContext);
    const { underwritingData } = useContext(UnderwritingCategoriesContext);

    const answer = questionResponse?.answer;
    const answeredChoice = questionConfig.choices?.find(
        choice => choice.text === answer
    );

    useEffect(() => {
        if (answeredChoice?.action === 'CONTINUE') {
            setReadyToSubmit(false);
        } else if (answeredChoice?.action === 'END') {
            setReadyToSubmit(true);
        }
    }
    , [ answeredChoice, setReadyToSubmit ]);

    async function multiChoiceOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        const confirmationMessage = questionConfig.choices?.find(
            choice => choice.text === event.target.value
        )?.confirmationMessage;

        if (!confirmationMessage || await confirm(confirmationMessage)) {
            updateQuestionIdsToDisplayAndSideEffects(setQuestionResponse({
                ...questionResponse,
                answer: event.target.value
            }));
        }
    }

    return (
        <UnderwritingCardWrapper
            questionConfig={questionConfig}
            questionResponse={questionResponse}
            className={clsx(styles.singleSelectQuestion, {
                [styles.inlineSingleSelect]: questionConfig.parentQuestionId && questionConfig.variant === 'SIMPLE',
                [styles.dropdown]: questionConfig.variant === 'DROPDOWN'
            })}
        >
            {/* If the variant is dropdown, the question.text is the textbox label */}
            {questionConfig.variant !== 'DROPDOWN' && questionConfig.text && (
                <Markdown valuesToInject={underwritingData}>
                    {questionConfig.text}
                </Markdown>
            )}

            {questionConfig.variant === 'SIMPLE' && (
                <div className={styles.underwritingAnswerButtons}>
                    {(questionConfig.choices?.map(choice => (
                        <Tooltip
                            key={choice.text}
                            title="" // TODO would be nice to add choice.tooltip here
                            enterDelay={0}
                        >
                            <Button
                                key={choice.text}
                                variant={choice.text === answer ? 'contained' : 'text'}
                                disableElevation
                                onClick={async () => {
                                    if (!choice.confirmationMessage || await confirm(choice.confirmationMessage)) {
                                        updateQuestionIdsToDisplayAndSideEffects(setQuestionResponse({
                                            ...questionResponse,
                                            answer: choice.text
                                        }));
                                    }
                                }}
                            >
                                {choice.text}
                            </Button>
                        </Tooltip>
                    )))}
                </div>
            )}

            {questionConfig.variant === 'RADIOBUTTON' && (
                <RadioGroup
                    onChange={multiChoiceOnChange}
                    className={styles.radios}
                    value={answer}
                >
                    {questionConfig.choices?.map(choice => (
                        <Radio
                            key={choice.text}
                            name={questionConfig.id}
                            value={choice.text}
                            label={(
                                <Markdown>
                                    {choice.text}
                                </Markdown>
                            )}
                        />
                    ))}
                </RadioGroup>
            )}

            {questionConfig.variant === 'DROPDOWN' && (
                <TextField
                    select
                    label={questionConfig.text}
                    value={answer}
                    onChange={multiChoiceOnChange}
                >
                    {questionConfig.choices?.map(choice => (
                        <MenuItem
                            key={choice.text}
                            value={choice.text}
                        >
                            {choice.text}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        </UnderwritingCardWrapper>
    );
}
