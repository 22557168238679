import { getRandomEnumValue, randomBoolean, randomNum } from '@tsp-ui/core/utils';
import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';
import { LiabilityBorrower } from '../../enums/liabilityBorrowerEnum';
import { LiabilityExclusionType } from '../../enums/liabilityExclusionType';
import { LiabilityLienPosition } from '../../enums/liabilityLienPosition';
import { LiabilityType } from '../../enums/liabilityTypeEnum';
import { LiabilityUCDPayoffType } from '../../enums/liabilityUCDPayoffTypeEnum';
import { SpecialLiabilityType } from '../../enums/specialLiabilityTypeEnum';

import { SpecialUnderwritingLiability, UnderwritingLiability } from './underwriting-liabilities-api';


let liabilityId = 0;
let specialLiabilityId = 0;

export const creditorNames = [
    'Bank of America', 'Chase', 'Wells Fargo', 'Capital One', 'Discover', 'Citibank'
];

function generateRandomLiability(): UnderwritingLiability {
    const excluded = randomBoolean();
    return {
        id: `${liabilityId++}`,
        creditorName: creditorNames[Math.floor(Math.random() * creditorNames.length)],
        liabilityType: getRandomEnumValue(LiabilityType),
        accountName: 'John Smith',
        accountNumber: `${randomNum(1000000000, 9999999999)}`,
        borrowerStatus: getRandomEnumValue(LiabilityBorrower),
        monthsToExclude: randomNum(0, 24),
        willBePaidOff: randomBoolean(),
        purpose: 'Sample purpose',
        ucdPayoffType: getRandomEnumValue(LiabilityUCDPayoffType),
        excluded,
        exclusionType: excluded ? getRandomEnumValue(LiabilityExclusionType) : undefined,
        resubordinatedIndicator: randomBoolean(),
        subjectProperty: randomBoolean(),
        currentLienPosition: getRandomEnumValue(LiabilityLienPosition),
        proposedLienPosition: getRandomEnumValue(LiabilityLienPosition),
        accountBalance: randomNum(5000, 100000, 2),
        monthsLeft: randomNum(6, 360),
        monthlyPayment: randomNum(200, 2000, 2),
        creditLimit: randomNum(10000, 50000, 2),
        paymentIncludesTaxes: randomBoolean(),
        paceLoan: randomBoolean(),
        energyImprovement: randomBoolean()
    };
}

function generateRandomSpecialLiability(): SpecialUnderwritingLiability {
    return {
        id: `${specialLiabilityId++}`,
        specialLiablityType: getRandomEnumValue(SpecialLiabilityType),
        borrowerStatus: getRandomEnumValue(LiabilityBorrower),
        monthlyPayment: randomNum(200, 2000, 2),
        accountBalance: randomNum(5000, 100000, 2),
        monthsLeft: randomNum(6, 360),
        creditLimit: randomNum(10000, 50000, 2)
    };
}

const mockLiabilities: UnderwritingLiability[] = Array(6).fill(null).map(() => generateRandomLiability());
const mockSpecialLiabilities: SpecialUnderwritingLiability[] = Array(1).fill(null)
    .map(() => generateRandomSpecialLiability());

export const mocks = [
    rest.get(getMockUrl('/client/:clientId/loan/:loanId/liability'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(mockLiabilities)
    )),
    rest.post(getMockUrl('/client/:clientId/loan/:loanId/liability'), (req, res, ctx) => {
        const newLiability: UnderwritingLiability = {
            ...req.body as Omit<UnderwritingLiability, 'id'>,
            id: `${liabilityId++}`
        };
        mockLiabilities.push(newLiability);
        return res(
            ctx.status(201),
            ctx.delay(300),
            ctx.json(newLiability)
        );
    }),
    rest.put(getMockUrl('/client/:clientId/loan/:loanId/liability/:liabilityId'), (req, res, ctx) => {
        const updatedLiability = req.body as UnderwritingLiability;
        const index = mockLiabilities.findIndex(l => l.id === updatedLiability.id);
        if (index !== -1) {
            mockLiabilities[index] = updatedLiability;
            return res(
                ctx.status(200),
                ctx.delay(300),
                ctx.json(updatedLiability)
            );
        }
        return res(
            ctx.status(404),
            ctx.json({ message: 'Liability not found' })
        );
    }),
    rest.delete(getMockUrl('/client/:clientId/loan/:loanId/liability/:underwritingLiabilityId'), (req, res, ctx) => {
        const { underwritingLiabilityId } = req.params;
        const index = mockLiabilities.findIndex(l => l.id === underwritingLiabilityId);
        if (index !== -1) {
            mockLiabilities.splice(index, 1);
            return res(
                ctx.status(204),
                ctx.delay(300)
            );
        }
        return res(
            ctx.status(404),
            ctx.json({ message: 'Liability not found' })
        );
    }),
    rest.get(getMockUrl('/client/:clientId/loan/:loanId/special-liability'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(mockSpecialLiabilities)
    )),
    rest.post(getMockUrl('/client/:clientId/loan/:loanId/special-liability'), (req, res, ctx) => {
        const newSpecialLiability: SpecialUnderwritingLiability = {
            ...req.body as Omit<SpecialUnderwritingLiability, 'id'>,
            id: `${specialLiabilityId++}`
        };
        mockSpecialLiabilities.push(newSpecialLiability);
        return res(
            ctx.status(201),
            ctx.delay(300),
            ctx.json(newSpecialLiability)
        );
    }),
    rest.put(getMockUrl('/client/:clientId/loan/:loanId/special-liability/:specialLiabilityId'), (req, res, ctx) => {
        const updatedSpecialLiability = req.body as SpecialUnderwritingLiability;
        const index = mockSpecialLiabilities.findIndex(l => l.id === updatedSpecialLiability.id);
        if (index !== -1) {
            mockSpecialLiabilities[index] = updatedSpecialLiability;
            return res(
                ctx.status(200),
                ctx.delay(300),
                ctx.json(updatedSpecialLiability)
            );
        }
        return res(
            ctx.status(404),
            ctx.json({ message: 'Special liability not found' })
        );
    }),
    rest.delete(getMockUrl('/client/:clientId/loan/:loanId/special-liability/:specialLiabilityId'), (req, res, ctx) => {
        const { specialLiabilityId } = req.params;
        const index = mockSpecialLiabilities.findIndex(l => l.id === specialLiabilityId);
        if (index !== -1) {
            mockSpecialLiabilities.splice(index, 1);
            return res(
                ctx.status(204),
                ctx.delay(300)
            );
        }
        return res(
            ctx.status(404),
            ctx.json({ message: 'Special liability not found' })
        );
    })
];
