import { CustomQuestionType } from '@api';
import { Markdown } from '@views/components/Markdown';
import { ReactNode, useContext, useEffect } from 'react';

import { UnderwritingCategoriesContext } from '../../UnderwritingCategories';
import { UnderWritingStepContext } from '../../UnderwritingStepSection';
import { UnderwritingQuestionCardProps } from '../../underwriting-common';

import { AusTable } from './components/AUSTable';
import AssetAccountsSection from './components/AssetAccountsSection';
import LiabilitiesSection from './components/LiabilitiesSection';


export function CustomQuestionCard({ questionConfig }: UnderwritingQuestionCardProps) {
    const {
        setReadyToSubmit
    } = useContext(UnderWritingStepContext);
    const { underwritingData } = useContext(UnderwritingCategoriesContext);

    useEffect(() => { // this is for the default rendering of the question, not when a user clicks next
        if (questionConfig.next?.action === 'END') {
            setReadyToSubmit(true);
        }
    }, [ questionConfig, setReadyToSubmit ]);

    // useEffect(() => {
    //     setSubmitResponseCallback(() => () => updateUnderwritingQuestionResponse({
    //         id: '',
    //         questionId: questionConfig.id,
    //         answer: 'true' // used just to acknowledge that the user has seen the question
    //     }));
    //     // setNextButtonText(questionConfig.next?.text || 'Submit');
    //     // setActiveQuestionResponse({
    //     //     id: '',
    //     //     questionId: questionConfig.id,
    //     //     answer: 'true'
    //     // });
    // }, [
    //     questionConfig, setSubmitResponseCallback, setNextButtonText, updateUnderwritingQuestionResponse,
    //     setActiveQuestionResponse
    // ]);

    return (
        <>
            {questionConfig.customQuestionType !== CustomQuestionType.LIABILITIES && (
                <Markdown valuesToInject={underwritingData}>
                    {questionConfig.text}
                </Markdown>
            )}

            {customQuestionsMap[questionConfig.customQuestionType!]}
        </>
    );
}

export const customQuestionsMap: Record<CustomQuestionType, ReactNode> = {
    AUS_DETAILS: <AusTable />,
    LIABILITIES: <LiabilitiesSection />,
    ASSET_ACCOUNTS: <AssetAccountsSection />
};
