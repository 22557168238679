import api, { PricingUploadBatch, UploadStatus, WebSocketEventType } from '@api';
import {
    Description, Download, ErrorOutline, Upload
} from '@mui/icons-material';
import {
    Button, DialogContent, Paper, Typography
} from '@mui/material';
import {
    DialogActions,
    FileInput,
    IconButton,
    IconTypography,
    RoutedDialog,
    RoutedDialogImplProps, useConfirm
} from '@tsp-ui/core';
import {
    replaceItemByKey, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { handleDownload, useGetCurrentAccount } from '@utils';
import {
    productAndPricingAcceptedFileTypes
} from '@views/product-pricing/ProductAndPricingPage';
import { PendingUploadFileCard } from '@views/product-pricing/components/PendingUploadsCard';
import clsx from 'clsx';
import {
    useCallback, useEffect, useState
} from 'react';
import { useNavigate } from 'react-router-dom';

import { ExpandableErrorList } from './ExpandableErrorList';
import styles from './LoanUploadResultsDialog.module.scss';

// Helper function to check if a file is XML
const isXmlFile = (fileName: string): boolean => fileName.toLowerCase().endsWith('.xml');

interface LoanUploadResultsDialogParams {
    batchId: string;
}

export default function LoanUploadResultsDialog(props: RoutedDialogImplProps) {
    const pageMessage = usePageMessage();
    const confirm = useConfirm();
    const navigate = useNavigate();

    const { batchId } = useParams<LoanUploadResultsDialogParams>();
    const { id: clientId, customerId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(true);
    const [ saveLoading, setSaveLoading ] = useState(false);
    const [ fileIdToReupload, setFileIdToReupload ] = useState<string>();
    const [ batch, setBatch ] = useState<PricingUploadBatch>();

    useAsyncEffect(useCallback(async () => {
        try {
            setLoading(true);
            setBatch(await api.loans.getLoanUploadBatch(clientId, batchId, customerId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the upload details', error);
        }

        setLoading(false);
    }, [
        pageMessage, clientId, customerId, batchId
    ]));

    useEffect(() =>  api.webSocket.subscribe(
        WebSocketEventType.FILE_UPLOAD_COMPLETE,
        (data) => {
            if (batch?.files.some(({ fileId }) => fileId === data.fileId)) {
                setBatch({
                    ...batch!,
                    files: replaceItemByKey(batch!.files, data, 'fileId')
                });
            }
        }
    ), [ batch ]);

    const numLoans = batch?.files.reduce((numLoans, file) => numLoans + file.loanCount, 0) || 0;
    const numErrors = batch?.files.reduce((numErrors, file) => numErrors + file.errors?.length, 0) || 0;
    const loansProcessed = numLoans - numErrors;

    const selectedFile = batch?.files.find(({ fileId }) => fileId === fileIdToReupload);

    async function handleAddFiles(files: File[]) {
        try {
            setSaveLoading(true);
            const newFile = await api.loans.updateBatchFile(
                clientId, batch!.customerId, batchId, fileIdToReupload!, files[0]
            );

            setBatch({
                ...batch!,
                files: replaceItemByKey(batch!.files, newFile, 'fileId')
            });
            setFileIdToReupload(undefined);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while uploading loan files', error);
        }

        setSaveLoading(false);
    }

    async function handleFileDownload() {
        if (!selectedFile) {
            return;
        }

        try {
            const file = await api.loans.downloadFile(clientId, batchId, selectedFile.fileId);
            const fileName = selectedFile.fileName.slice(selectedFile.fileName.lastIndexOf('/') + 1);
            handleDownload(file, fileName);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while downloading the file', error);
        }
    }

    async function handleSubmitForPricing() {
        if (await confirm(`${numErrors} ${numErrors === 1 ? 'loan' : 'loans'} have unresolved errors and will 
            be excluded from this pricing batch. Do you want to continue?`)) {
            setSaveLoading(true);

            try {
                await api.loans.forcePricingForBatch(clientId, batch!.customerId, batchId);

                navigate('..');
                pageMessage.success('Batch submitted for pricing');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while submitting the batch for pricing', error);
            }

            setSaveLoading(false);
        }
    }

    return (
        <RoutedDialog
            title="Upload details"
            maxWidth={false}
            loading={loading}
            saveLoading={saveLoading}
            {...props}
        >
            <DialogContent className={styles.dialogContent}>
                <div>
                    <div className={styles.statusContainer}>
                        <Typography
                            fontWeight="bold"
                            color="textPrimary"
                        >
                            BatchID: {batchId}
                        </Typography>

                        <div />

                        <Paper variant="outlined">
                            <IconTypography icon={<Description color="primary" />}>
                                {loansProcessed}
                            </IconTypography>

                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                {loansProcessed === 1 ? 'loan' : 'loans'} processed
                            </Typography>
                        </Paper>

                        <Paper variant="outlined">
                            <IconTypography icon={<ErrorOutline color="error" />}>
                                {numErrors}
                            </IconTypography>

                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                {numErrors === 1 ? 'loan' : 'loans'} errored
                            </Typography>
                        </Paper>
                    </div>

                    <div className={styles.files}>
                        <Typography color="textSecondary">
                            Files
                        </Typography>

                        {batch?.files.map((file) => {
                            const isXml = isXmlFile(file.fileName);
                            return (
                                <div className={styles.fileCardContainer}>
                                    <PendingUploadFileCard
                                        key={file.fileId}
                                        file={file}
                                    />

                                    <IconButton
                                        tooltip={isXml ? 'Revisions not supported for XML files, try uploading a new file' : 'Upload a revision'}
                                        size="small"
                                        onClick={() => setFileIdToReupload(file.fileId)}
                                        className={clsx({
                                            [styles.hidden]: file.status !== UploadStatus.ERROR
                                        })}
                                        disabled={isXml}
                                    >
                                        <Upload
                                            fontSize="small"
                                            color="secondary"
                                        />
                                    </IconButton>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {fileIdToReupload && (
                    <div>
                        <Typography color="textSecondary">
                            Upload new revision
                        </Typography>

                        <div className={styles.downloadContainer}>
                            <Typography fontWeight={500}>
                                {selectedFile?.fileName.slice(selectedFile?.fileName.lastIndexOf('/') + 1)}
                            </Typography>

                            <IconButton
                                tooltip="Download revision"
                                size="small"
                                onClick={handleFileDownload}
                            >
                                <Download
                                    fontSize="small"
                                    color="secondary"
                                />
                            </IconButton>
                        </div>

                        {selectedFile?.errors?.map((error) => (
                            <ExpandableErrorList
                                loanNumber={error.prospectiveLoanDisplayNumber}
                                errorDetails={error.errorDetails}
                            />
                        ))}

                        <FileInput
                            compact
                            title="file"
                            className={styles.revisionFileInput}
                            acceptedFileTypes={productAndPricingAcceptedFileTypes}
                            onAddFiles={handleAddFiles}
                            single
                            inputId={`${selectedFile?.fileName}_revision`}
                        />

                        <Typography
                            variant="caption"
                            color="textSecondary"
                            align="center"
                        >
                            Correct the errors and re-upload the file here
                        </Typography>
                    </div>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleSubmitForPricing}
                    disabled={!batch?.files.some(({ status }) => (
                        status === UploadStatus.ERROR
                    ))}
                >
                    Submit for pricing
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
