import api, {
    LiabilityBorrower,
    SpecialLiabilityType,
    SpecialUnderwritingLiability,
    liabilityBorrowerDisplay,
    specialLiabilityTypeDisplay
} from '@api';
import {
    DialogActions, DialogContent, FormLabel,
    MenuItem
} from '@mui/material';
import {
    Button,
    RoutedDialog,
    RoutedDialogImplProps,
    TextField, replaceItemByKey, usePageMessage,
    useParams
} from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils';
import { LoanDetailContext } from '@views/loans/LoanDetailPage';
import { UnderWritingStepContext } from '@views/loans/underwriting/UnderwritingStepSection';
import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './AddEditUnderwritingLiabilityDialog.module.scss';


export default function AddEditSpecialLiabilityDialog(props: RoutedDialogImplProps) {
    const { id: clientId } = useGetCurrentAccount();
    const { specialLiabilities, setSpecialLiabilities } = useContext(UnderWritingStepContext);
    const loanId = useContext(LoanDetailContext).loanDetail?.id;
    const { specialLiabilityId } = useParams();
    const liabilityToEdit = specialLiabilities.find(({ id }) => id === specialLiabilityId);
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const location = useLocation();
    const selectedType = location.state?.selectedType;
    const formMethods = useForm<SpecialUnderwritingLiability>({
        defaultValues: liabilityToEdit || {
            specialLiablityType: selectedType
        }
    });
    const { handleSubmit, watch } = formMethods;
    const isOther = watch('specialLiablityType') === SpecialLiabilityType.OTHER;

    const [ saveLoading, setSaveLoading ] = useState(false);

    async function onSubmit(data: SpecialUnderwritingLiability) {
        if (!loanId) {
            return;
        }

        setSaveLoading(true);
        try {
            if (liabilityToEdit) {
                const updatedLiability = await api.underwriting.liability.updateSpecialUnderwritingLiability(
                    clientId, loanId, {
                        ...data,
                        id: liabilityToEdit.id
                    }
                );
                setSpecialLiabilities(prevLiabilities => replaceItemByKey(prevLiabilities, updatedLiability, 'id'));
                pageMessage.success('Special liability updated successfully');
            } else {
                const newLiability = await api.underwriting.liability.createSpecialUnderwritingLiability(
                    clientId, loanId, data
                );
                setSpecialLiabilities([ ...specialLiabilities, newLiability ]);
                pageMessage.success('Special liability created successfully');
            }
            navigate(props.closeTo);
        } catch (e) {
            pageMessage.handleApiError(`An error occurred while ${liabilityToEdit ? 'updating' : 'creating'} special liability`, e);
        }
        setSaveLoading(false);
    }

    return (
        <RoutedDialog
            {...props}
            title={liabilityToEdit ? 'Edit special liability' : 'Add special liability'}
            saveLoading={saveLoading}
        >
            <DialogContent>
                <form
                    noValidate
                    id={formId}
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormProvider {...formMethods}>
                        <div>
                            <FormLabel className={styles.formLabel}>
                                Account Info
                            </FormLabel>

                            <div className={styles.detailsContainer}>
                                <TextField<SpecialUnderwritingLiability>
                                    name="specialLiablityType"
                                    label="Description"
                                    required
                                    select
                                >
                                    {Object.values(SpecialLiabilityType).map((type) => (
                                        <MenuItem
                                            key={type}
                                            value={type}
                                        >
                                            {specialLiabilityTypeDisplay[type]}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                {isOther && (
                                    <TextField<SpecialUnderwritingLiability>
                                        name="otherDescription"
                                        label="Other description"
                                    />
                                )}

                                <TextField<SpecialUnderwritingLiability>
                                    name="borrowerStatus"
                                    label="Borrower Status"
                                    select
                                >
                                    {Object.values(LiabilityBorrower).map((bor) => (
                                        <MenuItem
                                            key={bor}
                                            value={bor}
                                        >
                                            {liabilityBorrowerDisplay[bor]}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField<SpecialUnderwritingLiability>
                                    name="accountBalance"
                                    label="Account Balance"
                                    type="number"
                                />

                                <TextField<SpecialUnderwritingLiability>
                                    name="monthsLeft"
                                    label="Months Left"
                                    type="number"
                                />

                                <TextField<SpecialUnderwritingLiability>
                                    name="monthlyPayment"
                                    label="Monthly Payment"
                                    type="number"
                                    required
                                />

                                <TextField<SpecialUnderwritingLiability>
                                    name="creditLimit"
                                    label="Credit Limit"
                                    type="number"
                                />
                            </div>
                        </div>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    form={formId}
                    type="submit"
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

const formId = 'add-special-liability-form';
