/* eslint-disable max-len */
/**
 * Types are extracted to a separate file to avoid circular dependencies
 */

// Underwriting Category Types

export interface UnderwritingCategoryConfig {
    id: string;
    name: string;
    displayOrder: number;

    // steps is a summary list of all the steps in the category with their name and if they have been submitted
    steps: UnderwritingStepSummary[];
}

// Underwriting Data Types
export interface UnderwritingData {
    propertyAddress: string;
    commitmentExpiryDate: string;
    appraiserName: string;
    appraiserLicenseNumber: string;
    appraisalCompany: string;
    appraisalCompanyLicenseNumber: string;
    loanOfficerName: string;
    loanOfficerNmlsId: string;
    originatingCompanyName: string;
    originatingCompanyNmlsId: string;
    buyersAgentName: string;
    buyersAgentLicenseNumber: string;
    sellersAgentName: string;
    sellersAgentLicenseNumber: string;
    sellers: SellerBuyerInfo[];
    buyers: SellerBuyerInfo[];
}

export interface SellerBuyerInfo {
    name: string;
}

// Underwriting Step Types

export interface UnderwritingStepSummary {
    id: string;
    name: string;
    isSubmitted?: boolean;
}

// export interface UnderwritingStepDetail extends UnderwritingStepSummary{
//     questions: UnderwritingQuestion[];
// }

export interface UnderwritingStepConfig {
    underwritingCategoryId: string;
    id: string;
    name: string;
    displayOrder: number;
    questionConfigs?: UnderwritingQuestionConfig[]; // TODO this is just on here for the demo the calculate the number of questions per step on the category detail page
}

export interface UnderwritingStepData {
    underwritingStepId: string;
    isSubmitted: boolean;
}

// Underwriting Question Types

export enum LOSIterableEntity {
    GIFT_FUNDS = 'GIFT_FUNDS',
    INQUIRY = 'INQUIRY',
    PROPERTY = 'PROPERTY',
    SELLER = 'SELLER',
    BUYER = 'BUYER',
    DEROGATORY = 'DEROGATORY'
}

export const losIterableEntityDisplay: Record<LOSIterableEntity, string> = {
    [LOSIterableEntity.DEROGATORY]: 'Derogatory',
    [LOSIterableEntity.GIFT_FUNDS]: 'Gift Funds',
    [LOSIterableEntity.INQUIRY]: 'Inquiry',
    [LOSIterableEntity.PROPERTY]: 'Property',
    [LOSIterableEntity.SELLER]: 'Seller',
    [LOSIterableEntity.BUYER]: 'Buyer'
};

export enum UnderwritingQuestionType {
    SINGLE_SELECT = 'SINGLE_SELECT',
    FORM = 'FORM',
    FOREACH = 'FOREACH',
    INFO = 'INFO',
    TABLE_ENTRY = 'TABLE_ENTRY',
    GROUP = 'GROUP',
    CUSTOM = 'CUSTOM'
}

export const underwritingQuestionTypeDisplay = {
    [UnderwritingQuestionType.SINGLE_SELECT]: 'Single select',
    [UnderwritingQuestionType.FORM]: 'Form',
    [UnderwritingQuestionType.FOREACH]: 'Foreach',
    [UnderwritingQuestionType.INFO]: 'Info',
    [UnderwritingQuestionType.TABLE_ENTRY]: 'Table entry',
    [UnderwritingQuestionType.GROUP]: 'Group',
    [UnderwritingQuestionType.CUSTOM]: 'Custom'
};

export enum CustomQuestionType {
    AUS_DETAILS = 'AUS_DETAILS',
    LIABILITIES = 'LIABILITIES',
    ASSET_ACCOUNTS = 'ASSET_ACCOUNTS',
}
export const customQuestionTypeDisplay = {
    [CustomQuestionType.AUS_DETAILS]: 'AUS Details',
    [CustomQuestionType.LIABILITIES]: 'Liabilities',
    [CustomQuestionType.ASSET_ACCOUNTS]: 'Asset Accounts'
};

export enum SingleSelectVariant {
    SIMPLE = 'SIMPLE',
    RADIOBUTTON = 'RADIOBUTTON',
    DROPDOWN = 'DROPDOWN'
}
export const singleSelectVariantDisplay = {
    [SingleSelectVariant.SIMPLE]: 'Simple',
    [SingleSelectVariant.RADIOBUTTON]: 'Radiobutton',
    [SingleSelectVariant.DROPDOWN]: 'Dropdown'
};

export enum UnderwritingIconType {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS'
}
export const underwritingIconTypeDisplay = {
    [UnderwritingIconType.INFO]: 'Info',
    [UnderwritingIconType.WARNING]: 'Warning',
    [UnderwritingIconType.ERROR]: 'Error',
    [UnderwritingIconType.SUCCESS]: 'Success'
};

export interface UnderwritingAction {
    action: 'CONTINUE' | 'END';
    continueTo?: string;
    text: string; // only used for single select
    confirmationMessage?: string; // only used for single select
    sideEffects?: UnderwritingSideEffect[];
    dataSourceId?: string; // only used for single select, if selecting this option gets posted to the LOS
}

export interface UnderwritingSideEffect {
    id: string;
    type: 'SUSPEND_LOAN' | 'GENERATE_CONDITION';
    subType?: 'FOREACH' | 'STANDARD'; // generate a sideEffect for each row of a table question
    text?: string;
    description?: string;
}

export enum UnderwritingFieldType {
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    CURRENCY = 'CURRENCY',
    PERCENTAGE = 'PERCENTAGE'
    // TODO (idea for improvement) - add select & multiselect. We can pass the options in the field object
    // SELECT = 'SELECT',
    // MULTI_SELECT = 'MULTI_SELECT',
}

export const underwritingFieldTypeDisplay = {
    [UnderwritingFieldType.TEXT]: 'Text',
    [UnderwritingFieldType.NUMBER]: 'Number',
    [UnderwritingFieldType.DATE]: 'Date',
    [UnderwritingFieldType.CURRENCY]: 'Currency',
    [UnderwritingFieldType.PERCENTAGE]: 'Percentage'
};

export interface UnderwritingFieldConfig {
    id: string;
    dataSourceId: string;
    type: UnderwritingFieldType;
    label: string;
    size: number; // size is the span of the field out of 12 horizontal columns
    isDisabled: boolean;
    helperText?: string;
    isRequired?: boolean; // it is assumed all fields are required unless specified false
}

export interface UnderwritingTableColumnConfig {
    id: string;
    columnName: string;
    columnType?: UnderwritingFieldType; // TODO implement type in the components. Right now this was just added to support types in the future
    dataSourceId: string; // TODO rename this to dataSourceId to be consistent
}

// TODO we might be able to delete array options from the question config. We use entity name the describe which iterable entity is selected, and the datasource id represents which encompass field to post to
export interface ForeachArrayOptions {
    dataSourceId: string;
    iterationName: string;
    indexName: string;
}

export interface UnderwritingQuestionConfig {
    id: string;
    stepId?: string; // it's not vital to have this property on the frontend interface, but it will be needed as a fk for the backend and is helpful here for mocking

    type: UnderwritingQuestionType;
    customQuestionType?: CustomQuestionType; // for type CUSTOM
    next?: UnderwritingAction; // for type FORM, INFO, TABLE_ENTRY
    text?: string; // the label of the question

    // eslint-disable-next-line max-len
    showNextQuestion?: boolean; // for any question type that has a next property (Not SINGLE_SELECT). Most question require the user the answer the question and hit next before the next question is shown. This property is used to automatically show the next question even if the current question is unanswered, however all questions must still be answered before the step is able to be submitted.

    variant?: SingleSelectVariant; // if type is SINGLE_SELECT
    choices?: UnderwritingAction[]; // for type SINGLE_SELECT

    fields?: UnderwritingFieldConfig[]; // if type is FORM

    addButtonText?: string; // if type is FOREACH

    questions?: UnderwritingQuestionConfig[]; // if type is FOREACH, or GROUP // TODO this can be removed but the backend is currently returning it. We can remove it after the backend is updated

    entityName?: LOSIterableEntity; // if type is TABLE_ENTRY or FOREACH
    columns?: UnderwritingTableColumnConfig[]; // if type is TABLE_ENTRY

    icon?: UnderwritingIconType; // if type is INFO
    isInitialQuestion?: boolean;
    parentQuestionId?: string;
};

// Underwriting Question Response Types

export interface UnderwritingQuestionResponse {
    id: string;
    questionId: string; // associated with the question config

    // Question types:
    // SINGLE_SELECT    - uses 'answer' property
    // INFO             - uses 'answer' property
    // FORM             - uses 'fieldValues' property
    // TABLE_ENTRY      - uses 'tableRowValues' property
    // CUSTOM           - at this time does not require a response... this could change in the future as we expand the custom components
    // GROUP            - does not require a response (subquestions will get their own responses)
    // FOREACH          - uses 'fieldValues' and 'entityId' properties to track essential values for each entity (the values that get setup when a new entity is created)
    //                  a foreach question will have a response for each entity that is created
    //                  foreach subquestion responses are tracked with the "entityId" property that associates them with a specific entity in the LOS

    // info questions get 'true' when they are answered (aknowledged by the user)
    // single select questions get the text of the selected choice
    answer?: string;

    // if type is form, the fieldValues represent the answers to the form fields
    // if type is foreach, the fieldValues represent the answers to the form fields for each entity (there should be an response here for each entity)
    fieldValues?: UnderwritingFieldResponse[];

    entityId?: string; // if the question is the child of a foreach, this is the id of the specific entity that the SUBQUESTION is associated with

    // if type is table entry, each row gets a tableRowValues object
    tableRowValues?: UnderwritingTableRowValueSet[]; // this represents the 'answer' of the table

    // GROUP questions do not need a response (their subquestions will get their own responses)
};

export interface UnderwritingTableRowValueSet {
    entityId: string; // this is the id of the specific entity that the table ROW is associated with
    values: {
        id: string;
        columnId: string; // this is associated with the column.id on the question config
        value: string;
    }[];
}

export interface UnderwritingFieldResponse {
    id: string;
    fieldId: string; // this is associated with field.id on the question config
    value?: string | Date | null; // this represents the 'answer' of the field
}
