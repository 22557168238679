import { customQuestionTypeDisplay } from '@api';
import { Divider, Typography } from '@mui/material';
import { TextField, renderEnumOptions } from '@tsp-ui/core';
import { customQuestionsMap } from '@views/loans/underwriting/question-cards/CustomQuestionCard/CustomQuestionCard';
import { useFormContext } from 'react-hook-form';

import { UnderwritingQuestionFormValues } from '../AddEditQuestionDialog';


export function CustomFormContent() {
    const customQuestionType = useFormContext<UnderwritingQuestionFormValues>().watch('customQuestionType');

    return (
        <>
            <TextField<UnderwritingQuestionFormValues>
                name="customQuestionType"
                label="Custom Question Type"
                required
                select
            >
                {renderEnumOptions(customQuestionTypeDisplay)}
            </TextField>

            <Divider />

            {customQuestionType && (
                <>
                    <Typography variant="h6">
                        Content Preview
                    </Typography>

                    {customQuestionsMap[customQuestionType]}
                </>
            )}
        </>
    );
}
