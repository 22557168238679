import api, {
    LoanDocument, UnderwritingCategoryConfig, UnderwritingStepConfig, UnderwritingStepData,
    underwritingCategoryNameToConditionCategory
} from '@api';
import { ListItemButton, Paper, Typography } from '@mui/material';
import { Loader, useAsyncEffect } from '@tsp-ui/core';
import { useHandlePromiseSettledResult, useTryGetCurrentAccount } from '@utils';
import StatusChecklistItem from '@views/admin/customers/components/StatusChecklistItem';
import clsx from 'clsx';
import {
    Dispatch,
    SetStateAction,
    createContext,
    useCallback, useContext, useEffect, useState
} from 'react';

import { LoanDetailContext } from '../LoanDetailPage';
import { LoanDocumentItem } from '../components/LoanDocumentsDialog';
import { ConditionsDisplay } from '../conditions/ConditionsDisplay';
import ConditionsSummary from '../conditions/ConditionsSummary';

import styles from './UnderwritingCategoryDetail.module.scss';
import { UnderwritingStepSection } from './UnderwritingStepSection';


export const UnderwritingStepContext = createContext<{
    underwritingStepData: UnderwritingStepData[];
    setUnderwritingStepData: Dispatch<SetStateAction<UnderwritingStepData[]>>;
    underwritingStepConfigs: UnderwritingStepConfig[];
    setUnderwritingStepConfigs: Dispatch<SetStateAction<UnderwritingStepConfig[]>>;
}>({
    underwritingStepData: [],
    setUnderwritingStepData: () => {},
    underwritingStepConfigs: [],
    setUnderwritingStepConfigs: () => {}
});


interface UnderwritingCategoryDetailProps {
    category: UnderwritingCategoryConfig;
    underwritingCategoryId: string;
}

export function UnderwritingCategoryDetail({
    category, underwritingCategoryId
}: UnderwritingCategoryDetailProps) {
    const { conditions, loanDetail } = useContext(LoanDetailContext);
    const [ underwritingStepId, setUnderwritingStepId ] = useState<string>();
    const [ underwritingStepConfigs, setUnderwritingStepConfigs ] = useState<UnderwritingStepConfig[]>([]);
    const [ underwritingStepData, setUnderwritingStepData ] = useState<UnderwritingStepData[]>([]);
    const [ loading, setLoading ] = useState(true);
    const handlePromiseSettledResult = useHandlePromiseSettledResult();
    const clientId = useTryGetCurrentAccount()?.id!;

    // when category changes, reset underwritingStepId
    useEffect(() => {
        setUnderwritingStepId(undefined);
    }, [ category.id ]);

    // TODO conditions need to be updated to be linked by category id, not enum category
    // i.e. condition.category should be replaced with condition.categoryId
    // conditions also need to be associated with a specific underwriting step
    // jank method for the demo:
    const conditionsForCategory = conditions.filter(
        condition => condition.category === underwritingCategoryNameToConditionCategory[
            category.name as keyof typeof underwritingCategoryNameToConditionCategory
        ]
    );

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        const [ stepConfigsResult, stepDataResult ] = await Promise.allSettled([
            api.underwriting.getUnderwritingStepConfigs(clientId, underwritingCategoryId),
            api.underwriting.getUnderwritingStepData(clientId, loanDetail?.id!, underwritingCategoryId)
        ]);

        handlePromiseSettledResult(stepConfigsResult, setUnderwritingStepConfigs, 'An error occurred while fetching underwriting steps');
        handlePromiseSettledResult(stepDataResult, setUnderwritingStepData, 'An error occurred while fetching underwriting step data');

        setLoading(false);
    }, [
        clientId, handlePromiseSettledResult, loanDetail?.id, underwritingCategoryId
    ]));

    const labels: { [key: string]: string } = {
        0: 'AUS Report',
        1: 'Sales Contract',
        2: 'Credit Report Single',
        3: 'Bank Statements',
        4: 'Appraisal',
        5: 'Title Commitment',
        6: 'Hazard Insurance',
        7: 'Fraud Report'
    };

    // use ascending order by displayOrder
    const orderedStepConfigs = underwritingStepConfigs.sort((a, b) => a.displayOrder - b.displayOrder);

    return loading ? <Loader loading /> : (
        <UnderwritingStepContext.Provider value={{
            underwritingStepData,
            setUnderwritingStepData,
            underwritingStepConfigs: orderedStepConfigs,
            setUnderwritingStepConfigs
        }}
        >
            <div className={styles.leftPanel}>
                <Paper
                    elevation={0}
                    className={styles.relevantDocs}
                >
                    <Typography color="textSecondary">
                        Relevant documents
                    </Typography>

                    <ul className={styles.files}>
                        {[ labels[underwritingCategoryId] ].map((doc) => (
                            <LoanDocumentItem
                                key={doc}
                                documentId="1"
                                documents={[
                                    {
                                        id: '1',
                                        instanceCount: 1,
                                        name: doc
                                    } as LoanDocument
                                ]} // TODO post-demo make this better
                            />
                        ))}
                    </ul>
                </Paper>

                <div>
                    <ListItemButton
                        selected={!underwritingStepId}
                        className={styles.allConditionsStep}
                        classes={{
                            selected: clsx(styles.selected, {
                                [styles.extraWidth]: false
                            })
                        }}
                        onClick={() => setUnderwritingStepId(undefined)}
                    >
                        Conditions

                        <ConditionsDisplay numConditions={conditionsForCategory.length} />
                    </ListItemButton>

                    <Typography color="textSecondary">
                        Underwriting steps
                    </Typography>

                    {orderedStepConfigs.map((step) => (
                        <ListItemButton
                            key={step.id}
                            selected={underwritingStepId === step.id}
                            className={styles.stepChecklistItemContainer}
                            classes={{
                                selected: clsx(styles.selected, {
                                    [styles.extraWidth]: false
                                })
                            }}
                            onClick={() => setUnderwritingStepId(step.id)}
                        >
                            <StatusChecklistItem
                                label={step.name}
                                done={underwritingStepData.find(
                                    data => data.underwritingStepId === step.id
                                )?.isSubmitted}
                                selected={underwritingStepId === step.id}
                            />

                            {/* condition.underwritingStepId is not implemented on backend. Removing this for demo */}
                            {/* {step.isSubmitted && (
                                <ConditionsDisplay numConditions={
                                    conditions.filter(condition => condition.underwritingStepId === step.id).length
                                }
                                />
                            )} */}
                        </ListItemButton>
                    ))}
                </div>
            </div>

            {!underwritingStepId ? (
                <ConditionsSummary
                    header={`${category.name} Conditions`}
                    conditions={conditionsForCategory}
                />
            ) : (
                <UnderwritingStepSection
                    underwritingStepId={underwritingStepId}
                    underwritingCategory={category}
                    setUnderwritingStepId={setUnderwritingStepId}
                />
            )}
        </UnderwritingStepContext.Provider>
    );
}
