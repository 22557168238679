import api, {
    FileUpload, LoanPricingResult, PricingUploadBatch, UploadStatus, WebSocketEventType, uploadStatusDisplay
} from '@api';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import {
    Button, CircularProgress, Pagination, Paper, Tooltip, Typography
} from '@mui/material';
import { PaginatedResponse } from '@tsp-ui/core';
import { replaceItemByKey, usePageMessage } from '@tsp-ui/core/utils';
import { usePagination } from '@utils/hooks/usePagination';
import { UploadStatusCard } from '@views/product-pricing/components/UploadStatusCard';
import {
    Dispatch, SetStateAction, useEffect, useRef, useState
} from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

import styles from './PendingUploadsCard.module.scss';


interface PendingUploadsCardProps {
    pendingBatches?: PricingUploadBatch[];
    setLoanPricingResults: Dispatch<SetStateAction<PaginatedResponse<LoanPricingResult>>>;
    setParentBatches: Dispatch<SetStateAction<PricingUploadBatch[] | undefined>>;
}

const BATCH_REMOVAL_DELAY = 3000;
const { ERROR, COMPLETE, PENDING } = UploadStatus;

export default function PendingUploadsCard({
    pendingBatches, setLoanPricingResults, setParentBatches
}: PendingUploadsCardProps) {
    const pageMessage = usePageMessage();

    const [ batches, setBatches ] = useState(pendingBatches || []);
    const uploadsComplete = !!batches.length && batches.every(({ status }) => status !== PENDING);

    useEffect(() => {
        const unsubFileComplete = api.webSocket.subscribe(
            WebSocketEventType.FILE_UPLOAD_COMPLETE,
            (data) => {
                setBatches((batches) => batches.map((batch) => ({
                    ...batch,
                    files: replaceItemByKey(batch.files, data, 'fileId')
                })));
                setParentBatches(batches);
            }
        );

        const unsubPricingComplete = api.webSocket.subscribe(
            WebSocketEventType.PRICING_COMPLETE,
            (data) => {
                setLoanPricingResults((loanPricingResults) => {
                    loanPricingResults!.data = loanPricingResults.data || [];

                    if (data) {
                        data.results.forEach((pricingResult) => {
                            const hasExistingResult = loanPricingResults!.data.find(({ loanId }) => (
                                loanId === pricingResult.loanId
                            ));

                            loanPricingResults!.data = hasExistingResult
                                ? replaceItemByKey(loanPricingResults!.data, pricingResult, 'loanId')
                                : loanPricingResults!.data.concat(pricingResult);
                        });
                    }

                    return { ...loanPricingResults };
                });
            }
        );

        const unsubBatchUploadComplete = api.webSocket.subscribe(
            WebSocketEventType.BATCH_UPLOAD_COMPLETE,
            (data) => {
                setBatches((batches) => replaceItemByKey(batches, data, 'batchId'));
            }
        );

        return () => {
            unsubFileComplete();
            unsubPricingComplete();
            unsubBatchUploadComplete();
        };
    }, [
        batches, setLoanPricingResults, setParentBatches
    ]);

    useEffect(() => {
        setBatches((files) => [ ...(pendingBatches || []), ...files ]);
    }, [ pendingBatches ]);

    useEffect(() => {
        if (uploadsComplete) {
            pageMessage.success('All pending uploads have been processed');

            setTimeout(() => {
                setBatches((files) => files.filter(({ status }) => status === ERROR));
            }, BATCH_REMOVAL_DELAY);
        }
    }, [ uploadsComplete, pageMessage ]);

    const batchesPagination = usePagination(batches || [], 5);

    return (
        <TransitionGroup className={styles.root}>
            {!batches.length ? (
                <NoUploads />
            ) : batchesPagination.paginatedItems.map((pendingUpload, index) => (
                <PendingBatchCard
                    key={pendingUpload.batchId}
                    batchUpload={pendingUpload}
                    index={index}
                />
            ))}

            {batches.length > 5 && (
                <Pagination
                    count={batchesPagination.pageCount}
                    page={batchesPagination.page}
                    onChange={(event, page) => batchesPagination.setPage(page)}
                    className={styles.pagination}
                />
            )}
        </TransitionGroup>
    );
}

function NoUploads(props: Omit<CSSTransitionProps, 'timeout' | 'classNames'>) {
    const nodeRef = useRef<HTMLSpanElement | null>(null);

    return (
        <CSSTransition
            {...props}
            nodeRef={nodeRef}
            appear
            exit={false}
            key="no_uploads"
            timeout={500}
            classNames={{
                appear: styles.noUploadsTransition_appear,
                appearActive: styles.noUploadsTransition_appear_active
            }}
        >
            <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                ref={nodeRef}
            >
                You don't have any pending uploads
            </Typography>
        </CSSTransition>
    );
}

interface PendingBatchCardProps extends Omit<CSSTransitionProps, 'timout' | 'classNames'> {
    batchUpload: PricingUploadBatch;
    index: number;
}

export function PendingBatchCard({
    batchUpload, index, message, ...props
}: PendingBatchCardProps) {
    const { fileCount, files, batchId } = batchUpload;
    const nodeRef = useRef<HTMLDivElement | null>(null);

    return (
        <CSSTransition
            {...props}
            nodeRef={nodeRef}
            timeout={{
                enter: 250 + (index * 50),
                exit: 250
            }}
            classNames={{
                enter: styles.fileCardTransition_enter,
                enterActive: styles.fileCardTransition_enter_active,
                exit: styles.fileCardTransition_exit,
                exitActive: styles.fileCardTransition_exit_active
            }}
        >
            <Paper
                variant="outlined"
                ref={nodeRef}
                className={styles.batchCard}
            >
                <Typography
                    className={styles.batchCardHeader}
                    fontWeight={500}
                    color="textSecondary"
                >
                    {`${fileCount} ${fileCount > 1 ? 'files' : 'file'}`}

                    <Button
                        size="small"
                        component={Link}
                        to={`uploads/${batchId}`}
                    >
                        View details
                    </Button>
                </Typography>

                {files.map((file) => (
                    <PendingUploadFileCard
                        key={file.fileId}
                        file={file}
                    />
                ))}
            </Paper>
        </CSSTransition>
    );
}

interface PendingUploadFileCardsProps {
    file: FileUpload;
}

export function PendingUploadFileCard({ file }: PendingUploadFileCardsProps) {
    const {
        fileId, status, fileName, loanCount, errors
    } = file;

    return (
        <UploadStatusCard
            key={fileId}
            status={status}
            primaryText={fileName.slice(fileName.lastIndexOf('/') + 1)}
            secondaryText={status === PENDING ? undefined : status === COMPLETE
                ? `${loanCount} ${loanCount === 1 ? 'loan' : 'loans'} processed`
                : `${errors.length} ${errors.length === 1 ? 'loan' : 'loans'} errored`}
            actions={(
                <Tooltip title={uploadStatusDisplay[status]}>
                    {status === COMPLETE ? (
                        <CheckCircleOutline
                            fontSize="small"
                            color="success"
                        />
                    ) : status === ERROR ? (
                        <ErrorOutline
                            fontSize="small"
                            color="error"
                        />
                    ) : (
                        <CircularProgress
                            color="primary"
                            size={16}
                        />
                    )}
                </Tooltip>
            )}
        />
    );
}
