/* eslint-disable max-len */
import { apiUtils } from '../api-utils';

// import { underwritingQuestionConfigs } from './underwriting-mock-data';
import { UnderwritingQuestionResponse } from './underwriting-types';


export function getUnderwritingQuestionResponses(
    clientId: string,
    loanId: string,
    underwritingStepId: string
): Promise<UnderwritingQuestionResponse[]> {
    return apiUtils.get(`/client/${clientId}/loan/${loanId}/underwriting-step/${underwritingStepId}/question-response`);
    // get item from localStorage
    // if item doesn't exist yet, set it
    // const storedResponses = localStorage.getItem('underwritingQuestionResponses');
    // if (storedResponses) {
    //     setUnderwritingQuestionResponses(JSON.parse(storedResponses));
    // }

    // return new Promise((resolve) => {
    //     resolve(
    //         underwritingQuestionResponses.filter(questionResponse => {
    //             const questionConfig = underwritingQuestionConfigs.find(config => config.id === questionResponse.questionId);
    //             return questionConfig?.stepId === underwritingStepId;
    //         })
    //     );
    // });
}

export function updateUnderwritingQuestionResponse(
    clientId: string,
    loanId: string,
    underwritingStepId: string,
    underwritingQuestionResponse: UnderwritingQuestionResponse
): Promise<UnderwritingQuestionResponse> {
    return apiUtils.put(`/client/${clientId}/loan/${loanId}/underwriting-step/${underwritingStepId}/question-response/${underwritingQuestionResponse.id}`, underwritingQuestionResponse);
    // return new Promise((resolve) => {
    //     const updatedQuestionResponse = updateUnderwritingQuestionResponseMockHandler(underwritingQuestionResponse);
    //     // set in localStorage
    //     localStorage.setItem('underwritingQuestionResponses', JSON.stringify(underwritingQuestionResponses));
    //     resolve(updatedQuestionResponse);
    // });
}

export function deleteUnderwritingQuestionResponse(
    clientId: string,
    loanId: string,
    underwritingStepId: string,
    underwritingQuestionResponseId: string
): Promise<void> {
    return apiUtils.delete(`/client/${clientId}/loan/${loanId}/underwriting-step/${underwritingStepId}/question-response/${underwritingQuestionResponseId}`);
    // return new Promise((resolve) => {
    //     // remove from localStorage
    //     underwritingQuestionResponses = underwritingQuestionResponses.filter(questionResponse => questionResponse.id !== underwritingQuestionResponseId);
    //     localStorage.setItem('underwritingQuestionResponses', JSON.stringify(underwritingQuestionResponses));
    //     resolve();
    // });
}

export function createUnderwritingQuestionResponse(
    clientId: string,
    loanId: string,
    underwritingStepId: string,
    newQuestionResponse: UnderwritingQuestionResponse
): Promise<UnderwritingQuestionResponse> {
    return apiUtils.post(`/client/${clientId}/loan/${loanId}/underwriting-step/${underwritingStepId}/question-response`, newQuestionResponse);
    // return new Promise((resolve) => {
    //     const createdQuestionResponse = createUnderwritingQuestionResponseMockHandler(newQuestionResponse);
    //     // set in localStorage
    //     localStorage.setItem('underwritingQuestionResponses', JSON.stringify(underwritingQuestionResponses));
    //     resolve(createdQuestionResponse);
    // });
}

let underwritingQuestionResponses: UnderwritingQuestionResponse[] = [];
export function setUnderwritingQuestionResponses(newUnderwritingQuestionResponses: UnderwritingQuestionResponse[]) {
    underwritingQuestionResponses = [ ...newUnderwritingQuestionResponses ];
}

export function getUnderwritingQuestionResponsesMockHandler(): UnderwritingQuestionResponse[] {
    return underwritingQuestionResponses;
}

export function createUnderwritingQuestionResponseMockHandler(newQuestionResponse: UnderwritingQuestionResponse): UnderwritingQuestionResponse {
    const newQuestionResponseWithId = {
        ...newQuestionResponse,
        id: new Date().getTime().toString()
    };
    underwritingQuestionResponses.push(newQuestionResponseWithId);
    return newQuestionResponseWithId;
}

export function updateUnderwritingQuestionResponseMockHandler(updatedQuestionResponse: UnderwritingQuestionResponse): UnderwritingQuestionResponse {
    const index = underwritingQuestionResponses.findIndex(questionResponse => questionResponse.id === updatedQuestionResponse.id);
    underwritingQuestionResponses[index] = updatedQuestionResponse;
    return updatedQuestionResponse;
}
