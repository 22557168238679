export enum LiabilityBorrower {
    BORROWER = 'BORROWER',
    COBORROWER = 'COBORROWER',
    BOTH = 'BOTH'
}

export const liabilityBorrowerDisplay = {
    [LiabilityBorrower.BORROWER]: 'Borrower',
    [LiabilityBorrower.COBORROWER]: 'Coborrower',
    [LiabilityBorrower.BOTH]: 'Both'
};
